import { prepareEncrypt } from "@ingenieria_insoft/ispclientjs";
import { ISEncrypt, TJSONObject, isBool, val2Str } from "@ingenieria_insoft/ispgen";
import { DeleteDB, getItems, getKeys, removeItems, setItems } from "../lib/UlStore";
import * as functions from "../functions/functions"

Office.onReady(prepareEncrypt);

export function getGlobal(): any {
	return typeof self !== "undefined"
		? self
		: typeof window !== "undefined"
			? window
			: typeof global !== "undefined"
				? global
				: undefined;
}

const g = getGlobal() as any;

async function PrintFormula(event: Office.AddinCommands.Event, sFormula: string) {
	try {
		await Excel.run(async function (ctx: Excel.RequestContext) {

			let cell: Excel.Range = ctx.workbook.getActiveCell();
			cell.formulasLocal = [[sFormula]];
			return ctx.sync();

		})
	} catch (error) {
		console.log("Error: " + error);
		if (error instanceof OfficeExtension.Error) console.log("Debug info: " + val2Str(error.debugInfo));
	} finally { event.completed(); }
}

/**
 * Shows a notification when the add-in command is executed.
 * @param event
 */
g.refrescar = async (event: Office.AddinCommands.Event) => {
	try {
		await Excel.run(async function (ctx: Excel.RequestContext) {
			await g.borrarCache();
			ctx.workbook.worksheets.getActiveWorksheet().calculate(true);
			return ctx.sync();
		})
	} catch (error) {
		console.log("Error: " + error);
		if (error instanceof OfficeExtension.Error) console.log("Debug info: " + val2Str(error.debugInfo));
	} finally { event.completed(); }
}

g.borrarCache = async function (event: Office.AddinCommands.Event | undefined) {
	try {
		await Promise.all([
			DeleteDB(), setItems({ 'borrarCache': 'true', 'borrarCachePanel': 'true' })
		])
	} catch (error) { console.error(error); }
	finally { if (event) event.completed(); }
}

g.desconectarCP = async function (event: Office.AddinCommands.Event) {
	try {
		await removeItems(await getKeys())
		await Office.addin.showAsTaskpane()
	} catch (error) { console.error(error); }
	finally { event.completed(); }
}

g.migracion = async function (event: Office.AddinCommands.Event) {
	try {
		//Se obtiene el arreglo de valores que se pueden reemplazar
		let ArrFunciones: Array<string> = Object.keys(functions);

		await Excel.run(function (ctx: Excel.RequestContext) {
			let worksheets: Excel.WorksheetCollection = ctx.workbook.worksheets;
			for (let funcion of ArrFunciones) {
				worksheets.getActiveWorksheet().replaceAll("=@" + funcion, "=CP." + funcion, {});
				worksheets.getActiveWorksheet().replaceAll("=" + funcion, "=CP." + funcion, {});
				worksheets.getActiveWorksheet().replaceAll(";" + funcion, ";CP." + funcion, {});
				worksheets.getActiveWorksheet().replaceAll("(" + funcion, "(CP." + funcion, {});
				worksheets.getActiveWorksheet().replaceAll("=CE." + funcion, "=CP." + funcion, {});
				worksheets.getActiveWorksheet().replaceAll("CE." + funcion, "CP." + funcion, {})
				worksheets.getActiveWorksheet().replaceAll("=_xldudf_CE_" + funcion, "=CP." + funcion, {});
				worksheets.getActiveWorksheet().replaceAll("_xldudf_CE_" + funcion, "CP." + funcion, {});
				worksheets.getActiveWorksheet().replaceAll("=_xldudf_CP_" + funcion, "=CP." + funcion, {});
				worksheets.getActiveWorksheet().replaceAll("_xldudf_CP_" + funcion, "CP." + funcion, {});
				worksheets.getActiveWorksheet().replaceAll("@'C:\\ContaExcel Add-In 4\\ContaExcelAddInV4.xlam'!" + funcion, "CP." + funcion, {});
				worksheets.getActiveWorksheet().replaceAll("=@'C:\\ContaExcel Add-In 4\\ContaExcelAddInV4.xlam'!" + funcion, "=CP." + funcion, {});
			}
			return ctx.sync();
		})
	} catch (error) {
		console.log("Error: " + error);
		if (error instanceof OfficeExtension.Error) console.log("Debug info: " + val2Str(error.debugInfo));
	} finally { event.completed(); }
}

g.soporte = async function (event: Office.AddinCommands.Event) {
	try {
		let { licencia, email } = await getItems(["licencia", "email"])
		if (isBool(licencia) && isBool(email)) {
			let ObjSend: TJSONObject = {
				ilic: licencia,
				idlicenciamaster: licencia,
				semail: email
			}
			window.location.href = "https://www.contapyme.com/soporte/?user=" + ISEncrypt.strToBase64URL(val2Str(ObjSend));
		} else {
			window.location.href = "https://www.contapyme.com/soporte/";
		}
	} catch (_) { }
	finally { event.completed(); }
}

// Funciones Contabilidad
g.atributoCta = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.ATRIBUTOCUENTA()`)
g.nombreCta = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.NOMBRECUENTA()`)
g.nombreCtaAlterna = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.NOMBRECUENTAALTERNA()`)
g.naturalezaCta = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.NATURALEZACUENTA()`)
g.idTipoCta = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.IDTIPOCUENTA()`)
g.nombreTipoCta = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.NOMBRETIPOCUENTA()`)
g.cuntCtasFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CONTARCUENTASFILTRO()`)
g.idCta = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.IDCUENTA()`)
g.idCtaAlterna = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.IDCUENTAALTERNA()`)
g.manejaVrBase = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.MANEJAVALORBASE()`)
g.cuntAsientosCtsFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CONTARASIENTOSCONTABLESFILTRO()`)
g.movCta = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.MOVCUENTA()`)
g.movCtaDc = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.MOVCUENTADC()`)
g.movCtaTro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.MOVCUENTATERCERO()`)
g.movCtaMdExt = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.MOVCUENTAMONEDAEXTRANJERA()`)
g.movCtaVrBase = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.MOVCUENTAVALORBASE()`)
g.movCtaVrBaseFiltro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.MOVCUENTAVALORBASEFILTRO()`)
g.movCtaVr1 = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.MOVCUENTAVALOR1()`)
g.movCtaVr2 = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.MOVCUENTAVALOR2()`)
g.movCtdFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.MOVCONTABILIDADFILTRO()`)
g.movCtdDcFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.MOVCONTABILIDADDCFILTRO()`)
g.movCtaFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.MOVCUENTAFILTRO()`)
g.movCtaDcFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.MOVCUENTADCFILTRO()`)
g.movCtaMdExtFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.MOVCUENTAMONEDAEXTRANJERAFILTRO()`)
g.movCtaVr1Ftro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.MOVCUENTAVALOR1FILTRO()`)
g.movCtaVr2Ftro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.MOVCUENTAVALOR2FILTRO()`)
g.sdoCta = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.SALDOCUENTA()`)
g.sdoCtaFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.SALDOCUENTAFILTRO()`)
g.sdoCtdFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.SALDOCONTABILIDADFILTRO()`)
g.sdoCtaMdExt = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.SALDOCUENTAMONEDAEXTRANJERA()`)
g.sdoCtaVr1 = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.SALDOCUENTAVALOR1()`)
g.sdoCtaVr2 = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.SALDOCUENTAVALOR2()`)
g.sdoCtaMdExtFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.SALDOCUENTAMONEDAEXTRANJERAFILTRO()`)
g.sdoCtaVr1Ftro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.SALDOCUENTAVALOR1FILTRO()`)
g.sdoCtaVr2Ftro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.SALDOCUENTAVALOR2FILTRO()`)
g.pto = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.PRESUPUESTO()`)
g.ptoPeriodo = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.PRESUPUESTOPERIODO()`)
g.ptoFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.PRESUPUESTOFILTRO()`)
g.movEjecPptal = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.MOVEJECPPTAL()`)
g.sdoEjecPptal = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.SALDOEJECPPTAL()`)
g.cuntAsientosEjecPptalFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CONTARASIENTOSEJECPPTALFILTRO()`)
g.movEjecPptalFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.MOVEJECPPTALFILTRO()`)
g.sdoEjecPptalFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.SALDOEJECPPTALFILTRO()`)
g.tasaCambio = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.TASACAMBIO()`)
g.montoEscrito = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.MONTOESCRITO()`)

// Funciones Inventarios
g.atributoBodega = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.ATRIBUTOBODEGA()`)
g.nombreBodega = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.NOMBREBODEGA()`)
g.atributoElto = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.ATRIBUTOELEMENTO()`)
g.nombreElto = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.NOMBREELEMENTO()`)
g.descElto = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.DESCRIPCIONELEMENTO()`)
g.udElto = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.UNIDADELEMENTO()`)
g.udVolElto = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.UNIDADVOLUMENELEMENTO()`)
g.volElto = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.VOLUMENELEMENTO()`)
g.pesoElto = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.PESOELEMENTO()`)
g.udPesoElto = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.UNIDADPESOELEMENTO()`)
g.stockMin = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.STOCKMIN()`)
g.stockMax = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.STOCKMAX()`)
g.udCompElto = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.UNIDADCOMPRAELEMENTO()`)
g.cuntEltosFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CONTARELEMENTOSFILTRO()`)
g.cuntMovsInvFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CONTARMOVIMIENTOSINVENTARIOFILTRO()`)
g.atributoPreciosElto = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.ATRIBUTOPRECIOSELEMENTO()`)
g.precioElto = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.PRECIOELEMENTO()`)
g.ingresosVts = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.INGRESOSVENTAS()`)
g.ivaVts = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.IVAVENTAS()`)
g.cantVts = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADVENTAS()`)
g.costoVts = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.COSTOVENTAS()`)
g.dctoVts = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.DESCUENTOVENTAS()`)
g.ingresosVtsFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.INGRESOSVENTASFILTRO()`)
g.ivaVtsFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.IVAVENTASFILTRO()`)
g.cantVtsFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADVENTASFILTRO()`)
g.costoVtsFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.COSTOVENTASFILTRO()`)
g.dctoVtsFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.DESCUENTOVENTASFILTRO()`)
g.cantEnt = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADENTRADAS()`)
g.costoEnt = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.COSTOENTRADAS()`)
g.cantEntFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADENTRADASFILTRO()`)
g.costoEntFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.COSTOENTRADASFILTRO()`)
g.cantCons = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADCONSUMOS()`)
g.costoCons = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.COSTOCONSUMOS()`)
g.cantConsFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADCONSUMOSFILTRO()`)
g.costoConsFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.COSTOCONSUMOSFILTRO()`)
g.costoPromElto = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.COSTOPROMEDIOELEMENTO()`)
g.costoPromEltoFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.COSTOPROMEDIOELEMENTOFILTRO()`)
g.cantExist = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADEXISTENCIAS()`)
g.costoExist = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.COSTOEXISTENCIAS()`)
g.cantExistFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADEXISTENCIASFILTRO()`)
g.costoExistFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.COSTOEXISTENCIASFILTRO()`)
g.cantExistFis = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADEXISTENCIASFISICO()`)
g.cantSdas = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADSALIDAS()`)
g.costoSdas = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.COSTOSALIDAS()`)
g.cantSdasFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADSALIDASFILTRO()`)
g.costoSdasFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.COSTOSALIDASFILTRO()`)
// Funciones Cartera
g.cartXEdad = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CARTERAPOREDAD()`)
g.cartXEdadFecha = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CARTERAPOREDADFECHA()`)
g.movCartTro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.MOVCARTERATERCERO()`)
g.sdoCartTro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.SALDOCARTERATERCERO()`)
g.movCartTroFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.MOVCARTERATERCEROFILTRO()`)
g.sdoCartTroFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.SALDOCARTERATERCEROFILTRO()`)
// Funciones Activos
g.atributoActivo = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.ATRIBUTOACTIVO()`)
g.vrHoyActivo = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.VALORHOYACTIVO()`)
g.depreciacionActivo = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.DEPRECIACIONACTIVO()`)
g.faltaXDepreciarActivo = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FALTAPORDEPRECIARACTIVO()`)
g.cantUsoActivo = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADUSOACTIVO()`)
g.costoHistoricoActivo = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.COSTOHISTORICOACTIVO()`)
g.cantUsoActivoFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADUSOACTIVOFILTRO()`)
// Funciones Labores
g.atributoAct = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.ATRIBUTOACTIVIDAD()`)
g.atributoLabor = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.ATRIBUTOLABOR()`)
g.cantRecursoLabor = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADRECURSOLABOR()`)
g.cantProducida = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADPRODUCIDA()`)
g.cantLabor = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADLABOR()`)
g.cantManoDeObraCc = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADMANODEOBRACC()`)
g.cantManoDeObraLabor = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADMANODEOBRALABOR()`)
g.costoLabor = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.COSTOLABOR()`)
g.cantLaborFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADLABORFILTRO()`)
g.fechaFinUltimaVezLaborAct = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FECHAFINULTIMAVEZLABORACTIVIDAD()`)
g.fechaIniUltimaVezLaborAct = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FECHAINIULTIMAVEZLABORACTIVIDAD()`)
g.numeroVecesLaborAct = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.NUMEROVECESLABORACTIVIDAD()`)
// Funciones Contratos
g.totalPagadoConts = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.TOTALPAGADOCONTRATOS()`)
g.cantDeObraPagadaConts = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADDEOBRAPAGADACONTRATOS()`)
g.costoManoDeObraPagadaConts = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.COSTOMANODEOBRAPAGADACONTRATOS()`)
g.costoCantDeObraPagadaConts = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.COSTOCANTIDADDEOBRAPAGADACONTRATOS()`)
g.costoTotalPagadoConts = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.COSTOTOTALPAGADOCONTRATOS()`)
g.dcto1Conts = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.DESCUENTO1CONTRATOS()`)
g.dcto2Conts = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.DESCUENTO2CONTRATOS()`)
g.dcto3Conts = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.DESCUENTO3CONTRATOS()`)
g.dcto4Conts = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.DESCUENTO4CONTRATOS()`)
// Funciones Centros de costos
g.atributoSede = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.ATRIBUTOSEDE()`)
g.atributoCc = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.ATRIBUTOCC()`)
g.datoAnalisisSuelos = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.DATOANALISISSUELOS()`)
g.nombreCc = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.NOMBRECC()`)
g.cantAreaUdCc = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADAREAUNIDADCC()`)
g.udAreaCc = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.UNIDADAREACC()`)
g.cantAreaM2Cc = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CANTIDADAREAM2CC()`)
g.poblacionCc = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.POBLACIONCC()`)
g.udPoblacionCc = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.UNIDADPOBLACIONCC()`)
// Funciones Entorno
g.atributoEmp = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.ATRIBUTOEMPRESA()`)
g.nombreEmp = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.NOMBREEMPRESA()`)
g.fechaTrab = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FECHATRABAJO()`)
g.empTrabAct = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.EMPRESATRABAJOACTIVA()`)
g.nombresEmpTrabAct = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.NOMBRESEMPRESATRABAJOACTIVA()`)
g.nombreAreaTrabAct = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.NOMBREAREATRABAJOACTIVA()`)
g.atributoFtroListaDatos = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.ATRIBUTOFILTROLISTADATOS()`)
// Funciones Fecha
g.edad = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.EDAD()`)
g.primerDiaMes = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.PRIMERDIAMES()`)
g.ultimoDiaMes = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.ULTIMODIAMES()`)
g.numeroSemana = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.NUMEROSEMANA()`)
g.fechaInicioSemana = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FECHAINICIOSEMANA()`)
g.fechaLunes = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FECHALUNES()`)
g.nombreMes = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.NOMBREMES()`)
// Funciones Operaciones
g.cuntOprXDocFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CONTAROPRPORDOCFILTRO()`)
g.cuntOprXTipoFtro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.CONTAROPRPORTIPOFILTRO()`)
g.nombreTipDocumento = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.NOMBRETIPDOCUMENTO()`)
// Filtros
g.fhParaFtros = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FECHAPARAFILTROS()`)
g.ftros_OR = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROS_OR()`)
g.ftros_AND = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROS_AND()`)
g.ftro_GrupoAct = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTRO_GRUPOACTIVIDAD()`)
g.ftroMovCont_Cta = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_CUENTA()`)
g.ftroMovCont_Tro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_TERCERO()`)
g.ftroMovCont_ClsOpr = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_CLASEOPERACION()`)
g.ftroMovCont_TipoSop = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_TIPOSOPORTE()`)
g.ftroMovCont_TroCart = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_TERCEROCARTERA()`)
g.ftroMovCont_DocRef = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_DOCUMENTOREFERENCIA()`)
g.ftroMovCont_Anio = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_ANIO()`)
g.ftroMovCont_Mes = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_MES()`)
g.ftroMovCont_Semana = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_SEMANA()`)
g.ftroMovCont_Dia = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_DIA()`)
g.ftroMovCont_Emp = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_EMPRESA()`)
g.ftroMovCont_Sede = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_SEDE()`)
g.ftroMovCont_CC = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_CENTRODECOSTOS()`)
g.ftroMovCont_Flujo = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_FLUJO()`)
g.ftroMovCont_NroCicC = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_NUMEROCICLOCOSTOS()`)
g.ftroMovCont_Etapa = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_ETAPA()`)
g.ftroMovCont_Estado = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_ESTADO()`)
g.ftroMovCont_CtaPto = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_CUENTAPRESUPUESTO()`)
g.ftroMovCont_Cls1 = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_CLASE1()`)
g.ftroMovCont_Cls2 = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_CLASE2()`)
g.ftroMovCont_Banco = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_BANCO()`)
g.ftroMovCont_Activo = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_ACTIVO()`)
g.ftroMovCont_Act = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_ACTIVIDAD()`)
g.ftroMovCont_Per = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_PERIODO()`)
g.ftroMovCont_PerFhPago = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVCONT_PERIODOFECHAPAGO()`)
g.ftroMovInv_Emp = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVINV_EMPRESA()`)
g.ftroMovInv_Bodega = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVINV_BODEGA()`)
g.ftroMovInv_Elto = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVINV_ELEMENTO()`)
g.ftroMovInv_Tro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVINV_TERCERO()`)
g.ftroMovInv_Vend = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVINV_VENDEDOR()`)
g.ftroMovlnv_Vend2 = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVLNV_VENDEDOR2()`)
g.ftroMovInv_XcIVA = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVINV_PORCENTAJEIVA()`)
g.ftroMovInv_XcDcto = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVINV_PORCENTAJEDESCUENTO()`)
g.ftroMovInv_TipoProd = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVINV_TIPOPRODUCTO()`)
g.ftroMovInv_TipoSop = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVINV_TIPOSOPORTE()`)
g.ftroMovInv_ClsOpr = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVINV_CLASEOPERACION()`)
g.ftroMovInv_NroCicC = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVINV_NUMEROCICLODECOSTOS()`)
g.ftroMovInv_Estado = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVINV_ESTADO()`)
g.ftroMovInv_Etapa = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVINV_ETAPA()`)
g.ftroMovInv_XMesa = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVINV_PORMESA()`)
g.ftroMovInv_Act = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVINV_ACTIVIDAD()`)
g.ftroMovInv_Anio = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVINV_ANIO()`)
g.ftroMovInv_Mes = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVINV_MES()`)
g.ftroMovInv_Semana = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVINV_SEMANA()`)
g.ftroMovInv_Dia = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROMOVINV_DIA()`)
g.ftroCta_Cls = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROCUENTA_CLASE()`)
g.ftroCta_Id = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROCUENTA_IDENTIFICADOR()`)
g.ftroCta_Tipo = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROCUENTA_TIPO()`)
g.ftroCta_SlManTro = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROCUENTA_SOLOMANEJATERCERO()`)
g.ftroCta_SlManOtraMd = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROCUENTA_SOLOMANEJAOTRAMONEDA()`)
g.ftroCta_SlManCuotas = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROCUENTA_SOLOMANEJACUOTAS()`)
g.ftroCta_SlManCart = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROCUENTA_SOLOMANEJACARTERA()`)
g.ftroCta_SlExBase = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROCUENTA_SOLOEXIGEBASE()`)
g.ftroCta_SlExActivo = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROCUENTA_SOLOEXIGEACTIVO()`)
g.ftroCta_SlAutoActbles = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROCUENTA_SOLOAUTOACTIVABLES()`)
g.ftroCta_SlAjustxInf = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROCUENTA_SOLOAJUSTESXINF()`)
g.ftroCta_SlExVr1 = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROCUENTA_SOLOEXIGEVALOR1()`)
g.ftroCta_SlExVr2 = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROCUENTA_SOLOEXIGEVALOR2()`)
g.ftroCta_SlExCls1 = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROCUENTA_SOLOEXIGECLASE1()`)
g.ftroCta_SlExCls2 = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROCUENTA_SOLOEXIGECLASE2()`)
g.ftroTro_Id = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_IDENTIFICADOR()`)
g.ftroTro_Zona = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_ZONA()`)
g.ftroTro_Vend = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_VENDEDOR()`)
g.ftroTro_TipoVend = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_TIPOVENDEDOR()`)
g.ftroTro_TipoProv = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_TIPOPROVEEDOR()`)
g.ftroTro_TipoEdo = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_TIPOEMPLEADO()`)
g.ftroTro_TipoCte = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_TIPOCLIENTE()`)
g.ftroTro_TipoDoc = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_TIPODOCUMENTO()`)
g.ftroTro_Perfil = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_PERFIL()`)
g.ftroTro_Pais = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_PAIS()`)
g.ftroTro_Dep = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_DEPARTAMENTO()`)
g.ftroTro_Ciudad = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_CIUDAD()`)
g.ftroTro_ClasifLegal = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_CLASIFICACIONLEGAL()`)
g.ftroTro_Clasif = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_CLASIFICACION()`)
g.ftroTro_Categoria = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_CATEGORIA()`)
g.ftroTro_Dato1 = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_DATO1()`)
g.ftroTro_Dato2 = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_DATO2()`)
g.ftroTro_Cls1 = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_CLASE1()`)
g.ftroTro_Cls2 = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_CLASE2()`)
g.ftroTro_Vr1 = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_VALOR1()`)
g.ftroTro_Vr2 = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_VALOR2()`)
g.ftroSlVts = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROSOLOVENTAS()`)
g.ftroSlSdosIniciales = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROSOLOSALDOSINICIALES()`)
g.ftroSlIngXTraslados = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROSOLOINGRESOSPORTRASLADOS()`)
g.ftroSlIngXDevEnVts = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROSOLOINGRESOSPORDEVOLUCIONESENVENTAS()`)
g.ftroSlIngXAjust = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROSOLOINGRESOSPORAJUSTES()`)
g.ftroSlEmbods = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROSOLOEMBODEGAMIENTOS()`)
g.ftroSlEgXDevEnComps = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROSOLOEGRESOSPORDEVOLUCIONESENCOMPRAS()`)
g.ftroSlEgXAjust = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROSOLOEGRESOSPORAJUSTES()`)
g.ftroSlEg = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROSOLOEGRESOS()`)
g.ftroSlCons = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROSOLOCONSUMOS()`)
g.ftroSlComps = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROSOLOCOMPRAS()`)
g.ftroTro_SlVends = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_SOLOVENDEDORES()`)
g.ftroTro_SlEdos = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_SOLOEMPLEADOS()`)
g.ftroTro_SlProvs = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_SOLOPROVEEDORES()`)
g.ftroTro_SlCts = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_SOLOCLIENTES()`)
g.ftroTro_SlPJ = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_SOLOPERSONASJURIDICAS()`)
g.ftroTro_SlPN = async (event: Office.AddinCommands.Event) => PrintFormula(event, `=CP.FILTROTERCERO_SOLOPERSONASNATURALES()`)




