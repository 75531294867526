/////////////////////////////////INICIO METODOS MANEJO DE DATOS ///////////////////////////////

import { allTrim, isBool, isNumeric, upperCase, val2Bool, val2Date, val2Str } from "@ingenieria_insoft/ispgen";
import { getFechaString } from "./UlGeneralTool";
import { getItem } from "./UlStore";

function AtributoInList(NAtributo: string, valores: Array<string>): string {
    NAtributo = upperCase(NAtributo);
    if (valores.indexOf(NAtributo) != -1) return NAtributo
    return ""
}
export function getNAtributoContableDefault(NAtributo: string): string {
    return AtributoInList(NAtributo, ["IPADRE", "ITDCUENTA", "$ITDCUENTA", "ICUENTAALTERNA", "BAFECTABLEUSR", "BEXIGEICC", "BSEDEXDEFECTO", "BEXIGEBASE", "BEXIGEACTIVO", "BPPTO", "BAJUSTAXINF", "BMANEJAOTRAMONEDA", "IMONEDA", "BVISIBLE", "BDISPONIBLEGI", "BAUTOACTIVAR", "BEXIGETERCERO", "BMANEJATERCERO", "BMANEJACUOTAS", "BCONTROLACXX", "ICLASE", "$ICLASE", "ISUBCLASE", "$ISUBCLASE", "IDESC_CARGO1", "$IDESC_CARGO1", "IDESC_CARGO2", "IDESC_CARGO3", "IDESC_CARGO4", "IDESC_CARGO5", "IDESC_CARGO6", "NCUENTA", "BLOCAL", "BNIIF", "ICUENTANIIF", "SOBSERV"])
}
export function getNAtributoTerceroDefault(NAtributo: string): string {
    return AtributoInList(NAtributo, ["DIGVERIFICACION", "NTERCERO", "BEMPRESA", "NTERCERONAPELLIDO", "NAPELLIDONTERCERO", "NCOMERCIAL", "ITDDOCUM", "$ITDDOCUM", "ITDDOCUM_ABR", "NRAZONSOCIAL", "NOMBRE", "NTERCERO1", "NTERCERO2", "NAPELLIDO", "NAPELLIDO1", "NAPELLIDO2", "BPROVEEDOR", "BEMPLEADO", "BVENDEDOR", "BCLIENTE", "ISEXO", "FNACIMIENTO", "STRATAMIENTO", "PROFESION", "CARGO", "PAGWEB", "EMAIL", "CODPAIS", "PAIS", "CODDEPARTAMENTO", "DEPARTAMENTO", "CODCIUDAD", "CIUDAD", "CODDEPCIUDAD", "DIRECCION", "DIRECCION_AZ09", "ZONA", "TELEFONO", "TELEFONO2", "TELEFONO3", "CELULAR", "FAX", "VALOR1", "VALOR2", "VALOR3", "VALOR4", "FECHA1", "FECHA2", "FECHA3", "FECHA4", "FECHA5", "FECHA6", "ITIPONEGOCIO", "ILISTAPRECIOS", "$ILISTAPRECIOS", "ICCEMPLEADO", "QDIASPLAZOCXP", "ICTATIPO", "ICTATIPO2"])
}
export function getNAtributoInventarioDefault(NAtributo: string): string {
    return AtributoInList(NAtributo, ["NINVENTARIO", "NRESP", "NLOCALIZ", "QPRECISION", "BINVENTARIOESTIMADO", "ICCDEFAULT", "BLABORPROD", "ICUENTA", "$ICUENTA", "ICUENTAING", "ICUENTAPERD", "ICUENTADEVCOMPRA", "ICUENTADEVVENTA"])
}
export function getNAtributoElementosInventarioDefault(NAtributo: string): string {
    return AtributoInList(NAtributo, ["NELEMENTO", "NUNIDAD", "IGRUPOINV", "BESALIAS", "IRECURSOBASE", "BCONTROLINV", "BCONSUMO", "BVENTA", "BPRODUCTO", "BSERVICIO", "BCODBARRAS", "BVISIBLE", "BVISIBLEINTERNET", "BDESCFINANCIERO", "BESCOMISIONABLE", "BCONTEOUNDFISICA", "DESCRIPCION", "NUNIDADCOMPRA", "QFACTOR", "BUNIDADVENTA", "NUNIDADVENTA", "BFACTORUNIDADVENTA", "QFACTORUNIDADVENTA", "BALQUILER", "ITDTIEMPO", "NUNIDADQPESO", "QPESO", "NUNIDADQVOLUMEN", "QVOLUMEN", "SREFFABRICANTE", "SMARCA", "IDEPINV", "CLASE1", "CLASE2", "TIPO1", "TIPO2", "DATO1", "DATO2", "BCOMPUESTO", "BCUENTAINV", "ICUENTAINV", "BCUENTADEVCOMPRA", "ICUENTADEVCOMPRA", "BCUENTADEVVENTA", "ICUENTADEVVENTA", "BCUENTACOSTOS", "ICUENTACOSTOS", "BCUENTAEGR", "ICUENTAEGR", "BICUENTASPORCLASEEGR", "BCUENTAVTA", "ICUENTAVTA", "BICUENTASPORCLASEING", "BCONCEPTOCOMPRA", "ICONCEPTOCOMPRA1", "ICONCEPTOCOMPRA2", "ICONCEPTOCOMPRA3", "ICONCEPTOCOMPRA4", "ICONCEPTOCOMPRA5", "ICONCEPTOCOMPRA6", "BCONCEPTOVENTA", "BCONCEPTOVENTA1", "BCONCEPTOVENTA2", "BCONCEPTOVENTA3", "BCONCEPTOVENTA4"])
}
export function getNAtributoPrecioElementoDefault(NAtributo: string): string {
    return AtributoInList(NAtributo, ["IMETODO", "FASIGNACION", "FVIGENCIAHASTA", "MPRECIO1", "MPRECIO2", "MPRECIO3", "MPRECIO4"])
}
export function getNAtributoActivoDefault(NAtributo: string): string {
    return AtributoInList(NAtributo, ["NACTIVO", "ICODIGOALTERNO", "BLOCAL", "BNIIF", "ITDACTIVO", "ITDACTIVOLOCAL", "ITDACTIVONIIF", "IGRUPOACT", "$IGRUPOACT", "IGRUPOACTLOCAL", "IGRUPOACTNIIF", "IRESPONSABLE", "$IRESPONSABLE", "QPOTENCIA", "TDESCRIP", "NLOCALIZ", "SCLASIFICADORES", "NPROVEEDOR", "IPROVEEDOR", "FCOMPRA", "FINIDEPRECHISTORICANIIF", "ICC", "$ICC", "MVALORCOMP", "MVALORCOMPLOCAL", "MVALORCOMPNIIF", "MCSTAJUSTES", "MCSTAJUSTESLOCAL", "MCSTAJUSTESNIIF", "MDEPACUM", "MDEPACUMLOCAL", "MDEPACUMNIIF", "MAJDEP", "MAJDEPLOCAL", "MAJDEPNIIF", "MVALORHOY", "MVALORHOYLOCAL", "MVALORHOYNIIF", "ITDDEPREC", "ITDDEPRECLOCAL", "ITDDEPRECNIIF", "ICUENTAACTIVO", "$ICUENTAACTIVO", "ICUENTAACTIVOLOCAL", "ICUENTAACTIVONIIF", "ICUENTADIFERIDO", "ICUENTADIFERIDOLOCAL", "ICUENTADIFERIDONIIF", "QVIDAUTIL", "QVIDAUTILLOCAL", "QVIDAUTILNIIF", "QVIDAUTILRESTANTE", "QVIDAUTILRESTANTELOCAL", "QVIDAUTILRESTANTENIIF", "QUSODEPRECIADO", "QUSOMES", "ITDRECONOCINICIAL", "MVALORSALV", "MVALORSALVLOCAL", "MVALORSALVNIIF", "FFINALIZACIONDEPRECIACION", "FFINALIZACIONDEPRECIACIONNIIF", "FINICIODEPRECIACIONLOCAL", "FINICIODEPRECIACIONNIIF", "BBAJA"])
}
export function getNAtributoLaboresDefault(NAtributo: string): string {
    return AtributoInList(NAtributo, ["NACTIVIDAD", "ITDACTIVIDAD", "NCORTO", "QDURACIONLABORESDEF", "NUNIDAD", "SGRUPO", "STIPO"]);
}
export function getNAtributoLaborDefault(NAtributo: string): string {
    return AtributoInList(NAtributo, ["NLABOR", "NCORTO", "NUNIDAD", "ITDCLCREND", "SGRUPO", "STIPO", "BLABORPROD", "NPRODUCTO", "BAUTOEMBODEGA", "ICUENTA"])
}
export function getNAtributoCCDefault(NAtributo: string): string {
    return AtributoInList(NAtributo, ["IEMP", "NNODE", "ISEDE", "NNODETREE", "FINI", "FFIN", "ICC", "ICCPADRE", "NCORTO", "IACTIVIDAD", "$IACTIVIDAD", "INITRESP", "$INITRESP", "QUNIDADES", "NUNIDAD", "ITDCC", "QAREAUN", "IUN", "QAREA", "SCLASIFICADORES", "SGRUPO", "BACTIVARGASTOS", "SVALORADIC1", "SVALORADIC2", "SVALORADIC3", "SVALORADIC4", "SVALORADIC5", "SVALORADIC6", "SVALORADIC7", "SVALORADIC8", "SVALORADIC29", "SVALORADIC10", "SVALORADIC11", "SVALORADIC12", "FECHA1ADIC", "FECHA2ADIC", "FECHA3ADIC", "ICUENTADESARROLLO", "ICUENTAPRODPROC", "ICUENTAPRODPROCDEF", "ICUENTAPERDBAJADEF", "QVIDAREST", "IETAPA", "IESTADO", "ICUENTAPRODPROC_V", "IETAPASUBCC", "BDISTRIBAUTO", "BENE", "BFEB", "BMAR", "BABR", "BMAY", "BJUN", "BJUL", "BAGO", "BSEP", "BOCT", "BNOV", "BDIC"])
}
export function getICuentaDefault(ICuenta: string): string { return allTrim(ICuenta) }
export function getIContratoDefault(IContrato: string): string { return allTrim(IContrato) }
export function getITDContratoDefault(ITDContrato: string): string { return allTrim(ITDContrato) }
export function getNProductoDefault(NProducto: string): string { return allTrim(NProducto) }
export function getNUnidadMdODefault(NUnidadMdO: string): string { return allTrim(NUnidadMdO) }
export function getIActividadDefault(IActividad: string): string { return allTrim(IActividad) }
export function getITerceroDefault(ITercero: string): string { return allTrim(ITercero) }
export function getIDebCredDefault(IDebCred: string): string {
    IDebCred = upperCase(allTrim(IDebCred))
    if (!isBool(IDebCred)) return "S";
    if (IDebCred !== "S" && IDebCred !== "D" && IDebCred !== "C") throw ('El parámetro ingresado es incorrecto, la función solo aceptas los valores "S", "D", "C" ');
    return IDebCred;
}
export function getITipoRecursoDefault(ITipoRecurso: string): string {
    ITipoRecurso = allTrim(ITipoRecurso)
    if (!isBool(ITipoRecurso)) return ""
    if (ITipoRecurso !== "1" && ITipoRecurso !== "2" && ITipoRecurso !== "3") throw ('El parámetro ingresado es incorrecto, la función solo aceptas los valores "1", "2", "3" ');
    return ITipoRecurso
}
export async function getIempDefault(IEmp: string): Promise<string> {
    IEmp = val2Str(allTrim(IEmp))
    if (!isBool(IEmp))
        return val2Str(await getItem("iemp"))
    return IEmp
}
function validarRegExpFecha(Fecha: string): boolean {
    let expresion = [new RegExp(/(\d{4})([\/-])(\d{2})([\/-])(\d{2})/), new RegExp(/(\d{2})([\/-])(\d{2})([\/-])(\d{4})/), new RegExp(/(\d{1})([\/-])(\d{1})([\/-])(\d{4})/), new RegExp(/(\d{4})([\/-])(\d{1})([\/-])(\d{1})/), new RegExp(/(\d{4})([\/-])(\d{1})([\/-])(\d{2})/), new RegExp(/(\d{4})([\/-])(\d{2})([\/-])(\d{1})/), new RegExp(/(\d{2})([\/-])(\d{1})([\/-])(\d{4})/), new RegExp(/(\d{1})([\/-])(\d{2})([\/-])(\d{4})/)]
    for (let index = 0; index < expresion.length; index++)		if (expresion[index].test(Fecha)) return true;
    return false
}
export function getFechaIniDefault(FechaIni: string): string {
    if (!isBool(FechaIni)) return "1900-01-03";
    if (isNumeric(FechaIni)) return getFechaString(allTrim(FechaIni));
    else if (validarRegExpFecha(FechaIni)) return val2Date(FechaIni).toISOString().slice(0, 10);
    else throw new Error("Invalid Date");
}
export async function getFechaDefault(Fecha: string): Promise<string> {
    if (!isBool(Fecha)) {
        const fechaTrabajo = await getItem("fechatrabajo");
        if (typeof fechaTrabajo === 'string') return val2Date(fechaTrabajo).toISOString().slice(0, 10);
        return new Date().toISOString().slice(0, 10);
    }
    if (isNumeric(Fecha)) {
        return getFechaString(allTrim(Fecha));
    } else if (validarRegExpFecha(Fecha)) {
        let res = val2Date(Fecha);
        res.setUTCHours(10);
        return res.toISOString().slice(0, 10);
    }
    else throw new Error("Invalid Date");
}
export function getICCDefault(ICC: string): string { return allTrim(upperCase(ICC)) }
export function getBlocalDefault(Blocal: string): string { return val2Bool(Blocal) ? "T" : "F" }
export function getFiltroDefault(Filtro: string): string { return allTrim(upperCase(Filtro)) }
export function getIBodegaDefault(IBodega: string): string { return allTrim(upperCase(IBodega)) }
export function getIElementoDefault(IElemento: string): string { return allTrim(upperCase(IElemento)) }
export function getIValorMontoEscritoDefault(Valor: string): string { return allTrim(upperCase(Valor)) }
export function getNMonedaDefault(NMoneda: string): string { return !isBool(NMoneda) ? "pesos" : allTrim(NMoneda) }
export function getNCentavosDefault(NCentavos: string): string { return allTrim(NCentavos) }
export function getISedeDefault(ISede: string): string { return allTrim(ISede) }
export function getIListaDefault(ILista: string): string { return allTrim(ILista) }
export function getIActivoDefault(IActivo: string): string { return allTrim(IActivo) }
export function getILaborDefault(ILabor: string): string { return allTrim(ILabor) }
export function getListaActivosDefault(ListaActivos: string): string { return allTrim(ListaActivos) }
export function getICxXDefault(ICxX: string): string {
    ICxX = upperCase(allTrim(ICxX))
    if (!isBool(ICxX)) ICxX = "";
    if (ICxX !== "CXC" && ICxX !== "CXP" && ICxX !== "") throw ('El parámetro ingresado es incorrecto, la función solo aceptas los valores "CxC", "CxP"');
    return ICxX
}
