/* global setInterval, fetch, setTimeout */
import { ISRequest, TJSONObject, TReqResponse, isBool, isNumeric, lowerCase, upperCase, val2Bool, val2Date, val2Float, val2JSON, val2Str } from "@ingenieria_insoft/ispgen";
import { GetObjectCache, setObjectCache, verificarBorrarCache } from "./UlCache";
import { ISErrores, SServerProxy, config } from "./UlConst";
import { datosLogIn, saveError, registrarRequest, getItem, restaurarLogin } from "./UlStore";
import { val2Int } from "@ingenieria_insoft/ispgen";
import { ISEncrypt } from "@ingenieria_insoft/ispgen";

export type TFuncionServer = (respuesta: string | number) => void;
export type TASWRequest = {
	url: string,
	method: string,
	params: string,
	cachekey: string,
	fhcre: Date,
	finicial: Date,
	ffinal: Date,
	qtime: number,
	itddato?: "texto" | "fecha",
	fnresponse: TFuncionServer
}

export function getControlKey(ObjJson: TJSONObject, keyagente: number): string {
	let temp: String = new String(ISEncrypt.strToMD5(val2Str(ObjJson)));
	let sum: number = 0;
	for (let i in temp)
		sum += temp[i].charCodeAt(0);
	return val2Str(((val2Int(keyagente) + sum) * sum) - sum);
}

let QueueRequest: Array<TASWRequest> = new Array();
let qRequest = 0;

async function request(Obj: TASWRequest | undefined) {
	if (!Obj) return;
	if (qRequest > 8) return QueueRequest.push(Obj);
	let result: string | number = '#¿NOMBRE?';
	try {
		qRequest++;
		//se analza la cache y se le entrega al fnCAllBack
		let ObjCache = GetObjectCache(Obj.url, Obj.method, Obj.cachekey);
		if (isBool(ObjCache)) return result = ObjCache.response as string;
		//Realizacion de la peticion y manejo de los datos
		let response: TReqResponse = await ISRequest.Get(SServerProxy(Obj.url), {}, 30000);
		let json: any = val2JSON(response.responseText, undefined);
		Obj.ffinal = new Date();
		Obj.qtime = Obj.ffinal.getDate() - Obj.finicial.getDate();

		registrarRequest(Obj, response);

		//Se extrae el json de respuesta
		if (isBool(json?.result)) json = json.result;
		if (isBool(json[0])) json = json[0];

		let bIsErrorRequest: boolean =
			((response.statusCode < 200 || response.statusCode > 299)) ||
			(!isBool(json) || !(json instanceof Object)) ||
			isBool(json.error) ||
			(isBool(json.encabezado) && isBool(json.encabezado.resultado) && !val2Bool(json.encabezado.resultado))

		if (response.statusCode == 401) await restaurarLogin();

		//Manejo de errores controlados por el servidor (agente CP)
		if (bIsErrorRequest) return result = "#¡N/A!";
		result = val2Str(json.respuesta.datos.valor);
		if (isNumeric(result)) result = val2Float(result);
		else if (Obj.itddato == "fecha") result = val2Date(result).toLocaleDateString()

		//Manejo de la respuesta dada por el servidor en la peticion
		setObjectCache(Obj.url, Obj.method, Obj.cachekey, result);
	} catch (error) {
		//Registro de error si definitivamente la peticion fallo 
		saveError("dispachRequest", val2Str(Obj), error as Error);
	} finally {
		qRequest--;
		request(QueueRequest.shift())
		//Se resuelve la promesa creada al momento de inicializar la peticion
		Obj.fnresponse(result);
	}
}

export function fetchCE(method: string, params: string, itddato?: "texto" | "fecha"): Promise<string | number> {
	// Separar los pares clave-valor
	let ArrParams: Array<string> = params.split("&");
	let ArrPairs: Array<Array<string>> = ArrParams.map((par) => par.split("="));
	let cachekey = method + ":" + ArrPairs.map(([_, valor]) => valor).join(":");

	return new Promise((resolve, reject) => {
		try {
			requestCE(method, params, cachekey, resolve, itddato);
		} catch (error) {
			reject(error)
		}
	})
}

/**
 * Function general creada para le ejecucion de los metodos de ContaExcel
 * @param nMethod nombre del metodo que sera llamado
 * @param params parametros enviados necesarios por el metodo a ser llamado
 * @param cachekey Valor local para la utilizacion del cache en el sistemas
 * @param contadorFallo Variable creada para volver a ejecutar la peticion recursiva si esta llego a fallar y poder distinguir el tipo de peticion
 */
async function requestCE(method: string, params: string, cachekey: string, fnresponse: TFuncionServer, itddato?: "texto" | "fecha") {
	try {
		cachekey = upperCase(cachekey);
		method = lowerCase(method);
		let { url, keyagente } = await datosLogIn();
		//Busqueda en cache y verificacion de la sesion del usuario
		if (!isBool(url) || !isBool(keyagente) || keyagente == 0) throw new Error('Error CP#0000: Debe iniciar sesión primero')

		if (params.includes('"'))
			throw new Error('Se han ingresado valores en el formato incorrecto, por favor revise el formato. Puede existir algún valor ingresado que contenga comillas dobles (")');
		//Se verifica si se debe borrar el cache para la ejecucion
		await verificarBorrarCache();
		for (let index in ISErrores) if (params.includes(ISErrores[index])) return fnresponse(ISErrores[index]);
		//Se arma la URL para realizar la peticion
		url += "/DataSnap/rest/ContaPyme/ContaExcel/" + method + "/" + getControlKey(params as unknown as TJSONObject, keyagente)
			+ "/" + config.iapp + "/?" + encodeURIComponent(params);

		// Consulta del Obj cache y si esta siendo procesado por otra funcion, agrega la promesa al objeto para ejectuar el resolve Cuando esta termine
		let ObjCache: TJSONObject = GetObjectCache(url, method, cachekey);
		if (isBool(ObjCache)) return fnresponse(ObjCache.response as string);
		let Obj: TASWRequest = {
			url,
			method,
			params,
			cachekey,
			itddato,
			fhcre: new Date(),
			finicial: new Date(),
			ffinal: new Date(),
			qtime: 0,
			fnresponse
		}
		request(Obj)
	} catch (error) {
		saveError(method, params, error as Error);
		return fnresponse("#¡VALOR!");
	}
}
