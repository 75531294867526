import { prepareEncrypt } from "@ingenieria_insoft/ispclientjs";
import { asignarUbicacion, ISEncrypt, ServiciosInSoft, TInfoConnection, TIS_IApp, val2KeyIApp } from "@ingenieria_insoft/ispgen";

prepareEncrypt();

export const config = {
    iapp: "101",
    idmaquina: "CE"
};

export const ISErrores = ["#¡VALOR!", "#¡NUM!", "#¡N/A!", "#¿NOMBRE?"];

export const ISCamposExtracto = {
	CNTPUC: {
		agrupadores: ["ICUENTA", "BLOCAL", "NCUENTA", "BNIIF", "NCUENTANIIF", "ICUENTANIIF", "ICUENTAALTERNA", "ITDCUENTA", "INIVEL", "ICLASE", "ISUBCLASE", "BVISIBLE", "IEXIGETERC", "BEXIGEICC", "BSEDEXDEFECTO", "BEXIGEACTIVO", "BCONTROLACXX", "BMANEJACUOTAS", "BMANEJATERCERO", "BTEMPORALANIO", "BAFECDTMTE", "BAJUSTARXINF", "BDISPONIBLEGI", "IEXIGEBASE", "BAUTOACTIVAR", "BMANEJAOTRAMONEDA", "IMONEDA", "IDESCUENTO", "ICARGO", "ICARGO2", "ICARGO3", "SOBSERV", "IPADRE", "BEXIGEVALOR1", "BEXIGEVALOR2", "BEXIGECLASE1", "BEXIGECLASE2", "IUSUARIOULT", "IUSUARIO", "FULTIMA", "IWSULT", "FCREACION", "IWS", "QREGDCONC", "ISUCURSAL", "ICARGO4", "ICARGO5", "BPPTO", "ICUENTAORIGEN", "ITDTASA", "ICUENTAPUENTEDETERIORO", "ICUENTAGASTOSDETERIORO", "ICUENTAREVDETERIOROOTROPERIODO", "BCALCCOSTOAMORT", "PTASAND", "BCALCFLUJOFUTURO", "BCALCPROBNOPAGO", "QREGPORCNOPAGO", "BUSECUENTANIIF", "ICARGO6"],
		agregados: ["ICUENTA", "ICUENTANIIF", "ICUENTAALTERNA", "ITDCUENTA", "INIVEL", "ICLASE", "ISUBCLASE", "IEXIGETERC", "IEXIGEBASE", "IMONEDA", "IDESCUENTO", "ICARGO", "ICARGO2", "ICARGO3", "IPADRE", "IUSUARIOULT", "IUSUARIO", "FULTIMA", "IWSULT", "FCREACION", "IWS", "QREGDCONC", "ISUCURSAL", "ICARGO4", "ICARGO5", "ICUENTAORIGEN", "ITDTASA", "ICUENTAPUENTEDETERIORO", "ICUENTAGASTOSDETERIORO", "ICUENTAREVDETERIOROOTROPERIODO", "QREGPORCNOPAGO", "ICARGO6"]
	},
	ABANITS: {
		agrupadores: ["INIT", "NTERCERO", "IDIGCHEQUEO", "BEMPRESA", "NAPELLIDO", "ITDDOCUM", "BPROVEEDOR", "BCLIENTE", "BVENDEDOR", "BEMPLEADO", "NCOMERCIAL", "BNCOMERCIAL", "ISEXO", "FNACIMIENTO", "STRATAMIENTO", "SPROFESION", "SCARGO", "SWWW", "SEMAIL", "BVISIBLE", "SCLASIFLEGAL", "IPAIS", "IDEP", "IMUN", "TDIRECCION", "SBARRIO", "TTELEFONO", "TTELEFONO2", "TTELEFONO3", "TCELULAR", "TFAX", "ITDCATEGORIA", "ITDTERCERO", "SCODIGOALTERNO", "NZONA", "CLASE1", "CLASE2", "DATO1", "DATO2", "QVALOR1", "QVALOR2", "IVENDEDOR", "BPERFIL", "IPERFIL", "ILISTAPRECIOS", "BBLOQUEARCREDITOS", "IAUTORIZACIONBLQCREDITOS", "ITIPONEGOCIO", "IACTIVIDADECONOMICA", "MCUPOCREDITO", "BERROREXCESOCUPOCREDITO", "QDIASPLAZOCXC", "PDESCUENTOVENTASFIJO", "ICTANOMBRE", "ICTANUMERO", "ICTAFORMAPAGO", "IAUTORIZACIONBLQPAGOS", "BBLOQUEARPAGOS", "BMARCADIAN", "BERRDIAN", "CALC_IPAISIDEP", "CALC_IPAISIDEPIMUN", "IWS", "FCREACION", "IWSULT", "FULTIMA", "CALC_NTERCERONAPELLIDO", "CALC_NAPELLIDONTERCERO", "QREGDIRECCIONES", "QREGCONTACTOS", "QREGDATOSVENDEDOR", "QREGLINEAS", "QREGPRODUCTOS", "IUSUARIO", "IUSUARIOULT"],
		agregados: ["ITDDOCUM", "FNACIMIENTO", "QVALOR1", "QVALOR2", "MCUPOCREDITO", "QDIASPLAZOCXC", "PDESCUENTOVENTASFIJO", "QREGDIRECCIONES", "QREGCONTACTOS", "QREGDATOSVENDEDOR", "QREGLINEAS", "QREGPRODUCTOS"]
	},
	INVMREC: {
		agrupadores: ["IRECURSO", "NRECURSO", "NUNIDAD", "IGRUPOINV", "BESALIAS", "IRECURSOBASE", "BCONTROLINV", "BCONSUMO", "BVENTA", "BPRODUCTO", "BSERVICIO", "BVISIBLE", "BVISIBLEINTERNET", "BDESCFINANCIERO", "BESCOMISIONABLE", "BCODBARRAS", "SDESCRIP", "NUNIDADCOMPRA", "QFACTOR", "BALQUILER", "ITDTIEMPO", "NUNIDADQPESO", "QPESO", "NUNIDADQVOLUMEN", "QVOLUMEN", "SREFFABRICANTE", "SMARCA", "IDEPINV", "CLASE1", "CLASE2", "TIPO1", "TIPO2", "DATO1", "DATO2", "DATO3", "SOBSERVACIONES", "BCOMPUESTO", "ITDDESCARGA", "BAFECTACUENTAPADRE", "ITDFACTCOMPUESTO", "ITDCOTIZCOMPUESTO", "BCUENTAINV", "ICUENTAINV", "BCUENTADEVCOMPRA", "ICUENTADEVCOMPRA", "BCUENTADEVVENTA", "ICUENTADEVVENTA", "BCUENTACOSTOS", "ICUENTACOSTOS", "BCUENTAEGR", "ICUENTAEGR", "BICUENTASPORCLASEEGR", "BCUENTAVTA", "ICUENTAVTA", "BICUENTASPORCLASEING", "BCONCEPTOCOMPRA", "ICONCEPTOCOMPRA1", "ICONCEPTOCOMPRA2", "ICONCEPTOCOMPRA3", "ICONCEPTOCOMPRA4", "BCONCEPTOVENTA", "ICONCEPTOVENTA1", "ICONCEPTOVENTA2", "ICONCEPTOVENTA3", "ICONCEPTOVENTA4", "SPARAMS", "QREGPARTESCOMPUESTO", "QREGTRASLADOEGR", "QREGTRASLADOING", "BEXIGEDATO1", "BEXIGEDATO2", "BEXIGEDATO3", "BEXIGEDATO4", "BEXIGEDATO5", "BEXIGEDATO6", "BEXIGEVALOR1", "BEXIGEVALOR2", "IWS", "FCREACION", "IUSUARIO", "IWSULT", "FULTIMA", "IUSUARIOULT", "BIVAMAYORVALOR", "ISUCURSAL", "ICONCEPTOCOMPRA5", "ICONCEPTOCOMPRA6", "ICONCEPTOVENTA5", "BEXIGEVALOR3", "BEXIGEVALOR4", "BUNIDADVENTA", "NUNIDADVENTA", "QFACTORUNIDADVENTA", "BCONTEOUNDFISICA", "ICONCEPTOVENTA6", "QREGEQUIVALENTES", "BPERMITEASIGNARPRECIOVTA", "BOBSERVACIONESHTML", "BINCLUIRENCOTIZACION", "BERRORSICOSTOOPRECIOENCERO", "SMODELO", "ICONCEPTOCOMPRA7", "ICONCEPTOVENTA7", "BUNIDADCOMPRA", "BCANTIDADPORBASCULA", "BPERSONALIZARCTAS", "NUNIDADSTD", "BMUESTRAGRATIS", "ITDCODIFICACIONSTD", "SCODIGOSTD", "SURLIMG", "BEMPRESAASUMEIVA", "ICONCEPTOIVAFALTANTE", "BPERSONALIZARGRUPODIASINIVA", "IGRUPODIASINIVA", "SGENERODIASINIVA"],
		agregados: ["IRECURSO", "IGRUPOINV", "IRECURSOBASE", "QFACTOR", "ITDTIEMPO", "QPESO", "QVOLUMEN", "IDEPINV", "ITDDESCARGA", "ITDFACTCOMPUESTO", "ITDCOTIZCOMPUESTO", "ICUENTAINV", "ICUENTADEVCOMPRA", "ICUENTADEVVENTA", "ICUENTACOSTOS", "ICUENTAEGR", "ICUENTAVTA", "ICONCEPTOCOMPRA1", "ICONCEPTOCOMPRA2", "ICONCEPTOCOMPRA3", "ICONCEPTOCOMPRA4", "ICONCEPTOVENTA1", "ICONCEPTOVENTA2", "ICONCEPTOVENTA3", "ICONCEPTOVENTA4", "QREGPARTESCOMPUESTO", "QREGTRASLADOEGR", "QREGTRASLADOING", "FCREACION", "IUSUARIO", "FULTIMA", "IUSUARIOULT", "ISUCURSAL", "ICONCEPTOCOMPRA5", "ICONCEPTOCOMPRA6", "ICONCEPTOVENTA5", "QFACTORUNIDADVENTA", "ICONCEPTOVENTA6", "QREGEQUIVALENTES", "ICONCEPTOCOMPRA7", "ICONCEPTOVENTA7", "ITDCODIFICACIONSTD", "ICONCEPTOIVAFALTANTE", "IGRUPODIASINIVA"]
	},
	INVMAEST: {
		agrupadores: ["IEMP", "IINVENTARIO", "NINVENTARIO", "NRESP", "NLOCALIZ", "QPRECISION", "BINVENTARIOESTIMADO", "ICCDEFAULT", "ICUENTA", "ICUENTAING", "ICUENTAPERD", "ICUENTADEVCOMPRA", "ICUENTADEVVENTA", "IWS", "IWSULT", "FCREACION", "FULTIMA", "IUSUARIO", "IUSUARIOULT", "ISUCURSAL", "BINVENTARIOESTRICTO", "BVISIBLE"],
		agregados: ["IEMP", "IINVENTARIO", "QPRECISION", "ICCDEFAULT", "ICUENTA", "ICUENTAING", "ICUENTAPERD", "ICUENTADEVCOMPRA", "ICUENTADEVVENTA", "FCREACION", "FULTIMA", "IUSUARIO", "IUSUARIOULT", "ISUCURSAL"]
	},
	EMPMAESTCC: {
		agrupadores: ["IEMP", "ICC", "NCC", "NCORTO", "INIVEL", "ICCPADRE", "ISEDE", "ITDNODE", "INUMOPER", "IIMAGEN", "BESCC", "BIMPUTABLE", "BSELECTCC", "FSOPORT", "INITRESP", "IACTIVIDAD", "ITDCC", "SCLASIFICADORES", "SGRUPO", "IWS", "FCREACION", "IUSUARIO", "IWSULT", "FULTIMA", "IUSUARIOULT", "ISUCURSAL", "SVALORADIC1", "SVALORADIC2", "SVALORADIC3", "SVALORADIC4", "SVALORADIC5", "SVALORADIC6", "SVALORADIC7", "SVALORADIC8", "SVALORADIC9", "SVALORADIC10", "SVALORADIC11", "SVALORADIC12", "FECHA1ADIC", "FECHA2ADIC", "FECHA3ADIC"],
		agregados: ["IEMP", "ICC", "INIVEL", "ICCPADRE", "ISEDE", "ITDNODE", "INUMOPER", "FSOPORT", "INITRESP", "IACTIVIDAD", "ITDCC", "FCREACION", "IUSUARIO", "FULTIMA", "IUSUARIOULT", "ISUCURSAL", "FECHA1ADIC", "FECHA2ADIC", "FECHA3ADIC"]
	},
	ABAMDEPINV: {
		agrupadores: ["IDEPINV", "NDEPINV", "IWS", "FCREACION", "IWSULT", "FULTIMA", "IUSUARIO", "IUSUARIOULT", "ISUCURSAL", "BUSARITDGRAVAMEN", "ITDGRAVAMEN", "BBLOQUEARPORINVENTARIOFISICO"],
		agregados: ["IDEPINV", "FCREACION", "FULTIMA", "IUSUARIO", "IUSUARIOULT", "ISUCURSAL", "ITDGRAVAMEN"]
	},
	INVMGRUPO: {
		agrupadores: ["IGRUPOINV", "NGRUPO", "BCONTROLINV", "ICUENTACOSTOS", "BCONSUMO", "ICUENTAEGR", "BVENTA", "BPRODUCTO", "BSERVICIO", "ICUENTAVTA", "ICONCEPTOCOMPRA1", "ICONCEPTOCOMPRA2", "ICONCEPTOCOMPRA3", "ICONCEPTOCOMPRA4", "ICONCEPTOVENTA1", "ICONCEPTOVENTA2", "ICONCEPTOVENTA3", "ICONCEPTOVENTA4", "BICUENTASPORCLASEEGR", "BICUENTASPORCLASEING", "IWS", "FCREACION", "IWSULT", "FULTIMA", "IUSUARIO", "IUSUARIOULT", "QREGTRASLADOEGR", "QREGTRASLADOING", "BCUENTAINV", "ICUENTAINV", "BCUENTADEVCOMPRA", "ICUENTADEVCOMPRA", "BCUENTADEVVENTA", "ICUENTADEVVENTA", "BIVAMAYORVALOR", "ISUCURSAL", "ITDTRAZABILIDAD", "BEXIGETRAZABILIDADENTRADAS", "ETIQUETARPT", "ICONCEPTOCOMPRA5", "ICONCEPTOCOMPRA6", "ICONCEPTOVENTA5", "BCCPORBODEGA", "ICONCEPTOVENTA6", "ICUENTADETERIORO", "ICUENTAINGREVERSIONDET", "ETIQUETARPTEGR", "BVISIBLE", "FINIITDTRAZABILIDAD", "ICONCEPTOCOMPRA7", "ICONCEPTOVENTA7", "BPERSONALIZARCTAS", "BEMPRESAASUMEIVA", "ICONCEPTOIVAFALTANTE", "IGRUPODIASINIVA"],
		agregados: ["IGRUPOINV", "ICUENTACOSTOS", "ICUENTAEGR", "ICUENTAVTA", "ICONCEPTOCOMPRA1", "ICONCEPTOCOMPRA2", "ICONCEPTOCOMPRA3", "ICONCEPTOCOMPRA4", "ICONCEPTOVENTA1", "ICONCEPTOVENTA2", "ICONCEPTOVENTA3", "ICONCEPTOVENTA4", "FCREACION", "FULTIMA", "IUSUARIO", "IUSUARIOULT", "QREGTRASLADOEGR", "QREGTRASLADOING", "ICUENTAINV", "ICUENTADEVCOMPRA", "ICUENTADEVVENTA", "ISUCURSAL", "ITDTRAZABILIDAD", "ICONCEPTOCOMPRA5", "ICONCEPTOCOMPRA6", "ICONCEPTOVENTA5", "ICONCEPTOVENTA6", "ICUENTADETERIORO", "ICUENTAINGREVERSIONDET", "FINIITDTRAZABILIDAD", "ICONCEPTOCOMPRA7", "ICONCEPTOVENTA7", "ICONCEPTOIVAFALTANTE", "IGRUPODIASINIVA"]
	},
	KARMOVUN: {
		agrupadores: ["IEMP", "FSOPORT", "INUMOPER", "ILINEA", "IINVENTARIO", "IPRODUCTO", "ITDMOV", "IPRODUCTOORIGEN", "ITDMOVORIGEN", "ICCPRODUCTOR", "ISEDE", "ICC", "QPRODUCTO", "MCOSTO", "MPRECIOVTA", "IETAPA", "IESTADO", "QNUMCICLOCOSTOS", "IACTIVIDAD", "ITIPOPRODUCTO", "INIT", "INITVENDEDOR", "INITVENDEDOR2", "ITDSOP", "INUMSOP", "FSEMANA", "ICLASIFOP", "MIVA", "MDESCUENTO", "PIVA", "PDESCUENTO", "PIMPUESTO1", "PIMPUESTO2", "PIMPUESTO3", "ICUENTAPORFACTURAR", "BVENDIDO", "SOBSERV", "ILISTAPRECIOS", "FMES", "FDIA", "FANIO"],
		agregados: ["FMES", "FDIA", "FANIO", "FSOPORT", "MPRECIOCOMPONENTE", "QPRODUCTO", "MCOSTO", "MPRECIOVTA", "FSEMANA", "MIVA", "MDESCUENTO"]
	},
	CNTMOVUN: {
		agrupadores: ["IEMP", "FSOPORT", "INUMOPER", "FSEMANA", "ICC", "ISEDE", "ICUENTA", "ICTAPPTO", "ITDMOV", "ICCORIGEN", "IACTIVIDAD", "INIT", "INITCXX", "ITDSOP", "INUMSOP", "ICXX", "IACTIVO", "FPAGO", "ICLASIFOP", "TDETALLE", "MVALORBASE", "ICHEQUE", "MDEBITO", "MCREDITO", "IBANCO", "IFLUJO", "MEXTRANJERA", "CLASE1", "CLASE2", "VALOR1", "VALOR2", "FANIO", "FMES", "FDIA", "MSALDO"],
		agregados: ["MSALDO", "MDEBITO", "MCREDITO", "FSOPORT", "FANIO", "FMES", "FDIA", "MEXTRANJERA", "MVALORBASE"]
	},
}

export const TISdefCampos = {
	//INICIO CNTMOVUN
	IEMP: { datatype: { type: "numeric", size: 0 }, header: "Cod Emp" },
	FSOPORT: { datatype: { type: "date", size: 0 }, header: "Fecha documento soporte" },
	INUMOPER: { datatype: { type: "numeric", size: 0 }, header: "Numero operación" },
	ILINEAMOV: { datatype: { type: "numeric", size: 0 }, header: "Linea movimiento" },
	ILINEA: { datatype: { type: "numeric", size: 0 }, header: "Numero movimiento" },
	FSEMANA: { datatype: { type: "numeric", size: 0 }, header: "N° Semana" },
	ICC: { datatype: { type: "varchar", size: 20 }, header: "Centro de costos" },
	ISEDE: { datatype: { type: "varchar", size: 20 }, header: "Sede " },
	IETAPA: { datatype: { type: "numeric", size: 0 }, header: "Etapa productiva" },
	IESTADO: { datatype: { type: "numeric", size: 0 }, header: "Estado CC" },
	ICUENTA: { datatype: { type: "varchar", size: 30 }, header: "Cod Cuenta" },
	ICTAPPTO: { datatype: { type: "varchar", size: 30 }, header: "Cuenta presupuesto" },
	ITDMOV: { datatype: { type: "numeric", size: 0 }, header: "Codigo tipo movimiento" },
	ICCORIGEN: { datatype: { type: "varchar", size: 20 }, header: "CC Origen" },
	QNUMCICLOCOSTOS: { datatype: { type: "numeric", size: 0 }, header: "N° Ciclo costos" },
	IACTIVIDAD: { datatype: { type: "varchar", size: 6 }, header: "Actividad Empresarial" },
	ICUENTAFUENTE: { datatype: { type: "varchar", size: 30 }, header: "Cuenta ajuste por inflación" },
	INIT: { datatype: { type: "varchar", size: 20 }, header: "Cod Tercero" },
	INITCXX: { datatype: { type: "varchar", size: 20 }, header: "Cod Tercero Cartera" },
	ITDSOP: { datatype: { type: "numeric", size: 0 }, header: "Cod Documento Soporte" },
	INUMSOP: { datatype: { type: "varchar", size: 20 }, header: "Num Documento Soporte" },
	ICXX: { datatype: { type: "varchar", size: 32 }, header: "Id Cuenta xX" },
	IACTIVO: { datatype: { type: "varchar", size: 10 }, header: "Cod Activo" },
	ITDMOVAUX: { datatype: { type: "numeric", size: 0 }, header: "Tipo de movimiento auxiliar" },
	FPAGO: { datatype: { type: "date", size: 0 }, header: "Fecha Pago" },
	ICLASIFOP: { datatype: { type: "numeric", size: 0 }, header: "Cod Clase Operación" },
	TDETALLE: { datatype: { type: "varchar", size: 200 }, header: "Detalle movimiento" },
	MVALORBASE: { datatype: { type: "numeric", size: 15 }, header: "Valor base" },
	ICHEQUE: { datatype: { type: "varchar", size: 10 }, header: "Cod Cheque" },
	MDEBITO: { datatype: { type: "numeric", size: 15 }, header: "Valor debito" },
	MCREDITO: { datatype: { type: "numeric", size: 15 }, header: "Valor credito" },
	IBANCO: { datatype: { type: "varchar", size: 4 }, header: "Cod Banco" },
	IFLUJO: { datatype: { type: "varchar", size: 5 }, header: "Cod Flujo efectivo" },
	MEXTRANJERA: { datatype: { type: "numeric", size: 15 }, header: "Valor Moneda Extranjera" },
	BCCDEPRODUCCION: { datatype: { type: "varchar", size: 1 }, header: "Destinado a producción" },
	CLASE1: { datatype: { type: "varchar", size: 10 }, header: "Clase 1" },
	CLASE2: { datatype: { type: "varchar", size: 10 }, header: "Clase 2" },
	VALOR1: { datatype: { type: "numeric", size: 0 }, header: "Valor 1" },
	VALOR2: { datatype: { type: "numeric", size: 0 }, header: "Valor 2" },
	FANIO: { datatype: { type: "numeric", size: 0 }, header: "Año" },
	FMES: { datatype: { type: "numeric", size: 0 }, header: "Mes" },
	FDIA: { datatype: { type: "numeric", size: 0 }, header: "Día" },
	MSALDO: { datatype: { type: "numeric", size: 0 }, header: "Saldo" },
	//INICIO KARMOVUN
	BVENDIDO: { datatype: { type: "varchar", size: 0 }, header: "Es venta" },
	ICCPRODUCTOR: { datatype: { type: "varchar", size: 0 }, header: "Centro de costos productor" },
	ICUENTAPORFACTURAR: { datatype: { type: "numeric", size: 0 }, header: "Cod cuenta para facturar" },
	IDEPINV: { datatype: { type: "varchar", size: 10 }, header: "Cod departamento o linea" },
	IINVENTARIO: { datatype: { type: "numeric", size: 0 }, header: "Cod Bodega Inventario" },
	ILISTAPRECIOS: { datatype: { type: "numeric", size: 0 }, header: "Lista de precios" },
	INITVENDEDOR: { datatype: { type: "varchar", size: 20 }, header: "Cod tercero vendedor" },
	INITVENDEDOR2: { datatype: { type: "varchar", size: 20 }, header: "Cod tercero vendedor alterno" },
	IPRODUCTO: { datatype: { type: "varchar", size: 30 }, header: "Cod Producto" },
	IPRODUCTOORIGEN: { datatype: { type: "varchar", size: 30 }, header: "Cod producto origen" },
	ITDMOVORIGEN: { datatype: { type: "numeric", size: 0 }, header: "Tipo movimiento origen " },
	ITIPOPRODUCTO: { datatype: { type: "varchar", size: 6 }, header: "Cod tipo producto" },
	MCOSTO: { datatype: { type: "numeric", size: 15 }, header: "Costo" },
	MDESCUENTO: { datatype: { type: "numeric", size: 15 }, header: "Valor descuento" },
	MIVA: { datatype: { type: "numeric", size: 15 }, header: "Valor total IVA" },
	MPRECIOCOMPONENTE: { datatype: { type: "numeric", size: 15 }, header: "Precio componente" },
	MPRECIOVTA: { datatype: { type: "numeric", size: 15 }, header: "Precio total venta" },
	PDESCUENTO: { datatype: { type: "numeric", size: 0 }, header: "Porcentaje Descuento" },
	PIMPUESTO1: { datatype: { type: "numeric", size: 0 }, header: "Porcentaje impuesto ventas" },
	PIMPUESTO2: { datatype: { type: "numeric", size: 0 }, header: "Porcentaje impuesto ventas 2" },
	PIMPUESTO3: { datatype: { type: "numeric", size: 0 }, header: "Porcentaje impuesto ventas 3" },
	PIVA: { datatype: { type: "numeric", size: 0 }, header: "Porcentaje IVA" },
	QPRODUCTO: { datatype: { type: "numeric", size: 0 }, header: "Cantidad unidades movimiento" },
	SOBSERV: { datatype: { type: "varchar", size: 200 }, header: "Detalle" },
	//INICIO ABANITS
	NTERCERO: { datatype: { type: "varchar", size: 80 }, header: "Nombre o razón social" },
	IDIGCHEQUEO: { datatype: { type: "varchar", size: 2 }, header: "Digito de verificación NIT" },
	BEMPRESA: { datatype: { type: "varchar", size: 1 }, header: "Es persona juridica" },
	NAPELLIDO: { datatype: { type: "varchar", size: 30 }, header: "Apellido" },
	ITDDOCUM: { datatype: { type: "numeric", size: 0 }, header: "Tipo documento" },
	BPROVEEDOR: { datatype: { type: "varchar", size: 1 }, header: "Es proveedor" },
	ITDPROVEEDOR: { datatype: { type: "numeric", size: 0 }, header: "Tipo de proveedor" },
	BCLIENTE: { datatype: { type: "varchar", size: 1 }, header: "Es cliente" },
	ITDCLIENTE: { datatype: { type: "numeric", size: 0 }, header: "Tipo de cliente" },
	BVENDEDOR: { datatype: { type: "varchar", size: 1 }, header: "Es vendedor" },
	ITDVENDEDOR: { datatype: { type: "numeric", size: 0 }, header: "Tipo de vendedor" },
	BEMPLEADO: { datatype: { type: "varchar", size: 1 }, header: "Es empleado" },
	ITDEMPLEADO: { datatype: { type: "numeric", size: 0 }, header: "Tipo de empleado" },
	NCOMERCIAL: { datatype: { type: "varchar", size: 80 }, header: "Nombre comercial" },
	BNCOMERCIAL: { datatype: { type: "varchar", size: 1 }, header: "Mostrar nombre comercial" },
	ISEXO: { datatype: { type: "varchar", size: 1 }, header: "Sexo" },
	FNACIMIENTO: { datatype: { type: "date", size: 0 }, header: "Fecha de nacimiento" },
	STRATAMIENTO: { datatype: { type: "varchar", size: 15 }, header: "Tratamiento" },
	SPROFESION: { datatype: { type: "varchar", size: 15 }, header: "Profesion" },
	SCARGO: { datatype: { type: "varchar", size: 15 }, header: "Cargo" },
	SWWW: { datatype: { type: "varchar", size: 60 }, header: "Página web" },
	SEMAIL: { datatype: { type: "varchar", size: 60 }, header: "E-mail" },
	SMSN: { datatype: { type: "varchar", size: 60 }, header: "Messenger" },
	SSKYPE: { datatype: { type: "varchar", size: 30 }, header: "Skype" },
	BVISIBLE: { datatype: { type: "varchar", size: 1 }, header: "Visible en selección" },
	SCLASIFLEGAL: { datatype: { type: "varchar", size: 30 }, header: "Clasificación Legal" },
	IPAIS: { datatype: { type: "varchar", size: 5 }, header: "Cod Pais" },
	IDEP: { datatype: { type: "varchar", size: 5 }, header: "Cod departamento" },
	IMUN: { datatype: { type: "varchar", size: 5 }, header: "Cod municipio" },
	TDIRECCION: { datatype: { type: "varchar", size: 150 }, header: "Dirección" },
	SBARRIO: { datatype: { type: "varchar", size: 20 }, header: "Barrio" },
	TTELEFONO: { datatype: { type: "varchar", size: 30 }, header: "Telefono" },
	TTELEFONO2: { datatype: { type: "varchar", size: 30 }, header: "Telefono 2" },
	TTELEFONO3: { datatype: { type: "varchar", size: 30 }, header: "Telefono 3" },
	TCELULAR: { datatype: { type: "varchar", size: 30 }, header: "Celular" },
	TFAX: { datatype: { type: "varchar", size: 30 }, header: "Fax" },
	ITDCATEGORIA: { datatype: { type: "varchar", size: 20 }, header: "Categoria del tercero" },
	ITDTERCERO: { datatype: { type: "varchar", size: 20 }, header: "Tipo de tercero" },
	SCODIGOALTERNO: { datatype: { type: "varchar", size: 20 }, header: "Código alterno" },
	NZONA: { datatype: { type: "varchar", size: 10 }, header: "Zona" },
	DATO1: { datatype: { type: "varchar", size: 20 }, header: "Dato 1" },
	DATO2: { datatype: { type: "varchar", size: 20 }, header: "Dato 2" },
	QVALOR1: { datatype: { type: "numeric", size: 0 }, header: "Valor 1" },
	QVALOR2: { datatype: { type: "numeric", size: 0 }, header: "Valor 2" },
	IVENDEDOR: { datatype: { type: "varchar", size: 20 }, header: "Tercero vendedor" },
	BPERFIL: { datatype: { type: "varchar", size: 1 }, header: "Es perfil asociado" },
	IPERFIL: { datatype: { type: "varchar", size: 10 }, header: "Cod perfil asociado" },
	BBLOQUEARCREDITOS: { datatype: { type: "varchar", size: 1 }, header: "Bloqueados creditos" },
	IAUTORIZACIONBLQCREDITOS: { datatype: { type: "varchar", size: 20 }, header: "Cod autorización bloqueo" },
	ITIPONEGOCIO: { datatype: { type: "varchar", size: 30 }, header: "Tipo negocio" },
	IACTIVIDADECONOMICA: { datatype: { type: "varchar", size: 30 }, header: "Cod actividad economica" },
	MCUPOCREDITO: { datatype: { type: "numeric", size: 15 }, header: "Cupo máximo crédito" },
	BERROREXCESOCUPOCREDITO: { datatype: { type: "varchar", size: 1 }, header: "Mostrar error Cupo" },
	QDIASPLAZOCXC: { datatype: { type: "numeric", size: 0 }, header: "Numero de dias plazo CxC" },
	PDESCUENTOVENTASFIJO: { datatype: { type: "numeric", size: 0 }, header: "Porcentaje de descuento ventas" },
	ICTANOMBRE: { datatype: { type: "varchar", size: 20 }, header: "Nombre titular cuenta" },
	ICTANUMERO: { datatype: { type: "varchar", size: 20 }, header: "Numero de la cuenta" },
	ICTABANCO: { datatype: { type: "varchar", size: 20 }, header: "Entidad bancaria cuenta" },
	ICTATIPO: { datatype: { type: "varchar", size: 20 }, header: "Tipo de cuenta" },
	ICTAFORMAPAGO: { datatype: { type: "varchar", size: 20 }, header: "Forma de pago" },
	IAUTORIZACIONBLQPAGOS: { datatype: { type: "varchar", size: 20 }, header: "Código de laautorización delbloqueo" },
	BBLOQUEARPAGOS: { datatype: { type: "varchar", size: 1 }, header: "Proveedor bloqueado" },
	BMARCADIAN: { datatype: { type: "varchar", size: 1 }, header: "Genera formato medios magnéticos" },
	BERRDIAN: { datatype: { type: "varchar", size: 1 }, header: "Error formato medios magnéticos" },
	CALC_IPAISIDEP: { datatype: { type: "varchar", size: 20 }, header: "" },
	CALC_IPAISIDEPIMUN: { datatype: { type: "varchar", size: 20 }, header: "" },
	IWS: { datatype: { type: "varchar", size: 6 }, header: "Código equipo registro" },
	FCREACION: { datatype: { type: "timestamp", size: 0 }, header: "Fecha/Hora registro" },
	IWSULT: { datatype: { type: "varchar", size: 20 }, header: "Codigo equipo modificación registro" },
	FULTIMA: { datatype: { type: "timestamp", size: 0 }, header: "Fecha/Hora modificación registro" },
	CALC_NTERCERONAPELLIDO: { datatype: { type: "varchar", size: 20 }, header: "Nombre y apellido" },
	CALC_NAPELLIDONTERCERO: { datatype: { type: "varchar", size: 20 }, header: "Apellido y nombre" },
	QREGDIRECCIONES: { datatype: { type: "numeric", size: 0 }, header: "Cantidad direcciones" },
	QREGCONTACTOS: { datatype: { type: "numeric", size: 0 }, header: "Cantidad contactos" },
	QREGDATOSVENDEDOR: { datatype: { type: "numeric", size: 0 }, header: "Cantidad registros datos vendedor" },
	QREGLINEAS: { datatype: { type: "numeric", size: 0 }, header: "Cantidad lineas" },
	QREGPRODUCTOS: { datatype: { type: "numeric", size: 0 }, header: "Cantidad productos" },
	IUSUARIO: { datatype: { type: "varchar", size: 10 }, header: "Cod usuario registro" },
	IUSUARIOULT: { datatype: { type: "varchar", size: 10 }, header: "Cod usuario modificación registro" },
	//CNTPUC
	BLOCAL: { datatype: { type: "varchar", size: 0 }, header: " " },
	NCUENTA: { datatype: { type: "varchar", size: 0 }, header: " " },
	BNIIF: { datatype: { type: "varchar", size: 0 }, header: " " },
	NCUENTANIIF: { datatype: { type: "varchar", size: 0 }, header: " " },
	ICUENTANIIF: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICUENTAALTERNA: { datatype: { type: "numeric", size: 0 }, header: " " },
	ITDCUENTA: { datatype: { type: "numeric", size: 0 }, header: " " },
	INIVEL: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICLASE: { datatype: { type: "numeric", size: 0 }, header: " " },
	ISUBCLASE: { datatype: { type: "numeric", size: 0 }, header: " " },
	IEXIGETERC: { datatype: { type: "numeric", size: 0 }, header: " " },
	BEXIGEICC: { datatype: { type: "varchar", size: 0 }, header: " " },
	BSEDEXDEFECTO: { datatype: { type: "varchar", size: 0 }, header: " " },
	BEXIGEACTIVO: { datatype: { type: "varchar", size: 0 }, header: " " },
	BCONTROLACXX: { datatype: { type: "varchar", size: 0 }, header: " " },
	BMANEJACUOTAS: { datatype: { type: "varchar", size: 0 }, header: " " },
	BMANEJATERCERO: { datatype: { type: "varchar", size: 0 }, header: " " },
	BTEMPORALANIO: { datatype: { type: "varchar", size: 0 }, header: " " },
	BAFECDTMTE: { datatype: { type: "varchar", size: 0 }, header: " " },
	BAJUSTARXINF: { datatype: { type: "varchar", size: 0 }, header: " " },
	BDISPONIBLEGI: { datatype: { type: "varchar", size: 0 }, header: " " },
	IEXIGEBASE: { datatype: { type: "numeric", size: 0 }, header: " " },
	BAUTOACTIVAR: { datatype: { type: "varchar", size: 0 }, header: " " },
	BMANEJAOTRAMONEDA: { datatype: { type: "varchar", size: 0 }, header: " " },
	IMONEDA: { datatype: { type: "numeric", size: 0 }, header: " " },
	IDESCUENTO: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICARGO: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICARGO2: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICARGO3: { datatype: { type: "numeric", size: 0 }, header: " " },
	IPADRE: { datatype: { type: "numeric", size: 0 }, header: " " },
	BEXIGEVALOR1: { datatype: { type: "varchar", size: 0 }, header: " " },
	BEXIGEVALOR2: { datatype: { type: "varchar", size: 0 }, header: " " },
	BEXIGECLASE1: { datatype: { type: "varchar", size: 0 }, header: " " },
	BEXIGECLASE2: { datatype: { type: "varchar", size: 0 }, header: " " },
	QREGDCONC: { datatype: { type: "varchar", size: 0 }, header: " " },
	ISUCURSAL: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICARGO4: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICARGO5: { datatype: { type: "numeric", size: 0 }, header: " " },
	BPPTO: { datatype: { type: "varchar", size: 0 }, header: " " },
	ICUENTAORIGEN: { datatype: { type: "numeric", size: 0 }, header: " " },
	ITDTASA: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICUENTAPUENTEDETERIORO: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICUENTAGASTOSDETERIORO: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICUENTAREVDETERIOROOTROPERIODO: { datatype: { type: "numeric", size: 0 }, header: " " },
	BCALCCOSTOAMORT: { datatype: { type: "varchar", size: 0 }, header: " " },
	PTASAND: { datatype: { type: "varchar", size: 0 }, header: " " },
	BCALCFLUJOFUTURO: { datatype: { type: "varchar", size: 0 }, header: " " },
	BCALCPROBNOPAGO: { datatype: { type: "varchar", size: 0 }, header: " " },
	QREGPORCNOPAGO: { datatype: { type: "numeric", size: 0 }, header: " " },
	BUSECUENTANIIF: { datatype: { type: "varchar", size: 0 }, header: " " },
	ICARGO6: { datatype: { type: "numeric", size: 0 }, header: " " },
	//INVMREC
	IRECURSO: { datatype: { type: "numeric", size: 0 }, header: " " },
	NRECURSO: { datatype: { type: "varchar", size: 0 }, header: " " },
	NUNIDAD: { datatype: { type: "varchar", size: 0 }, header: " " },
	IGRUPOINV: { datatype: { type: "numeric", size: 0 }, header: " " },
	BESALIAS: { datatype: { type: "varchar", size: 0 }, header: " " },
	IRECURSOBASE: { datatype: { type: "numeric", size: 0 }, header: " " },
	BCONTROLINV: { datatype: { type: "varchar", size: 0 }, header: " " },
	BCONSUMO: { datatype: { type: "varchar", size: 0 }, header: " " },
	BVENTA: { datatype: { type: "varchar", size: 0 }, header: " " },
	BPRODUCTO: { datatype: { type: "varchar", size: 0 }, header: " " },
	BSERVICIO: { datatype: { type: "varchar", size: 0 }, header: " " },
	BVISIBLEINTERNET: { datatype: { type: "varchar", size: 0 }, header: " " },
	BDESCFINANCIERO: { datatype: { type: "varchar", size: 0 }, header: " " },
	BESCOMISIONABLE: { datatype: { type: "varchar", size: 0 }, header: " " },
	BCODBARRAS: { datatype: { type: "varchar", size: 0 }, header: " " },
	SDESCRIP: { datatype: { type: "varchar", size: 0 }, header: " " },
	NUNIDADCOMPRA: { datatype: { type: "varchar", size: 0 }, header: " " },
	QFACTOR: { datatype: { type: "numeric", size: 0 }, header: " " },
	BALQUILER: { datatype: { type: "varchar", size: 0 }, header: " " },
	ITDTIEMPO: { datatype: { type: "numeric", size: 0 }, header: " " },
	NUNIDADQPESO: { datatype: { type: "varchar", size: 0 }, header: " " },
	QPESO: { datatype: { type: "numeric", size: 0 }, header: " " },
	NUNIDADQVOLUMEN: { datatype: { type: "varchar", size: 0 }, header: " " },
	QVOLUMEN: { datatype: { type: "numeric", size: 0 }, header: " " },
	SREFFABRICANTE: { datatype: { type: "varchar", size: 0 }, header: " " },
	SMARCA: { datatype: { type: "varchar", size: 0 }, header: " " },
	TIPO1: { datatype: { type: "varchar", size: 0 }, header: " " },
	TIPO2: { datatype: { type: "varchar", size: 0 }, header: " " },
	DATO3: { datatype: { type: "varchar", size: 0 }, header: " " },
	SOBSERVACIONES: { datatype: { type: "varchar", size: 0 }, header: " " },
	BCOMPUESTO: { datatype: { type: "varchar", size: 0 }, header: " " },
	ITDDESCARGA: { datatype: { type: "numeric", size: 0 }, header: " " },
	BAFECTACUENTAPADRE: { datatype: { type: "varchar", size: 0 }, header: " " },
	ITDFACTCOMPUESTO: { datatype: { type: "numeric", size: 0 }, header: " " },
	ITDCOTIZCOMPUESTO: { datatype: { type: "numeric", size: 0 }, header: " " },
	BCUENTAINV: { datatype: { type: "varchar", size: 0 }, header: " " },
	ICUENTAINV: { datatype: { type: "numeric", size: 0 }, header: " " },
	BCUENTADEVCOMPRA: { datatype: { type: "varchar", size: 0 }, header: " " },
	ICUENTADEVCOMPRA: { datatype: { type: "numeric", size: 0 }, header: " " },
	BCUENTADEVVENTA: { datatype: { type: "varchar", size: 0 }, header: " " },
	ICUENTADEVVENTA: { datatype: { type: "numeric", size: 0 }, header: " " },
	BCUENTACOSTOS: { datatype: { type: "varchar", size: 0 }, header: " " },
	ICUENTACOSTOS: { datatype: { type: "numeric", size: 0 }, header: " " },
	BCUENTAEGR: { datatype: { type: "varchar", size: 0 }, header: " " },
	ICUENTAEGR: { datatype: { type: "numeric", size: 0 }, header: " " },
	BICUENTASPORCLASEEGR: { datatype: { type: "varchar", size: 0 }, header: " " },
	BCUENTAVTA: { datatype: { type: "varchar", size: 0 }, header: " " },
	ICUENTAVTA: { datatype: { type: "numeric", size: 0 }, header: " " },
	BICUENTASPORCLASEING: { datatype: { type: "varchar", size: 0 }, header: " " },
	BCONCEPTOCOMPRA: { datatype: { type: "varchar", size: 0 }, header: " " },
	ICONCEPTOCOMPRA1: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICONCEPTOCOMPRA2: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICONCEPTOCOMPRA3: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICONCEPTOCOMPRA4: { datatype: { type: "numeric", size: 0 }, header: " " },
	BCONCEPTOVENTA: { datatype: { type: "varchar", size: 0 }, header: " " },
	ICONCEPTOVENTA1: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICONCEPTOVENTA2: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICONCEPTOVENTA3: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICONCEPTOVENTA4: { datatype: { type: "numeric", size: 0 }, header: " " },
	SPARAMS: { datatype: { type: "varchar", size: 0 }, header: " " },
	QREGPARTESCOMPUESTO: { datatype: { type: "varchar", size: 0 }, header: " " },
	QREGTRASLADOEGR: { datatype: { type: "varchar", size: 0 }, header: " " },
	QREGTRASLADOING: { datatype: { type: "varchar", size: 0 }, header: " " },
	BEXIGEDATO1: { datatype: { type: "varchar", size: 0 }, header: " " },
	BEXIGEDATO2: { datatype: { type: "varchar", size: 0 }, header: " " },
	BEXIGEDATO3: { datatype: { type: "varchar", size: 0 }, header: " " },
	BEXIGEDATO4: { datatype: { type: "varchar", size: 0 }, header: " " },
	BEXIGEDATO5: { datatype: { type: "varchar", size: 0 }, header: " " },
	BEXIGEDATO6: { datatype: { type: "varchar", size: 0 }, header: " " },
	BIVAMAYORVALOR: { datatype: { type: "varchar", size: 0 }, header: " " },
	ICONCEPTOCOMPRA5: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICONCEPTOCOMPRA6: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICONCEPTOVENTA5: { datatype: { type: "numeric", size: 0 }, header: " " },
	BEXIGEVALOR3: { datatype: { type: "varchar", size: 0 }, header: " " },
	BEXIGEVALOR4: { datatype: { type: "varchar", size: 0 }, header: " " },
	BUNIDADVENTA: { datatype: { type: "varchar", size: 0 }, header: " " },
	NUNIDADVENTA: { datatype: { type: "varchar", size: 0 }, header: " " },
	QFACTORUNIDADVENTA: { datatype: { type: "numeric", size: 0 }, header: " " },
	BCONTEOUNDFISICA: { datatype: { type: "varchar", size: 0 }, header: " " },
	ICONCEPTOVENTA6: { datatype: { type: "numeric", size: 0 }, header: " " },
	QREGEQUIVALENTES: { datatype: { type: "numeric", size: 0 }, header: " " },
	BPERMITEASIGNARPRECIOVTA: { datatype: { type: "varchar", size: 0 }, header: " " },
	BOBSERVACIONESHTML: { datatype: { type: "varchar", size: 0 }, header: " " },
	BINCLUIRENCOTIZACION: { datatype: { type: "varchar", size: 0 }, header: " " },
	BERRORSICOSTOOPRECIOENCERO: { datatype: { type: "varchar", size: 0 }, header: " " },
	SMODELO: { datatype: { type: "varchar", size: 0 }, header: " " },
	ICONCEPTOCOMPRA7: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICONCEPTOVENTA7: { datatype: { type: "numeric", size: 0 }, header: " " },
	BUNIDADCOMPRA: { datatype: { type: "varchar", size: 0 }, header: " " },
	BCANTIDADPORBASCULA: { datatype: { type: "varchar", size: 0 }, header: " " },
	BPERSONALIZARCTAS: { datatype: { type: "varchar", size: 0 }, header: " " },
	NUNIDADSTD: { datatype: { type: "varchar", size: 0 }, header: " " },
	BMUESTRAGRATIS: { datatype: { type: "varchar", size: 0 }, header: " " },
	ITDCODIFICACIONSTD: { datatype: { type: "numeric", size: 0 }, header: " " },
	SCODIGOSTD: { datatype: { type: "varchar", size: 0 }, header: " " },
	SURLIMG: { datatype: { type: "varchar", size: 0 }, header: " " },
	BEMPRESAASUMEIVA: { datatype: { type: "varchar", size: 0 }, header: " " },
	ICONCEPTOIVAFALTANTE: { datatype: { type: "numeric", size: 0 }, header: " " },
	BPERSONALIZARGRUPODIASINIVA: { datatype: { type: "varchar", size: 0 }, header: " " },
	IGRUPODIASINIVA: { datatype: { type: "numeric", size: 0 }, header: " " },
	SGENERODIASINIVA: { datatype: { type: "varchar", size: 0 }, header: " " },
	//INVMAEST
	NINVENTARIO: { datatype: { type: "varchar", size: 0 }, header: " " },
	NRESP: { datatype: { type: "varchar", size: 0 }, header: " " },
	NLOCALIZ: { datatype: { type: "varchar", size: 0 }, header: " " },
	QPRECISION: { datatype: { type: "numeric", size: 0 }, header: " " },
	BINVENTARIOESTIMADO: { datatype: { type: "varchar", size: 0 }, header: " " },
	ICCDEFAULT: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICUENTAING: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICUENTAPERD: { datatype: { type: "numeric", size: 0 }, header: " " },
	BINVENTARIOESTRICTO: { datatype: { type: "varchar", size: 0 }, header: " " },
	//ABAMDEPINV
	NDEPINV: { datatype: { type: "varchar", size: 0 }, header: " " },
	BUSARITDGRAVAMEN: { datatype: { type: "varchar", size: 0 }, header: " " },
	ITDGRAVAMEN: { datatype: { type: "varchar", size: 0 }, header: " " },
	BBLOQUEARPORINVENTARIOFISICO: { datatype: { type: "varchar", size: 0 }, header: " " },
	//INVMGRUPO
	NGRUPO: { datatype: { type: "varchar", size: 0 }, header: " " },
	ITDTRAZABILIDAD: { datatype: { type: "numeric", size: 0 }, header: " " },
	BEXIGETRAZABILIDADENTRADAS: { datatype: { type: "varchar", size: 0 }, header: " " },
	ETIQUETARPT: { datatype: { type: "varchar", size: 0 }, header: " " },
	BCCPORBODEGA: { datatype: { type: "varchar", size: 0 }, header: " " },
	ICUENTADETERIORO: { datatype: { type: "numeric", size: 0 }, header: " " },
	ICUENTAINGREVERSIONDET: { datatype: { type: "numeric", size: 0 }, header: " " },
	ETIQUETARPTEGR: { datatype: { type: "varchar", size: 0 }, header: " " },
	FINIITDTRAZABILIDAD: { datatype: { type: "varchar", size: 0 }, header: " " },
	//EMPMAESTCC
	NCC: { datatype: { type: "varchar", size: 0 }, header: " " },
	NCORTO: { datatype: { type: "varchar", size: 0 }, header: " " },
	ICCPADRE: { datatype: { type: "numeric", size: 0 }, header: " " },
	ITDNODE: { datatype: { type: "numeric", size: 0 }, header: " " },
	IIMAGEN: { datatype: { type: "numeric", size: 0 }, header: " " },
	BESCC: { datatype: { type: "varchar", size: 0 }, header: " " },
	BIMPUTABLE: { datatype: { type: "varchar", size: 0 }, header: " " },
	BSELECTCC: { datatype: { type: "varchar", size: 0 }, header: " " },
	INITRESP: { datatype: { type: "numeric", size: 0 }, header: " " },
	ITDCC: { datatype: { type: "numeric", size: 0 }, header: " " },
	SCLASIFICADORES: { datatype: { type: "varchar", size: 0 }, header: " " },
	SGRUPO: { datatype: { type: "varchar", size: 0 }, header: " " },
	SVALORADIC1: { datatype: { type: "varchar", size: 0 }, header: " " },
	SVALORADIC2: { datatype: { type: "varchar", size: 0 }, header: " " },
	SVALORADIC3: { datatype: { type: "varchar", size: 0 }, header: " " },
	SVALORADIC4: { datatype: { type: "varchar", size: 0 }, header: " " },
	SVALORADIC5: { datatype: { type: "varchar", size: 0 }, header: " " },
	SVALORADIC6: { datatype: { type: "varchar", size: 0 }, header: " " },
	SVALORADIC7: { datatype: { type: "varchar", size: 0 }, header: " " },
	SVALORADIC8: { datatype: { type: "varchar", size: 0 }, header: " " },
	SVALORADIC9: { datatype: { type: "varchar", size: 0 }, header: " " },
	SVALORADIC10: { datatype: { type: "varchar", size: 0 }, header: " " },
	SVALORADIC11: { datatype: { type: "varchar", size: 0 }, header: " " },
	SVALORADIC12: { datatype: { type: "varchar", size: 0 }, header: " " },
	FECHA1ADIC: { datatype: { type: "timestamp", size: 0 }, header: " " },
	FECHA2ADIC: { datatype: { type: "timestamp", size: 0 }, header: " " },
	FECHA3ADIC: { datatype: { type: "timestamp", size: 0 }, header: " " }
};

var URLSserverProxy = "https://sserver-proxy.azurewebsites.net/api/proxy";
export function SServerProxy(url: string): string {
	return `${URLSserverProxy}/${ISEncrypt.strToBase64URL(url)}`
}

export async function InitialiceApp() {
	let ubicacionProxy: TInfoConnection = await asignarUbicacion(val2KeyIApp(TIS_IApp.AppContaExcl), "sserver-proxy" as ServiciosInSoft);
	URLSserverProxy = `https://${ubicacionProxy.host}/api/proxy`;
}
//Se inicializa la aplicacion
InitialiceApp()