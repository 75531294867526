/* global OfficeExtension */
import { TJSONObject, allTrim, isBool, isNumeric, upperCase, val2Date, val2Str } from "@ingenieria_insoft/ispgen";
import { ISErrores, ISCamposExtracto } from "../lib/UlConst";
import * as ISData from "../lib/UlDataDefault";
import { bIsText, generarSQL, generarfiltro } from "../lib/UlGeneralTool";
import { fetchCE } from "../lib/UlRequest";
import { getItem, getKeys, removeItems, saveError, setItem } from "../lib/UlStore";

const E1 = ISErrores[0];
const ErrorParam = new Error("El parámetro ingresado es incorrecto, la función requiere un parámetro que se ha brindado como un valor vacío o incorrecto.")
const ErrorNum = new Error("El parámetro solo puede contener valores númericos, en caso de ser varios valores, se pueden enviar separados por punto y coma ( ; ) o coma ( , ).");
const ErrorDate = new Error("Se han ingresado valores no numéricos, por favor revise el formato. Se recomienda utilizar la función fecha para este argumento, así se evitarán confusiones entre fechas.Ej. FECHA(2017;12;31)")
const ErrorNoParam = new Error("Se debe tener al menos un parámetro.")
function validarAgregado(Valores: string, arregloValoresTexto: Array<string>): string {
	try {
		let txtAgrupadores = "";
		if (Valores !== null) {
			let splitValores = Valores.split(",");
			for (let index = 0; index < splitValores.length; index++) {
				let s: string = allTrim(upperCase(splitValores[index]))
				let dato: string | Array<string> = s.split("(");
				let boolSplit = false;
				if (dato.length > 1)
					dato = dato[1].slice(0, -1);
				else {
					dato = dato[0];
					boolSplit = true;
				}
				let indexArreglo = arregloValoresTexto.indexOf(dato);
				if (indexArreglo !== -1) {
					if (boolSplit) txtAgrupadores += "SUM(" + arregloValoresTexto[indexArreglo] + "),"
					else if (s.indexOf("SUM(") != -1 || s.indexOf("MIN(") != -1 || s.indexOf("MAX(") != -1 || s.indexOf("AVG(") != -1 || s.indexOf("CONT(") != -1)
						txtAgrupadores += s + ","
				}
			}
		}
		return txtAgrupadores.slice(0, -1);
	} catch (error) {
		saveError("validarDatosValoresInformes", Valores, error as Error);
		return ""
	}
}
function validarDatosValoresInformes(Valores: string, arregloValoresTexto: Array<string>): string {
	try {
		let txtAgrupadores = "";
		if (isBool(Valores)) {
			let splitValores = Valores.split(",");
			for (let index = 0; index < splitValores.length; index++) {
				let indexArreglo = arregloValoresTexto.indexOf(splitValores[index].trim().toUpperCase());
				if (indexArreglo !== -1) txtAgrupadores += splitValores[index].trim().toUpperCase() + ",";
			}
		}
		return txtAgrupadores.slice(0, -1);
	} catch (error) {
		saveError("validarDatosValoresInformes", Valores, error as Error);
		return "";
	}
}
async function _IEmp(IEmp: string) { return ISData.getIempDefault(IEmp) }
async function _FechaFin(FechaFin: string) { return ISData.getFechaDefault(FechaFin); }
function _FechaIni(FechaIni: string) { return ISData.getFechaIniDefault(FechaIni) }
function _BLocal(BLocal: string) { return ISData.getBlocalDefault(BLocal) }
function _ILabor(ILabor: string) { return ISData.getILaborDefault(ILabor) }
function _ICC(ICC: string) { return ISData.getICCDefault(ICC) }
function _Filtro(Filtro: string) { return ISData.getFiltroDefault(Filtro) }
function _ITercero(ITercero: string) { return ISData.getITerceroDefault(ITercero) }
function _ITDContrato(ITDContrato: string) { return ISData.getITDContratoDefault(ITDContrato) }
function _IContrato(IContrato: string) { return ISData.getIContratoDefault(IContrato) }
function _IBodega(IBodega: string) { return ISData.getIBodegaDefault(IBodega) }
function _IElemento(IElemento: string) { return ISData.getIElementoDefault(IElemento) }
function _ICuenta(ICuenta: string) { return ISData.getICuentaDefault(ICuenta) }
function _IActividad(IActividad: string) { return ISData.getIActividadDefault(IActividad) }
function _IActivo(IActivo: string) { return ISData.getIActivoDefault(IActivo) }
function _IDebCred(IDebCred: string) { return ISData.getIDebCredDefault(IDebCred) }
////////////////////////////////FUNCIONES PROPIAS DE EXCEL ///////////////////////////////
/**
 * return value for name of storage
 * @customfunction
 * @param name name key of storage
 * @returns value of key storage
 */
export function getstorage(name: string): Promise<string | null> {
	return getItem(name);
}
/**
 * set value for name of storage
 * @customfunction
 * @param name name key of storage
 * @param value valye fo rkey storage
 * @returns none
 */
export function setstorage(name: string, value: string): Promise<void> {
	return setItem(name, value);
}
/**
 * return keys name of storage
 * @customfunction
 * @returns keys name of storage
 */
export async function getkeysstorage(): Promise<string> {
	let keys: Array<string> = await getKeys()
	return keys.join(";");
}
/**
 * remove keys name of storage
 * @customfunction
 * @returns void
 */
export async function deletekeysstorage(): Promise<void> {
	return removeItems(await getKeys())
}
////////////////////////////////INICIO METODOS PERSONALIZADOS EXCEL ///////////////////////////////
//#region ATRIBUTOS Y CARTERA
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Esta función es utilizada para conocer cualquier atributo de un centro de costos según el código dado. Tenga en cuenta que los atributos son características dadas a cada centro de costos y se configuran desde el explorador gráfico de empresas de ContaPyme®.
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoActivo.html
 * @param {string} NAtributo Determina el tipo de atributo que desea obtener
 * @param {string} IActivo Código de un activo creado en el catálogo de activos de ContaPyme®. 
 * @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
 * @param {string} [Fecha] Determina la fecha donde realiza el cálculo.
 * @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
 */
export async function atributoActivo(NAtributo: string, IActivo: string, IEmp: string, Fecha: string, BLocal: string): Promise<string | number> {
	let nMethod = "AtributoActivo";
	let params = "";
	try {
		NAtributo = ISData.getNAtributoActivoDefault(NAtributo);
		params += "natributo=" + (NAtributo);
		IActivo = _IActivo(IActivo);
		params += "&IActivo=" + (IActivo);
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		Fecha = await _FechaFin(Fecha);
		params += "&fecha=" + Fecha;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		if (NAtributo == "" || IActivo == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función suma los usos de un activo en las unidades configuradas por el usuario entre un periodo de tiempo. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadUsoActivo.html
* @param {string} ListaActivos Código de uno o más activos del catálogo de activos de ContaPyme® a los cuales se desea consultar el uso.  
* @param {string} [ILabor] Código de una o varias labores, las cuales son imputadas en el uso del activo. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function cantidadUsoActivo(ListaActivos: string, ILabor: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "cantidadUsoActivo"
	let params = "";
	try {
		ListaActivos = ISData.getListaActivosDefault(ListaActivos);
		params += "ListaActivos=" + (ListaActivos);
		ILabor = _ILabor(ILabor);
		params += "&ILabor=" + ILabor;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		if (ListaActivos == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula la cantidad de veces que se ha utilizado un activo con un filtro determinado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadUsoActivoFiltro.html
* @param {string} [Filtro] Filtro que se desea aplicar a la función
* @param {string} [ListaActivos] Código de uno o más activos del catálogo de activos de ContaPyme® a los cuales se desea consultar el uso.  
* @param {string} [ILabor] Código de una o varias labores, las cuales son imputadas en el uso del activo. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function cantidadUsoActivoFiltro(Filtro: string, ListaActivos: string, ILabor: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "cantidadUsoActivoFiltro";
	let params = "";
	try {
		ListaActivos = ISData.getListaActivosDefault(ListaActivos);
		params += "ListaActivos=" + (ListaActivos);
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		ILabor = _ILabor(ILabor);
		params += "&ILabor=" + ILabor;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		if (ListaActivos == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función muestra el costo de un activo en una fecha determina. Tenga en cuenta que el costo del activo es un valor que se registra al momento de la compra o del cargue inicial del activo en la contabilidad. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/costoHistoricoActivo.html
* @param {string} IActivo Código de un activo creado en el catálogo de activos de ContaPyme®. 
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [Fecha] Determina la fecha donde realiza el cálculo.
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function costoHistoricoActivo(IActivo: string, IEmp: string, Fecha: string, BLocal: string): Promise<string | number> {
	let nMethod = "costoHistoricoActivo";
	let params = "";
	try {
		IActivo = _IActivo(IActivo);
		params += "IActivo=" + (IActivo);
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		Fecha = await _FechaFin(Fecha);
		params += "&fecha=" + Fecha;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		if (IActivo == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula la depreciación de un activo a una fecha determina. Tenga en cuenta que la depreciación de un activo es generada cada mes, al ejecutar la operación de acciones automáticas de fin de mes.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/depreciacionActivo.html
* @param {string} IActivo Código de un activo creado en el catálogo de activos de ContaPyme®. 
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [Fecha] Determina la fecha donde realiza el cálculo.
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function depreciacionActivo(IActivo: string, IEmp: string, Fecha: string, BLocal: string): Promise<string | number> {
	let nMethod = "DepreciacionActivo"
	let params = "";
	try {
		IActivo = _IActivo(IActivo);
		params += "IActivo=" + (IActivo);
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		Fecha = await _FechaFin(Fecha);
		params += "&fecha=" + Fecha;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		if (IActivo == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el tiempo en meses que a un activo le falta por depreciar. Tenga en cuenta que la depreciación de un activo es generada cada mes, al ejecutar la operación de acciones automáticas de fin de mes. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/faltaPorDepreciarActivo.html
* @param {string} IActivo Código de un activo creado en el catálogo de activos de ContaPyme®. 
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [Fecha] Determina la fecha donde realiza el cálculo.
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function faltaPorDepreciarActivo(IActivo: string, IEmp: string, Fecha: string, BLocal: string): Promise<string | number> {
	let nMethod = "FaltaPorDepreciarActivo";
	let params = "";
	try {
		IActivo = _IActivo(IActivo);
		params += "IActivo=" + (IActivo);
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		Fecha = await _FechaFin(Fecha);
		params += "&fecha=" + Fecha;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		if (IActivo == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el valor en libros que tiene a hoy un activo. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/valorHoyActivo.html
* @param {string} IActivo Código de un activo creado en el catálogo de activos de ContaPyme®. 
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [Fecha] Determina la fecha donde realiza el cálculo.
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function valorHoyActivo(IActivo: string, IEmp: string, Fecha: string, BLocal: string): Promise<string | number> {
	let nMethod = "ValorHoyActivo";
	let params = "";
	try {
		IActivo = _IActivo(IActivo);
		params += "IActivo=" + (IActivo);
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		Fecha = await _FechaFin(Fecha);
		params += "&fecha=" + Fecha;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		if (IActivo == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el saldo de las cuentas por cobrar que se vencen en un periodo determinado y a la fecha actual no se han cancelado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/carteraPorEdad.html
* @param {string} [ICuenta] Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [ICxX] Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function carteraPorEdad(ICuenta: string, ITercero: string, ICxX: string, FechaIni: string, FechaFin: string, IEmp: string, BLocal: string): Promise<string | number> {
	// Manejo de los datos ingresados
	ICuenta = _ICuenta(ICuenta);
	let nMethod = "CarteraPorEdad";
	let params = "icuenta=" + (ICuenta);
	try {
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		ICxX = ISData.getICxXDefault(ICxX);
		params += "&ICxX=" + ICxX;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el saldo de las cuentas por cobrar que se vencen en un periodo determinado y que a una fecha en específico no se han cancelado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/carteraPorEdadFecha.html
* @param {string} [ICuenta] Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [ICxX] Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [FechaIni] Establece el periodo inicial de tiempo, filtrando por la fecha estimada de pago del documento.
* @param {string} [FechaFin] Establece el periodo final de tiempo, filtrando por la fecha estimada de pago del documento.
* @param {string} [Fecha] Establece el momento hasta donde se calculan los saldos según los abonos realizados a la deuda.Se recomienda utilizar la función fecha para este argumento, así se evitan confusiones entre fechas.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function carteraPorEdadFecha(ICuenta: string, ITercero: string, ICxX: string, FechaIni: string, FechaFin: string, Fecha: string, IEmp: string, BLocal: string): Promise<string | number> {
	let nMethod = "carteraPorEdadFecha";
	// Manejo de los datos ingresados
	ICuenta = _ICuenta(ICuenta);
	let params = "icuenta=" + (ICuenta);
	try {
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		ICxX = ISData.getICxXDefault(ICxX);
		params += "&ICxX=" + ICxX;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		Fecha = await ISData.getFechaDefault(Fecha);
		params += "&fecha=" + Fecha;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el movimiento dédito o crédito de la cartera de un tercero en un periodo de tiempo determinado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/movCarteraTercero.html
* @param {string} [ICuenta] Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [IDebCred] Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function movCarteraTercero(ICuenta: string, ITercero: string, IDebCred: string, FechaIni: string, FechaFin: string, IEmp: string, BLocal: string): Promise<string | number> {
	let nMethod = "MovCarteraTercero";
	// Manejo de los datos ingresados
	ICuenta = _ICuenta(ICuenta);
	let params = "icuenta=" + (ICuenta);
	try {
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		IDebCred = _IDebCred(IDebCred);
		params += "&idebcred=" + IDebCred;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el movimiento dédito o crédito de la cartera de un tercero con un filtro determinado.
* @customfunction 
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/movCarteraTerceroFiltro.html
* @param {string} [Filtro] Filtro que se desea aplicar a la función
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [IDebCred] Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function movCarteraTerceroFiltro(Filtro: string, ITercero: string, IDebCred: string, FechaIni: string, FechaFin: string, IEmp: string, BLocal: string): Promise<string | number> {
	let nMethod = "MovCarteraTerceroFiltro"
	// Manejo de los datos ingresados
	let params = "";
	try {
		ITercero = _ITercero(ITercero);
		params += "itercero=" + ITercero;
		IDebCred = _IDebCred(IDebCred);
		params += "&idebcred=" + IDebCred;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el saldo de la cartera de un  tercero a una fecha en específico. Tenga en cuenta que el saldo es la resta de los créditos a los débitos.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/saldoCarteraTercero.html
* @param {string} [ICuenta] Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [IDebCred] Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function saldoCarteraTercero(ICuenta: string, ITercero: string, IDebCred: string, FechaFin: string, IEmp: string, BLocal: string): Promise<string | number> {
	let nMethod = "saldoCarteraTercero";
	let params = "icuenta=" + (ICuenta);
	try {
		// Manejo de los datos ingresados
		ICuenta = _ICuenta(ICuenta);
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		IDebCred = _IDebCred(IDebCred);
		params += "&idebcred=" + IDebCred;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el saldo de la cartera de un tercero a una fecha en específico con un filtro determinado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/saldoCarteraTerceroFiltro.html
* @param {string} [Filtro] Filtro que se desea aplicar a la función
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [IDebCred] Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function saldoCarteraTerceroFiltro(Filtro: string, ITercero: string, IDebCred: string, FechaFin: string, IEmp: string, BLocal: string): Promise<string | number> {
	let nMethod = "saldoCarteraTerceroFiltro";
	// Manejo de los datos ingresados
	let params = "";
	try {
		ITercero = _ITercero(ITercero);
		params += "itercero=" + ITercero;
		IDebCred = _IDebCred(IDebCred);
		params += "&idebcred=" + IDebCred;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
//#endregion
//#region CENTRO DE COSTOS
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Esta función es utilizada para conocer cualquier atributo de una sede creada en ContaPyme®.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoSede.html
 * @param {string} NAtributo Determina el tipo de atributo que desea obtener
 * @param {string} [IEmp] Código de una empresa creada en el explorador gráfico de empresas de ContaPyme® de la cual se desea conocer sus atributos. 
 * @param {string} [ISede] Código de una sede del explorador gráfico de empresas de ContaPyme® por el cual se desea filtrar. 
 * @param {string} [Fecha] Determina la fecha donde realiza el cálculo.
 */
export async function atributoSede(NAtributo: string, IEmp: string, ISede: string, Fecha: string): Promise<string | number> {
	let nMethod = "AtributoSede";
	let params = "";
	try {
		NAtributo = await ISData.getNAtributoCCDefault(NAtributo);
		params += "natributo=" + (NAtributo);
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ISede = ISData.getISedeDefault(ISede);
		if (ISede !== "") {
			params += "&ISede=" + ISede;
		}
		Fecha = await _FechaFin(Fecha);
		params += "&fecha=" + Fecha;
		if (NAtributo == "" || ISede == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para conocer cualquier atributo de una empresa según el código dado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoEmpresa.html
* @param {string} NAtributo Determina el tipo de atributo que desea obtener
* @param {string} [IEmp] Código de una empresa creada en el explorador gráfico de empresas de ContaPyme® de la cual se desea conocer sus atributos. 
* @param {string} [Fecha] Determina la fecha donde realiza el cálculo.
*/
export async function atributoEmpresa(NAtributo: string, IEmp: string, Fecha: string): Promise<string | number> {
	let nMethod = "AtributoEmpresa";
	let params = "";
	try {
		NAtributo = await ISData.getNAtributoCCDefault(NAtributo);
		params += "natributo=" + (NAtributo);
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		Fecha = await _FechaFin(Fecha);
		params += "&fecha=" + Fecha;
		if (NAtributo == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para conocer cualquier atributo de un centro de costos según el código dado. Tenga en cuenta que los atributos son características dadas a cada centro de costos y se configuran desde el explorador gráfico de empresas de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoCC.html
* @param {string} NAtributo Determina el tipo de atributo que desea obtener
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [Fecha] Determina la fecha donde realiza el cálculo.
*/
export async function atributoCC(NAtributo: string, IEmp: string, ICC: string, Fecha: string): Promise<string | number> {
	let nMethod = "AtributoCC";
	let params = "";
	try {
		NAtributo = await ISData.getNAtributoCCDefault(NAtributo);
		params += "natributo=" + (NAtributo);
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		Fecha = await _FechaFin(Fecha);
		params += "&fecha=" + Fecha;
		if (NAtributo == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para conocer cualquier dato de análisis de suelos de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/NoDisponible.html
* @param {string} NVariable Determina el tipo de atributo que desea obtener
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [Fecha] Determina la fecha donde realiza el cálculo.
*/
export async function DatoAnalisisSuelos(NVariable: string, IEmp: string, ICC: string, Fecha: string): Promise<string | number> {
	let nMethod = "DatoAnalisisSuelos";
	let params = "";
	try {
		NVariable = _IActividad(NVariable);
		params += "NVariable=" + (NVariable);
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		Fecha = await _FechaFin(Fecha);
		params += "&fecha=" + Fecha;
		if (NVariable == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función muestra el área en metros cuadrados de determinada empresa, según una fecha establecida.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadAreaM2CC.html
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [Fecha] Determina la fecha donde realiza el cálculo.
*/
export async function cantidadAreaM2CC(IEmp: string, ICC: string, Fecha: string): Promise<string | number> {
	let nMethod = "cantidadAreaM2CC";
	let params = "";
	try {
		IEmp = await _IEmp(IEmp);
		params += "iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		Fecha = await _FechaFin(Fecha);
		params += "&fecha=" + Fecha;
		if (ICC == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función muestra el área de la unidad que por defecto está configurado el centro de costos de la empresa creada en el explorador gráfico de ContaPyme® a una fecha determinada.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadAreaUnidadCC.html
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [Fecha] Determina la fecha donde realiza el cálculo.
*/
export async function cantidadAreaUnidadCC(IEmp: string, ICC: string, Fecha: string): Promise<string | number> {
	let nMethod = "cantidadAreaUnidadCC"
	let params = "";
	try {
		IEmp = await _IEmp(IEmp);
		params += "iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		Fecha = await _FechaFin(Fecha);
		params += "&fecha=" + Fecha;
		if (ICC == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para conocer el nombre de un centro de costos registrado en el explorador gráfico de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/nombreCC.html
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BNombreLargo] Determina si trae el nombre completo del centro de costos. "T"=Muestra el nombre completo del centro de costos. "F"=Muestra el nombre abreviado del centro de costos. 
*/
export async function nombreCC(IEmp: string, ICC: string, BNombreLargo: string): Promise<string | number> {
	let nMethod = "NombreCC";
	let params = "";
	try {
		IEmp = await _IEmp(IEmp);
		params += "iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC; BNombreLargo = _BLocal(BNombreLargo);
		if (BNombreLargo !== "") params += "&BNombreLargo=" + BNombreLargo;
		else params += "&BNombreLargo=F";
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina cuanta población se encuentra en un centro de costos determinado. Tenga en cuenta que la población de un centro de costos es configurada desde ContaPyme® en el explorador gráfico de empresas.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/poblacionCC.html
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [Fecha] Determina la fecha donde realiza el cálculo.
*/
export async function poblacionCC(IEmp: string, ICC: string, Fecha: string): Promise<string | number> {
	let nMethod = "PoblacionCC";
	let params = "";
	try {
		IEmp = await _IEmp(IEmp);
		params += "iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		Fecha = await _FechaFin(Fecha);
		params += "&fecha=" + Fecha;
		if (ICC == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función muestra la unidad del área de una empresa. Tenga en cuenta que esta unidad es configurable desde el explorador gráfico de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/unidadAreaCC.html
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
*/
export async function unidadAreaCC(IEmp: string): Promise<string | number> {
	let nMethod = "UnidadAreaCC";
	let params = "";
	try {
		IEmp = await _IEmp(IEmp);
		params += "iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función muestra la unidad de la población de un centro de costos. tenga en cuenta que esta unidad la puede configurar desde el explorador gráfico de ContaPyme®
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/unidadPoblacionCC.html
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
*/
export async function unidadPoblacionCC(IEmp: string, ICC: string): Promise<string | number> {
	let nMethod = "UnidadPoblacionCC"
	let params = "";
	try {
		IEmp = await _IEmp(IEmp);
		params += "iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		if (ICC == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
//#endregion
//#region CONTABILIDAD
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Esta función calcula la cantidad de movimientos de las cuentas marcadas "es de presupuesto" con un filtro determinado
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/contarAsientosEjecPptalFiltro.html
 * @param {string} [Filtro] Filtro que se desea aplicar a la función
 * @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
 * @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
 * @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
 * @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
 * @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
 */
export async function contarAsientosEjecPptalFiltro(Filtro: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "ContarAsientosEjecPptalFiltro";
	let params = "";
	try {
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") {
			params += "filtro=" + Filtro;
		}
		FechaIni = _FechaIni(FechaIni);
		if (params == "") {
			params += "fechaini=" + FechaIni;
		} else {
			params += "&fechaini=" + FechaIni;
		}
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula los movimientos de las cuentas marcadas "es de presupuesto" entre un periodo de tiempo determinado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/movEjecPptal.html
* @param {string} [ICuenta] Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function movEjecPptal(ICuenta: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "MovEjecPptal";
	let params = "";
	try {
		ICuenta = _ICuenta(ICuenta);
		if (ICuenta !== "") {
			params += "icuenta=" + (ICuenta);
		}
		FechaIni = _FechaIni(FechaIni);
		if (params == "") {
			params += "fechaini=" + FechaIni;
		} else {
			params += "&fechaini=" + FechaIni;
		}
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula los movimientos de las cuentas marcadas "es de presupuesto" con un filtro determinado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/movEjecPptalFiltro.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function movEjecPptalFiltro(ICuenta: string, Filtro: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "MovEjecPptalFiltro";
	let params = "";
	try {
		ICuenta = _ICuenta(ICuenta);
		if (ICuenta !== "") {
			params += "icuenta=" + (ICuenta);
		}
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") {
			if (params == "") {
				params += "filtro=" + Filtro;
			} else {
				params += "&filtro=" + Filtro;
			}
		}
		FechaIni = _FechaIni(FechaIni);
		if (params == "") {
			params += "fechaini=" + FechaIni;
		} else {
			params += "&fechaini=" + FechaIni;
		}
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula los saldos de las cuentas marcadas "es de presupuesto" entre un periodo de tiempo determinado.. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/saldoEjecPptal.html
* @param {string} [ICuenta] Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function saldoEjecPptal(ICuenta: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "saldoEjecPptal";
	let params = "";
	try {
		ICuenta = _ICuenta(ICuenta);
		if (ICuenta !== "") {
			params += "icuenta=" + (ICuenta);
		}
		FechaFin = await _FechaFin(FechaFin);
		if (params == "") {
			params += "fechafin=" + FechaFin;
		} else {
			params += "&fechafin=" + FechaFin;
		}
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el saldo de una o más cuentas con un filtro determinado
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/saldoEjecPptalFiltro.html
* @param {string} [ICuenta] Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function saldoEjecPptalFiltro(ICuenta: string, Filtro: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "saldoEjecPptalFiltro";
	let params = "";
	try {
		ICuenta = _ICuenta(ICuenta);
		if (ICuenta !== "") {
			params += "icuenta=" + (ICuenta);
		}
		FechaFin = await _FechaFin(FechaFin);
		if (params == "") {
			params += "fechafin=" + FechaFin;
		} else {
			params += "&fechafin=" + FechaFin;
		}
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para conocer cualquier atributo de una cuenta según el código dado. Tenga en cuenta que los atributos son características dadas a cada cuenta y se configuran desde el PUC de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoCuenta.html
* @param {string} NAtributo Determina el tipo de atributo que desea obtener
* @param {string} ICuenta Código de una cuenta del plan de cuentas de ContaPyme®. 
*/
export async function atributoCuenta(NAtributo: string, ICuenta: string): Promise<string | number> {
	let nMethod = "AtributoCuenta";
	let params = "";
	try {
		ICuenta = _ICuenta(ICuenta);
		params += "icuenta=" + (ICuenta);
		NAtributo = ISData.getNAtributoContableDefault(NAtributo);
		params += "&natributo=" + (NAtributo);
		if (ICuenta == "" || NAtributo == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el número de asientos contables con un filtro determinado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/contarAsientosContablesFiltro.html
* @param {string} [Filtro] Filtro que se desea aplicar a la función
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function contarAsientosContablesFiltro(Filtro: string, FechaIni: string, FechaFin: string, BLocal: string): Promise<string | number> {
	let nMethod = "ContarAsientosContablesFiltro";
	let params = "";
	try {
		FechaIni = _FechaIni(FechaIni);
		params += "fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para contar las cuentas según un filtro determinado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/contarCuentasFiltro.html
* @param {string} [ICuenta] Código de una cuenta del plan de cuentas de ContaPyme®. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función
*/
export async function contarCuentasFiltro(ICuenta: string, Filtro: string): Promise<string | number> {
	let nMethod = "ContarCuentasFiltro";
	let params = "";
	try {
		ICuenta = _ICuenta(ICuenta);
		if (ICuenta !== "") {
			params += "icuenta=" + (ICuenta);
		}
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") {
			if (params == "") {
				params += "filtro=" + Filtro;
			} else {
				params += "&filtro=" + Filtro;
			}
		}
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Dada la identificación alterna de una cuenta, la función muestra el código de la cuenta del PUC de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/idCuenta.html
* @param {string} IdCuentaAlterna Identificación alterna de una cuenta, configurada desde el PUC de ContaPyme®. 
*/
export async function idCuenta(IdCuentaAlterna: string): Promise<string | number> {
	let nMethod = "IdCuenta";
	let params = "";
	try {
		if (IdCuentaAlterna == "") throw ErrorParam
		params += "idcuentaalterna=" + (IdCuentaAlterna);
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para conocer la identificación alterna de una cuenta. Tenga en cuenta que la identificación alterna es configurable para cada cuenta desde el PUC de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/idCuentaAlterna.html
* @param {string} ICuenta Código de una cuenta del plan de cuentas de ContaPyme®. 
*/
export async function idCuentaAlterna(ICuenta: string): Promise<string | number> {
	let nMethod = "IdCuentaAlterna";
	let params = "";
	try {
		ICuenta = _ICuenta(ICuenta);
		if (ICuenta == "") throw ErrorParam
		params += "icuenta=" + (ICuenta);
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para conocer cuál es el Id del tipo de una cuenta determinada. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/idTipoCuenta.html
* @param {string} ICuenta Código de una cuenta del plan de cuentas de ContaPyme®. 
*/
export async function idTipoCuenta(ICuenta: string): Promise<string | number> {
	let nMethod = "IdTipoCuenta";
	let params = "";
	try {
		ICuenta = _ICuenta(ICuenta);
		if (ICuenta == "") throw ErrorParam
		params += "icuenta=" + (ICuenta);
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Dado el código de la cuenta, la función determina si la cuenta maneja valor base. Tenga en cuenta que el valor base es el valor sobre el cual se calcularán los impuestos. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/manejaValorBase.html
* @param {string} ICuenta Código de una cuenta del plan de cuentas de ContaPyme®. 
*/
export async function manejaValorBase(ICuenta: string): Promise<string | number> {
	let nMethod = "ManejaValorBase";
	let params = "";
	try {
		ICuenta = _ICuenta(ICuenta);
		if (ICuenta == "") throw ErrorParam
		params += "icuenta=" + (ICuenta);
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para conocer la naturaleza de una cuenta, débito o crédito, según el código dado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/naturalezaCuenta.html
* @param {string} ICuenta Código de una cuenta del plan de cuentas de ContaPyme®. 
*/
export async function naturalezaCuenta(ICuenta: string): Promise<string | number> {
	let nMethod = "NaturalezaCuenta";
	let params = "";
	try {
		ICuenta = _ICuenta(ICuenta);
		if (ICuenta == "") throw ErrorParam
		params += "icuenta=" + (ICuenta);
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para conocer la naturaleza de una cuenta, débito o crédito, según el código dado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/nombreCuenta.html
* @param {string} ICuenta Código de una cuenta del plan de cuentas de ContaPyme®. 
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function nombreCuenta(ICuenta: string, BLocal: string): Promise<string | number> {
	let nMethod = "NombreCuenta";
	let params = "";
	try {
		ICuenta = _ICuenta(ICuenta);
		BLocal = _BLocal(BLocal);
		if (ICuenta == "") throw ErrorParam
		params += "icuenta=" + (ICuenta);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Dada la identificación alterna de una cuenta, la función muestra el nombre de la cuenta del PUC de ContaPyme® en contabilización local o NIIF. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/nombreCuentaAlterna.html
* @param {string} ICuenta Código de una cuenta del plan de cuentas de ContaPyme®. 
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function nombreCuentaAlterna(ICuenta: string, BLocal: string) {
	let nMethod = "NombreCuentaAlterna";
	let params = "";
	try {
		ICuenta = _ICuenta(ICuenta);
		BLocal = _BLocal(BLocal);
		if (ICuenta == "") throw ErrorParam
		params += "icuenta=" + (ICuenta);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para conocer a que tipo de cuenta se encuentra asociada una determinada cuenta, según el código dado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/nombreTipoCuenta.html
* @param {string} ICuenta Código de una cuenta del plan de cuentas de ContaPyme®. 
*/
export async function nombreTipoCuenta(ICuenta: string): Promise<string | number> {
	let nMethod = "NombreTipoCuenta";
	let params = "";
	try {
		ICuenta = _ICuenta(ICuenta);
		if (ICuenta == "") throw ErrorParam
		params += "icuenta=" + (ICuenta);
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula los movimientos contables débitos y/o créditos de una o varias cuentas con un filtro determinado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/movContabilidadDCFiltro.html
* @param {string} [Filtro] Filtro que se desea aplicar a la función
* @param {string} [IDebCred] Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function movContabilidadDCFiltro(Filtro: string, IDebCred: string, FechaIni: string, FechaFin: string, BLocal: string): Promise<string | number> {
	let nMethod = "MovContabilidadDCFiltro";
	let params = "";
	try {			// Manejo de los datos ingresados
		IDebCred = _IDebCred(IDebCred);
		params += "idebcred=" + IDebCred;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula los movimientos contables de una o varias cuen tas con un filtro determinado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/movContabilidadFiltro.html
* @param {string} [Filtro] Filtro que se desea aplicar a la función
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function movContabilidadFiltro(Filtro: string, FechaIni: string, FechaFin: string, BLocal: string): Promise<string | number> {
	let nMethod = "MovContabilidadFiltro";
	let params = "";
	try {			// Manejo de los datos ingresados
		FechaIni = _FechaIni(FechaIni);
		params += "fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el movimiento contable de una cuenta entre un periodo de tiempo determinado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/movCuenta.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function movCuenta(ICuenta: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "movCuenta";
	ICuenta = _ICuenta(ICuenta);
	// Manejo de los datos ingresados
	let params = "icuenta=" + (ICuenta);
	try {
		if (ICuenta == "") throw ErrorParam
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula los movimiento débitos y/o créditos de una o varias cuentas, entre un periodo de tiempo determinado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/movCuentaDC.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [IDebCred] Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function movCuentaDC(ICuenta: string, IDebCred: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "MovCuentaDC";
	ICuenta = _ICuenta(ICuenta);
	// Manejo de los datos ingresados
	let params = "icuenta=" + (ICuenta);
	try {
		if (ICuenta == "") throw ErrorParam
		IDebCred = _IDebCred(IDebCred);
		params += "&idebcred=" + IDebCred;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula los movimientos débitos o créditos de una o más cuentas con un filtro determinado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/movCuentaDCFiltro.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [IDebCred] Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function movCuentaDCFiltro(ICuenta: string, IDebCred: string, Filtro: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "MovCuentaDCFiltro";
	ICuenta = _ICuenta(ICuenta);
	// Manejo de los datos ingresados
	let params = "icuenta=" + (ICuenta);
	try {
		if (ICuenta == "") throw ErrorParam
		IDebCred = _IDebCred(IDebCred);
		params += "&idebcred=" + IDebCred;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el saldo de los movimientos de una o más cuentas con un filtro determinado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/movCuentaFiltro.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function movCuentaFiltro(ICuenta: string, Filtro: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "MovCuentaFiltro";
	// Manejo de los datos ingresados
	let params = "icuenta=" + (ICuenta);
	try {
		ICuenta = _ICuenta(ICuenta);
		if (ICuenta == "") throw ErrorParam
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula los movimientos contable realizados en moneda extrajera entre un periodo de tiempo determinado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/movCuentaMonedaExtranjera.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function movCuentaMonedaExtranjera(ICuenta: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "MovCuentaMonedaExtranjera";
	ICuenta = _ICuenta(ICuenta);
	// Manejo de los datos ingresados
	let params = "icuenta=" + (ICuenta);
	try {
		if (ICuenta == "") throw ErrorParam
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&ICC=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el saldo de los movimientos de una o más cuentas con un filtro determinado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/movCuentaMonedaExtranjeraFiltro.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function movCuentaMonedaExtranjeraFiltro(ICuenta: string, Filtro: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "MovCuentaMonedaExtranjeraFiltro";
	ICuenta = _ICuenta(ICuenta);
	// Manejo de los datos ingresados
	let params = "icuenta=" + (ICuenta);
	try {
		if (ICuenta == "") throw ErrorParam
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&ICC=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula los movimientos de una cuenta en un periodo de tiempo y que se le han imputado a un tercero determinado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/movCuentaTercero.html
* @param {string} [ICuenta] Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [IDebCred] Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function movCuentaTercero(ICuenta: string, ITercero: string, IDebCred: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "MovCuentaTercero";
	// Manejo de los datos ingresados
	ICuenta = _ICuenta(ICuenta);
	let params = "icuenta=" + (ICuenta);
	try {
		ITercero = _ITercero(ITercero);
		if (ITercero == "")
			throw new Error("El parámetro ingresado es incorrecto, la función requiere el parámetro ITERCERO que se ha brindado como un valor vacío.");
		params += "&itercero=" + ITercero;
		IDebCred = _IDebCred(IDebCred);
		params += "&idebcred=" + IDebCred;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&ICC=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función suma los valores de la columna valor 1 de todas las operaciones de movimiento contable entre un periodo de tiempo determinado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/movCuentaValor1.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function movCuentaValor1(ICuenta: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "MovCuentaValor1";
	ICuenta = _ICuenta(ICuenta);
	// Manejo de los datos ingresados
	let params = "icuenta=" + (ICuenta);
	try {
		if (ICuenta == "") throw ErrorParam
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&ICC=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función suma los valores de la columna valor 2 de todas las operaciones de movimiento contable entre un periodo de tiempo determinado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/movCuentaValor2.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function movCuentaValor2(ICuenta: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "MovCuentaValor2";
	ICuenta = _ICuenta(ICuenta);
	// Manejo de los datos ingresados
	let params = "icuenta=" + (ICuenta);
	try {
		if (ICuenta == "") throw ErrorParam
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&ICC=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función suma los valores de la columna valor 1 de todas las operaciones de movimiento contable teniendo en cuenta un filtro determinado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/movCuentaValor1Filtro.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function movCuentaValor1Filtro(ICuenta: string, Filtro: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "MovCuentaValor1Filtro";
	ICuenta = _ICuenta(ICuenta);
	// Manejo de los datos ingresados
	let params = "icuenta=" + (ICuenta);
	try {
		if (ICuenta == "") throw ErrorParam
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") {
			params += "&icc=" + ICC;
		}
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función suma los valores de la columna valor 2 de todas las operaciones de movimiento contable teniendo en cuenta un filtro determinado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/movCuentaValor2Filtro.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function movCuentaValor2Filtro(ICuenta: string, Filtro: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "MovCuentaValor2Filtro";
	ICuenta = _ICuenta(ICuenta);
	// Manejo de los datos ingresados
	let params = "icuenta=" + (ICuenta);
	try {
		if (ICuenta == "") throw ErrorParam
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") {
			params += "&icc=" + ICC;
		}
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula los movimientos de una cuenta en un periodo de tiempo y que se le han imputado a un tercero determinado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/movCuentaValorBase.html
* @param {string} [ICuenta] Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function movCuentaValorBase(ICuenta: string, ITercero: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "MovCuentaValorBase";
	// Manejo de los datos ingresados
	ICuenta = _ICuenta(ICuenta);
	let params = "icuenta=" + (ICuenta);
	try {
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		if (ITercero == "" && ICuenta == "") throw ErrorParam
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&ICC=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula los movimientos de una cuenta aplicando un filtro determinado, en un periodo de tiempo y que se le han imputado a un tercero determinado. 
* @customfunction
* @param {string} [ICuenta] Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function movCuentaValorBaseFiltro(ICuenta: string, Filtro: string, ITercero: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "MovCuentaValorBaseFiltro";
	// Manejo de los datos ingresados
	ICuenta = _ICuenta(ICuenta);
	let params = "icuenta=" + (ICuenta);
	try {
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		if (ITercero == "" && ICuenta == "") throw ErrorParam
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&ICC=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
        Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el presupuesto de las cuentas a una fecha en específico. Este presupuesto se configurable por cuenta y es cargado desde el catálogo de presupuestos de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/presupuesto.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
*/
export async function presupuesto(ICuenta: string, FechaFin: string, IEmp: string, ICC: string): Promise<string | number> {
	let nMethod = "Presupuesto";
	ICuenta = _ICuenta(ICuenta);
	// Manejo de datos ingresados
	let params = "icuenta=" + (ICuenta);
	try {
		if (ICuenta == "") throw ErrorParam
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&ICC=" + ICC;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el presupuesto de una cuenta aplicando un filtro determinado. Tenga en cuenta que este presupuesto es cargado desde el catálogo de presupuesto de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/presupuestoFiltro.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
*/
export async function presupuestoFiltro(ICuenta: string, Filtro: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string): Promise<string | number> {
	let nMethod = "PresupuestoFiltro";
	let params = "";
	try {
		ICuenta = _ICuenta(ICuenta);
		if (ICuenta !== "") {
			params += "icuenta=" + (ICuenta);
		}
		FechaIni = _FechaIni(FechaIni);
		if (params == "") {
			params += "fechaini=" + FechaIni;
		} else {
			params += "&fechaini=" + FechaIni;
		}
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el presupuesto de las cuentas en un periodo de tiempo determinado. Este presupuesto se configurable por cada cuenta y es cargado desde el catálogo de presupuestos de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/presupuestoPeriodo.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
*/
export async function presupuestoPeriodo(ICuenta: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string): Promise<string | number> {
	let nMethod = "PresupuestoPeriodo";
	ICuenta = _ICuenta(ICuenta);
	// Manejo de los datos ingresados
	let params = "icuenta=" + (ICuenta);
	try {
		if (ICuenta == "") throw ErrorParam
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&ICC=" + ICC;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el saldo de las operaciones contables con un filtro determinado. Tenga en cuenta que el saldo de una operación se calcula restando los créditos de los débitos.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/saldoContabilidadFiltro.html
* @param {string} [Filtro] Filtro que se desea aplicar a la función
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function saldoContabilidadFiltro(Filtro: string, FechaFin: string, BLocal: string): Promise<string | number> {
	let nMethod = "saldoContabilidadFiltro";
	let params = "";
	try {
		FechaFin = await _FechaFin(FechaFin);
		params += "fechafin=" + FechaFin;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el saldo de una o más cuentas a una fecha en específico
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/saldoCuenta.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function saldoCuenta(ICuenta: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "saldocuenta";
	let params = "";
	try {
		// Manejo de datos ingresados
		ICuenta = _ICuenta(ICuenta);
		if (!isBool(ICuenta)) throw ErrorParam
		params += "icuenta=" + (ICuenta);
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") {
			params += "&icc=" + ICC;
		}
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	}
	catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el saldo de una o más cuentas con un filtro determinado
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/saldoCuentaFiltro.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function saldoCuentaFiltro(ICuenta: string, Filtro: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "saldoCuentaFiltro";
	ICuenta = _ICuenta(ICuenta);
	// Manejo de los datos ingresados
	let params = "icuenta=" + (ICuenta);
	try {
		if (ICuenta == "") throw ErrorParam
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&ICC=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el saldo en moneda extranjera de una cuenta o más cuentas a una fecha determinada.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/saldoCuentaMonedaExtranjera.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function saldoCuentaMonedaExtranjera(ICuenta: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "saldoCuentaMonedaExtranjera";
	ICuenta = _ICuenta(ICuenta);
	// Manejo de datos ingresados
	let params = "icuenta=" + (ICuenta);
	try {
		if (ICuenta == "") throw ErrorParam
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&ICC=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el saldo en moneda extranjera de una cuenta o más cuentas a una fecha y con un filtro determinado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/saldoCuentaMonedaExtranjeraFiltro.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función  
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function saldoCuentaMonedaExtranjeraFiltro(ICuenta: string, Filtro: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "saldoCuentaMonedaExtranjeraFiltro";
	ICuenta = _ICuenta(ICuenta);
	// Manejo de los datos ingresados
	let params = "icuenta=" + (ICuenta);
	try {
		if (ICuenta == "") throw ErrorParam
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&ICC=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función suma los valores de la columna valor 1 en todas las operaciones de movimiento contable a una fecha determinada.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/saldoCuentaValor1.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function saldoCuentaValor1(ICuenta: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "saldoCuentaValor1";
	ICuenta = _ICuenta(ICuenta);
	// Manejo de datos ingresados
	let params = "icuenta=" + (ICuenta);
	try {
		if (ICuenta == "") throw ErrorParam
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&ICC=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función suma los valores de la columna valor 2 en todas las operaciones de movimiento contable a una fecha determinada.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/saldoCuentaValor2.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function saldoCuentaValor2(ICuenta: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "saldoCuentaValor2";
	ICuenta = _ICuenta(ICuenta);
	// Manejo de datos ingresados
	let params = "icuenta=" + (ICuenta);
	try {
		if (ICuenta == "") throw ErrorParam
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&ICC=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función suma los valores de la columna valor 1 en todas las operaciones de movimiento contable con un filtro determinado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/saldoCuentaValor1Filtro.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función  
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function saldoCuentaValor1Filtro(ICuenta: string, Filtro: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "saldoCuentaValor1Filtro";
	ICuenta = _ICuenta(ICuenta);
	// Manejo de los datos ingresados
	let params = "icuenta=" + (ICuenta);
	try {
		if (ICuenta == "") throw ErrorParam
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&ICC=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función suma los valores de la columna valor 2 en todas las operaciones de movimiento contable con un filtro determinado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/saldoCuentaValor2Filtro.html
* @param {string} ICuenta Código de una o más cuentas del plan de cuentas de ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function saldoCuentaValor2Filtro(ICuenta: string, Filtro: string, FechaFin: string, IEmp: string, ICC: string, BLocal: string): Promise<string | number> {
	let nMethod = "saldoCuentaValor2Filtro";
	ICuenta = _ICuenta(ICuenta);
	// Manejo de los datos ingresados
	let params = "icuenta=" + (ICuenta);
	try {
		if (ICuenta == "") throw ErrorParam
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&ICC=" + ICC;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para mostrar la tasa de cambio de una moneda en específico. Tenga en cuenta que la tasa de cambio es registrada desde la operación "Registro de tasas de cambio". 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/tasaCambio.html
* @param {string} IMoneda Código de la moneda que se requiere. 
* @param {string} [Fecha] Establece el momento en que se desea consultar el atributo determinado. Se recomienda utilizar la función fecha para este argumento, así se evitan confusiones entre fechas. Ej. FECHA(2017;12;31) 
* @param {string} [BTasaCompra] Determina el valor de la tasa de cambio para la compra o venta de la moneda.
* @param {string} [IEmp] Código de una empresa creada en el explorador gráfico de empresas de ContaPyme® por la cual se desea filtrar. 
*/
export async function tasaCambio(IMoneda: string, Fecha: string, BTasaCompra: string, IEmp: string): Promise<string | number> {
	let nMethod = "TasaCambio";
	IMoneda = _ICuenta(IMoneda);
	// Manejo de datos ingresados
	let params = "imoneda=" + (IMoneda);
	try {
		if (IMoneda == "") throw ErrorParam
		Fecha = await _FechaFin(Fecha);
		params += "&fecha=" + Fecha;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		BTasaCompra = _BLocal(BTasaCompra);
		if (BTasaCompra !== "") params += "&BTasaCompra=" + BTasaCompra;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
//#endregion
//#region ENTORNO
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Esta función es utilizada para conocer el código de la empresa activa en el momento. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/empresaTrabajoActiva.html
 */
export async function empresaTrabajoActiva(): Promise<string | number> {
	let nMethod = "EmpresaTrabajoActiva";
	try {
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, 'null');
	} catch (error) {
		saveError(nMethod, "", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para conocer el código de la empresa activa en el momento. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/NoDisponible.html
* @param {number} IAgente Código de la empresa de trabajo a utilizar para la petición. 
*/
export async function empresaTrabajoActivaMulti(): Promise<string | number> {
	let nMethod: string = "EmpresaTrabajoActiva";
	try {
		//return "Antes del llamado " + IAgente;
		//let datos: any = consultarDatoTrabajoIndice(IAgente);
		//return JSON.stringify(datos);
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, 'null');
	} catch (error) {
		saveError(nMethod, "", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para conocer la fecha de trabajo configurada en el momento. Esta fecha se encuentra en el botón "Datos de trabajo" en la pestaña ContaPyme. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/fechaTrabajo.html
*/
export async function fechaTrabajo(): Promise<string | number> {
	let nMethod = "FechaTrabajo";
	try {
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, 'null');
	} catch (error) {
		saveError(nMethod, "", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para conocer el nombre del área de trabajo activa en el momento. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/nombreAreaTrabajoActiva.html
*/
export async function nombreAreaTrabajoActiva(): Promise<string | number> {
	let nMethod = "NombreAreaTrabajoActiva";
	try {
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, 'null');
	} catch (error) {
		saveError(nMethod, "", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para conocer el nombre de una empresa registrada en el explorador gráfico de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/nombreEmpresa.html
* @param {string} [IEmp] Código de la empresa del cual se desea conocer el nombre, basado en el explorador gráfico de empresas de ContaPyme®. 
* @param {string} [BNombreLargo] Determina si trae el nombre completo de la empresa. "T"=Muestra el nombre completo de la empresa, "F"=Muestra el nombre abreviado de la empresa 
*/
export async function nombreEmpresa(IEmp: string, BNombreLargo: string): Promise<string | number> {
	let nMethod = "NombreEmpresa";
	let params = "";
	try {
		IEmp = await _IEmp(IEmp);
		params += "iemp=" + IEmp;
		BNombreLargo = _BLocal(BNombreLargo);
		if (BNombreLargo !== "") params += "&BNombreLargo=" + BNombreLargo;
		else params += "&BNombreLargo=F";
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para conocer el nombre completo de la empresa activa en el momento. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/nombreEmpresaTrabajoActiva.html
*/
export async function nombreEmpresaTrabajoActiva(): Promise<string | number> {
	let nMethod = "NombreEmpresaTrabajoActiva";
	try {
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, 'null');
	} catch (error) {
		saveError(nMethod, "", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Dado el código de un documento de soporte registrado en ContaPyme®, la función muestra el nombre del documento de soporte. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/nombreTipoDocumento.html
* @param {string} ITipoDocumento Código de un documento de soporte registrado en ContaPyme®. 
*/
export async function nombreTipoDocumento(ITipoDocumento: string): Promise<string | number> {
	let nMethod = "NombreTipoDocumento";
	let params = "";
	try {
		if (ITipoDocumento === null || ITipoDocumento == "" || !isNumeric(ITipoDocumento)) throw ErrorParam
		params += "ITipoDocumento=" + ITipoDocumento;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
//#endregion
///////////////////////////////////INICIO METODOS PROPIOS EXCEL Y FILTROS  ///////////////////////////////////////////
//#region FECHA GENERAL
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Esta función muestra la diferencia entre dos fechas indicadas. " FechaFin - FechaIni " 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/edad.html
 * @param {string} FechaIni Establecen el periodo inicial de tiempo que se va a calcular.
 * @param {string} FechaFin Establecen el periodo final de tiempo que se va a calcular.
 * @param {string} [BIncluyeTexto] Determina si el nombre del mes es mostrado completo o abreviado.
 */
export async function edad(FechaIni: string, FechaFin: string, BIncluyeTexto: string) {
	try {
		BIncluyeTexto = isBool(BIncluyeTexto) ? upperCase(BIncluyeTexto) : "F";
		if (bIsText(FechaIni) || bIsText(FechaFin) || (BIncluyeTexto != "F" && BIncluyeTexto != "T" && BIncluyeTexto != '"T"' && BIncluyeTexto != '"F"'))
			throw new Error("Se han ingresado valores en el formato incorrecto, por favor revise el formato. Se recomienda utilizar la función fecha para este argumento, así se evitarán confusiones entre fechas.Ej. FECHA(2017;12;31) ");
		FechaIni = await _FechaFin(FechaIni);
		FechaFin = await _FechaFin(FechaFin);
		let fechaInicio = val2Date(FechaIni).getTime();
		let fechaFin = val2Date(FechaFin).getTime();
		let diferencia = Math.abs(fechaFin - fechaInicio);
		let diferenciaDias = Math.floor((diferencia / (1000 * 60 * 60 * 24)));
		let diferenciaAños = Math.floor(diferenciaDias / 365);
		let diferenciaAñosCalculo = diferenciaDias / 365;
		let diferenciaMeses = Math.floor(diferenciaAñosCalculo % 1 * 12);
		if (BIncluyeTexto == "F") {
			if (diferenciaAños < 1) {
				if (diferenciaMeses < 1) {
					return diferenciaDias;
				} else {
					return diferenciaMeses;
				}
			} else {
				return diferenciaAños;
			}
		} else {
			if (diferenciaAños < 1) {
				if (diferenciaMeses < 1) {
					if (diferenciaDias == 1) {
						return diferenciaDias + " dia";
					} else {
						return diferenciaDias + " dias";
					}
				} else {
					if (diferenciaMeses == 1) {
						return diferenciaMeses + " mes";
					} else {
						return diferenciaMeses + " meses";
					}
				}
			} else {
				let res: string;
				if (diferenciaAños == 1) {
					res = " año";
				} else {
					res = " años";
				}
				if (diferenciaMeses < 1) {
					return diferenciaAños + res;
				} else {
					if (diferenciaMeses == 1) {
						return diferenciaAños + res + " y " + diferenciaMeses + " mes";
					} else {
						return diferenciaAños + res + " y " + diferenciaMeses + " meses";
					}
				}
			}
		}
	} catch (error) {
		saveError("EDAD", FechaIni + "," + FechaFin + "," + BIncluyeTexto, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Dando el número de la semana, la función determina la fecha en que inicia tal semana. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/fechaInicioSemana.html
* @param {number} Semana Número de la semana con la cual la función realizará el cálculo. 
* @param {number} Año Año con el cual la función realizará el cálculo. 
*/
export async function fechaInicioSemana(Semana: number, Año: number) {
	try {
		if (Año == null || Año == 0)
			Año = val2Date(await _FechaFin("")).getFullYear();
		if (Año > 1) {
			//Se calcula la fecha en la cual inicia una semana, contando como fecha inicio el dia lunes de cada semana
			let simple = new Date(Date.UTC(Año, 0, 1 + (Semana - 1) * 7));
			let dow = simple.getDay();
			let ISOweekStart = simple;
			if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
			else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
			return ISOweekStart.toLocaleDateString()
		} else throw new Error("Se han ingresado valores en el formato incorrecto o valores de año negativos, por favor revise el formato.");
	} catch (error) {
		saveError("FECHAINICIOSEMANA", Semana + "," + Año, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Indicando una fecha, la función muestra la fecha del día lunes, basándose en la misma semana de la fecha indicada. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/fechaLunes.html
* @param {string} Fecha Código del mes que mostrará la función. 
*/
export async function fechaLunes(Fecha: string) {
	try {
		if (bIsText(Fecha)) throw ErrorDate;
		let fecha = await _FechaFin(Fecha);
		let fechaReferencia = new Date(fecha);
		let mes = fechaReferencia.getMonth() + 1;
		let año = fechaReferencia.getFullYear();
		let dia = 0;
		if (fechaReferencia.getDay() == 0)
			dia = fechaReferencia.getDate() - 6;
		else dia = fechaReferencia.getDate() - fechaReferencia.getDay() + 1;
		let returnDate = val2Date(mes + "/" + dia + "/" + año).toLocaleDateString();
		return returnDate;
	} catch (error) {
		saveError("FECHALUNES", Fecha, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Dada una fecha, la función determina el número de la semana del año al que pertenece. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/nombreMes.html
* @param {number} IMes Código del mes que mostrará la función. 
* @param {string} [BNombreLargo] Determina si el nombre del mes es mostrado completo o abreviado.
*/
export function nombreMes(IMes: number, BNombreLargo: string) {
	try {
		BNombreLargo = isBool(BNombreLargo) ? upperCase(BNombreLargo) : "F";
		if ((BNombreLargo != "F" && BNombreLargo != "T" && BNombreLargo != '"F"' && BNombreLargo !== '"T"') || !isNumeric(IMes) || IMes > 12 || IMes < 1)
			throw new Error("Se han ingresado valores en el formato incorrecto, por favor verifique las ayudas.")
		let mesNombreCorto = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
		let mesNombreLargo = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
		if (BNombreLargo == "F") return mesNombreCorto[IMes - 1];
		else return mesNombreLargo[IMes - 1];
	} catch (error) {
		saveError("NOMBREMES", IMes + "," + BNombreLargo, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Dada una fecha, la función determina el número de la semana del año al que pertenece. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/numeroSemana.html
* @param {string} Fecha Establece la fecha donde se empezará a realizar el cálculo.
* @param {number} [AñoReferencia] Año donde iniciará el conteo de las semanas. 
*/
export async function numeroSemana(Fecha: string, AñoReferencia: number) {
	try {
		let fecha = await _FechaFin(Fecha);
		if (AñoReferencia == null || AñoReferencia == 0) {
			let año = new Date(await _FechaFin(Fecha));
			AñoReferencia = año.getFullYear();
		}
		if (bIsText(Fecha) || !isNumeric(AñoReferencia) || AñoReferencia < 1) throw ErrorDate
		let añoInicio: any;
		let fechaReferencia: any = new Date(fecha);
		let dayNum = fechaReferencia.getUTCDay() || 7;
		fechaReferencia.setUTCDate(fechaReferencia.getUTCDate() + 4 - dayNum);
		if (AñoReferencia !== 0) añoInicio = new Date(Date.UTC(AñoReferencia, 0, 1));
		else añoInicio = new Date(Date.UTC(fechaReferencia.getFullYear(), 0, 1));
		let numeroSemana = (Math.ceil((((fechaReferencia - añoInicio) / 86400000) + 1) / 7));
		return numeroSemana;
	} catch (error) {
		saveError("NUMEROSEMANA", Fecha + "," + AñoReferencia, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina la fecha en que inicia un mes, basándose en una fecha de referencia. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/primerDiaMes.html
* @param {string} Fecha Establece la fecha donde se empezará a realizar el cálculo.
* @param {number} [NumMeses] Número de meses que se le quiere sumar o restar al argumento Fecha. 
* @param {number} [NumAños] Número de años que se le quiere sumar o restar al argumento Fecha. 
*/
export async function primerDiaMes(Fecha: string, NumMeses: number, NumAños: number) {
	try {
		Fecha = await _FechaFin(Fecha)
		if (!isBool(NumMeses)) NumMeses = 0;
		if (!isBool(NumAños)) NumAños = 0;
		if (bIsText(Fecha) || !isNumeric(NumMeses) || !isNumeric(NumAños)) throw ErrorDate
		let fecha: Date = val2Date(Fecha);
		fecha.setDate(1)
		fecha.setFullYear(fecha.getFullYear() + NumAños)
		fecha.setMonth(fecha.getMonth() + NumMeses)
		return fecha.toLocaleDateString()
	} catch (error) {
		//Manejo y registro de errores
		saveError("PRIMERDIAMES", Fecha + "," + NumMeses + "," + NumAños, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina la fecha en que termina un mes, basándose en una fecha de referencia. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/ultimoDiaMes.html
* @param {string} Fecha Establece la fecha donde se empezará a realizar el cálculo.
* @param {number} [NumMeses] Número de meses que se le quiere sumar o restar al argumento Fecha. 
* @param {number} [NumAños] Número de años que se le quiere sumar o restar al argumento Fecha. 
*/
export async function ultimoDiaMes(Fecha: string, NumMeses: number, NumAños: number) {
	try {
		Fecha = await _FechaFin(Fecha)
		if (!isBool(NumMeses)) NumMeses = 0;
		if (!isBool(NumAños)) NumAños = 0;
		if (bIsText(Fecha) || !isNumeric(NumMeses) || !isNumeric(NumAños)) throw ErrorDate
		let fecha: Date = val2Date(Fecha);
		fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
		fecha.setFullYear(fecha.getFullYear() + NumAños)
		fecha.setMonth(fecha.getMonth() + 1 + NumMeses)
		fecha.setDate(0)
		return fecha.toLocaleDateString()
	} catch (error) {
		saveError("ULTIMODIAMES", Fecha + "," + NumMeses + "," + NumAños, error as Error);
		return E1;
	}
}
//#endregion
//#region FILTROS GENERALES
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.    Filtra una función por la fecha hasta donde se van a realizar los cálculos. Se recomienda utilizar la función fecha, así se evitan confusiones entre meses y días. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} Fecha  Fecha hasta donde se van a realizar los cálculos
 */
export async function fechaParaFiltros(Fecha: string) {
	try {
		// Obtenemos el valor de la fecha ingresada, utilizando la funcion de FechaFin ya que si esta en 0 o vacio, debe retornar el valor de la fecha de trabajo
		let fecha: any = await _FechaFin(Fecha);
		// Se verifica que le fecha no incluya texto, de contener, seria una fecha incorrecta y se muestra mensaje de error
		if (bIsText(Fecha)) throw ErrorDate

		let returnFecha: any = new Date(fecha).setUTCHours(5);
		returnFecha = new Date(returnFecha).toLocaleDateString();
		if (returnFecha == "Invalid Date") {
			saveError("FECHAPARAFILTROS", fecha, new Error("Se ha ingresado un formato de fecha incorrecto, se recomienda utilizar la función FECHA"));
			return E1;
		} else
			return returnFecha;
	} catch (error) {
		saveError("FECHAPARAFILTROS", Fecha, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función con más de un filtro, realizando los cálculos con todos los filtros. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string[]} FiltroN Filtro que se desea aplicar a la función.
 */
export function filtros_and(FiltroN: string[]) {
	try {
		if (FiltroN.length == 0) throw ErrorNoParam;
		let res = "";
		res = FiltroN[0];
		if (FiltroN.length > 1)
			for (let index = 1; index < FiltroN.length; index++)
				if (FiltroN[index] !== "") {
					if (res == "") res += FiltroN[index]
					else res += " AND " + FiltroN[index]
				}
		if (res == "") throw ErrorNoParam;
		return res;
	} catch (error) {
		saveError("FILTROS_AND", val2Str(FiltroN), error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función con más de un filtro, realizando los cálculos con almenos un filtro. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string[]} FiltroN Filtro que se desea aplicar a la función.
 */
export function filtros_or(FiltroN: string[]) {
	try {
		if (FiltroN.length == 0) throw ErrorNoParam;
		let res = "";
		res = FiltroN[0];
		if (FiltroN.length > 1) {
			for (let index = 1; index < FiltroN.length; index++)
				if (FiltroN[index] !== "") {
					if (res == "")
						res += FiltroN[index]
					else res += " OR " + FiltroN[index]
				}
		}
		if (res == "") throw ErrorNoParam;
		return res;
	} catch (error) {
		saveError("FILTROS_OR", val2Str(FiltroN), error as Error);
		return E1;
	}
}
//#endregion
//#region FILTROMOVCONT - MOVIMIENTO CONTABLE
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.   Filtra una función con una o más actividades determinadas por el usuario. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaActividades  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_actividad(ListaActividades: string) {
	try {
		//Obtencion del Filtro de manera generica enviando los datos correspondientes
		return generarfiltro("CNTMOVUN", "IActividad", ListaActividades, "");
	} catch (error) {
		saveError("FILTROMOVCONT_ACTIVIDAD", ListaActividades, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función con uno o más activos determinados por el usuario. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaActivos  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_activo(ListaActivos: string) {
	try {
		return generarfiltro("CNTMOVUN", "IActivo", ListaActivos, "");
	} catch (error) {
		saveError("FILTROMOVCONT_ACTIVO", ListaActivos, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las operaciones realizadas en un año en específico. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaAños  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_anio(ListaAños: string) {
	try {
		//Como este metodo tiene un formato especifico, se valida que compla con dicho formato
		if (ListaAños.includes("%") || bIsText(ListaAños) || ListaAños.includes("-")) {
			saveError("FILTROMOVCONT_ANIO", ListaAños, new Error("El parámetro solo puede contener valores númericos positivos, en caso de ser varios valores, se pueden enviar separados por punto y coma ( ; ) o coma ( , )."));
			return "#¡NUM!";
		}
		//Obtencion del filtro de manera generica enviando los valores correspondientes
		return generarfiltro("CNTMOVUN", "FAnio", ListaAños, "");
	} catch (error) {
		//Manejo de errores
		saveError("FILTROMOVCONT_ANIO", ListaAños, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función con uno o más bancos determinados por el usuario, tenga en cuenta que el sistema cuenta con un catalogo de bancos el cual es configurado por el usuario.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaBancos  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_banco(ListaBancos: string) {
	try {
		return generarfiltro("CNTMOVUN", "IBanco", ListaBancos, "UPPER");
	} catch (error) {
		saveError("FILTROMOVCONT_BANCO", ListaBancos, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las operaciones a las que fue imputado un centro de costos determinado. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaCentrosdecostos  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_centrodecostos(ListaCentrosdecostos: string) {
	try {
		return generarfiltro("CNTMOVUN", "ICC", ListaCentrosdecostos, "");
	} catch (error) {
		saveError("FILTROMOVCONT_CENTRODECOSTOS", ListaCentrosdecostos, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las operaciones con productos elaborados en un centro de costos de producción específico.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaCentrosdecostos  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_centrodecostosorigen(ListaCentrosdecostos: string) {
	try {
		return generarfiltro("CNTMOVUN", "ICCOrigen", ListaCentrosdecostos, "");
	} catch (error) {
		saveError("FILTROMOVCONT_CENTRODECOSTOSORIGEN", ListaCentrosdecostos, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.   Filtra una función con las cuentas que exigen clase 1.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} sListaClases  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_clase1(sListaClases: string) {
	try {
		return generarfiltro("CNTMOVUN", "Clase1", sListaClases, "UPPER");
	} catch (error) {
		saveError("FILTROMOVCONT_CLASE1", sListaClases, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.   Filtra una función con las cuentas que exigen clase 2.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} sListaClases  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_clase2(sListaClases: string) {
	try {
		return generarfiltro("CNTMOVUN", "Clase2", sListaClases, "UPPER");
	} catch (error) {
		saveError("FILTROMOVCONT_CLASE2", sListaClases, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.   Filtra una función según la naturaleza de la operación.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} sListaClases  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_claseoperacion(sListaClases: string) {
	try {
		if (sListaClases.includes("%") || bIsText(sListaClases)) {
			saveError("FILTROMOVCONT_CLASEOPERACION", sListaClases, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("CNTMOVUN", "IClasifOp", sListaClases, "");
	} catch (error) {
		saveError("FILTROMOVCONT_CLASEOPERACION", sListaClases, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las cuentas determinadas. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaCuentas  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_cuenta(ListaCuentas: string) {
	try {
		return generarfiltro("CNTMOVUN", "ICuenta", ListaCuentas, "");
	} catch (error) {
		saveError("FILTROMOVCONT_CUENTA", ListaCuentas, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función con las cuentas que han sido afectadas por la operación de cierre de mes.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaCuentas  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_cuentafuente(ListaCuentas: string) {
	try {
		return generarfiltro("CNTMOVUN", "ICuentaFuente", ListaCuentas, "");
	} catch (error) {
		saveError("FILTROMOVCONT_CUENTAFUENTE", ListaCuentas, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.   Filtra una función con las operaciones en donde se imputan cuentas de presupuesto 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaCuentas  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_cuentapresupuesto(ListaCuentas: string) {
	try {
		return generarfiltro("CNTMOVUN", "ICtaPpto", ListaCuentas, "");
	} catch (error) {
		saveError("FILTROMOVCONT_CUENTAPRESUPUESTO", ListaCuentas, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las operaciones realizadas en un día en específico.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaDia  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_dia(ListaDia: string) {
	try {
		if (ListaDia.includes("%") || bIsText(ListaDia) || ListaDia.includes("-")) {
			saveError("FILTROMOVCONT_DIA", ListaDia, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("CNTMOVUN", "FDia", ListaDia, "");
	} catch (error) {
		saveError("FILTROMOVCONT_DIA", ListaDia, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por los documentos de referencia en los cuales está soportada la operación. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaDocumentos  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_documentoreferencia(ListaDocumentos: string) {
	try {
		return generarfiltro("CNTMOVUN", "ICxx", ListaDocumentos, "");
	} catch (error) {
		saveError("FILTROMOVCONT_DOCUMENTOREFERENCIA", ListaDocumentos, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las operaciones realizadas en una empresa en específica.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaEmpresas  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_empresa(ListaEmpresas: string) {
	try {
		if (ListaEmpresas.includes("%") || bIsText(ListaEmpresas)) {
			saveError("FILTROMOVCONT_EMPRESA", ListaEmpresas, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("CNTMOVUN", "IEmp", ListaEmpresas, "");
	} catch (error) {
		saveError("FILTROMOVCONT_EMPRESA", ListaEmpresas, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.   Filtra una función por estado en que se encuentra el centro de costos. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaEstados  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_estado(ListaEstados: string) {
	try {
		if (ListaEstados.includes("%") || bIsText(ListaEstados)) {
			saveError("FILTROMOVCONT_ESTADO", ListaEstados, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("CNTMOVUN", "IEstado", ListaEstados, "");
	} catch (error) {
		saveError("FILTROMOVCONT_ESTADO", ListaEstados, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por la etapa en que se encuentra el centro de costos. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaEtapas  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_etapa(ListaEtapas: string) {
	try {
		if (ListaEtapas.includes("%") || bIsText(ListaEtapas)) {
			saveError("FILTROMOVCONT_ETAPA", ListaEtapas, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("CNTMOVUN", "IEtapa", ListaEtapas, "");
	} catch (error) {
		saveError("FILTROMOVCONT_ETAPA", ListaEtapas, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función según el tipo de flujo de efectivo que ha sido utilizado en la operación.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaFlujos  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_flujo(ListaFlujos: string) {
	try {
		return generarfiltro("CNTMOVUN", "IFlujo", ListaFlujos, "");
	} catch (error) {
		saveError("FILTROMOVCONT_FLUJO", ListaFlujos, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las operaciones realizadas en un mes en específico.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaMes  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_mes(ListaMes: string) {
	try {
		if (ListaMes.includes("%") || bIsText(ListaMes)) {
			saveError("FILTROMOVCONT_MES", ListaMes, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("CNTMOVUN", "FMes", ListaMes, "");
	} catch (error) {
		saveError("FILTROMOVCONT_MES", ListaMes, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por el número del ciclo productivo en el que se encuentra el centro de costos.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaCiclos  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_numeroCicloDeCostos(ListaCiclos: string) {
	try {
		if (ListaCiclos.includes("%") || bIsText(ListaCiclos)) {
			saveError("FILTROMOVCONT_NUMEROCICLODECOSTOS", ListaCiclos, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("CNTMOVUN", "QNumCiclocostos", ListaCiclos, "");
	} catch (error) {
		saveError("FILTROMOVCONT_NUMEROCICLODECOSTOS", ListaCiclos, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por la fecha de creación de la operación realizada
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} FechaIni  Fecha inicial hasta donde se van a realizar los cálculos
 * @param {string} FechaFin  Fecha final hasta donde se van a realizar los cálculos
 */
export async function filtromovcont_periodo(FechaIni: string, FechaFin: string) {
	try {
		let fechaIni = new Date(_FechaIni(FechaIni));
		let fechaFin = new Date(await _FechaFin(FechaFin));
		if (bIsText(FechaIni) || bIsText(FechaFin)) {
			saveError("FILTROMOVCONT_PERIODO", FechaIni + +FechaFin, ErrorDate);
			return E1;
		} else {
			//(CNTMOVUN.FSOPORT BETWEEN '03/09/1901' AND '01/31/2019')
			fechaIni.setUTCHours(5);
			fechaFin.setUTCHours(5);
			let returnFiltro: string = "(CNTMOVUN.FSOPORT BETWEEN '" + fechaIni.toLocaleDateString() + "' AND '" + fechaFin.toLocaleDateString() + "')";
			return returnFiltro;
		}
	} catch (error) {
		saveError("FILTROMOVCONT_PERIODO", FechaIni + +FechaFin, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por la fecha de pago de una cuenta por cobrar o por pagar.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} FechaIni  Fecha inicial hasta donde se van a realizar los cálculos
 * @param {string} FechaFin  Fecha final hasta donde se van a realizar los cálculos
 */
export async function filtromovcont_periodoFechaPago(FechaIni: string, FechaFin: string) {
	try {
		let fechaIni = new Date(_FechaIni(FechaIni));
		let fechaFin = new Date(await _FechaFin(FechaFin));
		if (bIsText(FechaIni) || bIsText(FechaFin)) {
			saveError("FILTROMOVCONT_PERIODOFECHAPAGO", FechaIni + +FechaFin, ErrorDate);
			return E1;
		} else {
			//(CNTMOVUN.FSOPORT BETWEEN '03/09/1901' AND '01/31/2019')
			fechaIni.setUTCHours(5);
			fechaFin.setUTCHours(5);
			let returnFiltro: string = "(CNTMOVUN.FPAGO BETWEEN '" + fechaIni.toLocaleDateString() + "' AND '" + fechaFin.toLocaleDateString() + "')";
			return returnFiltro;
		}
	} catch (error) {
		saveError("FILTROMOVCONT_PERIODOFECHAPAGO", FechaIni + +FechaFin, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las operaciones realizadas en una sede de la empresa en específica. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaSedes  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_sede(ListaSedes: string) {
	try {
		return generarfiltro("CNTMOVUN", "ISede", ListaSedes, "");
	} catch (error) {
		saveError("FILTROMOVCONT_SEDE", ListaSedes, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las operaciones realizadas en el número de una semana en específica.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaSemanas  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_semana(ListaSemanas: string) {
	try {
		if (ListaSemanas.includes("%") || bIsText(ListaSemanas)) {
			saveError("FILTROMOVCONT_SEMANA", ListaSemanas, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("CNTMOVUN", "FSemana", ListaSemanas, "");
	} catch (error) {
		saveError("FILTROMOVCONT_SEMANA", ListaSemanas, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Esta función filtra una función por los terceros determindados.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaTerceros  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_tercero(ListaTerceros: string) {
	try {
		return generarfiltro("CNTMOVUN", "Init", ListaTerceros, "");
	} catch (error) {
		saveError("FILTROMOVCONT_TERCERO", ListaTerceros, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las cuentas por cobrar o por pagar según los terceros dados.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaTerceros  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_terceroCartera(ListaTerceros: string) {
	try {
		return generarfiltro("CNTMOVUN", "InitCxx", ListaTerceros, "");
	} catch (error) {
		saveError("FILTROMOVCONT_TERCEROCARTERA", ListaTerceros, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por el documento en que se encuentra soportado. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} nListaTipos  Filtro que se desea aplicar a la función.
 */
export function filtromovcont_tipoSoporte(nListaTipos: string) {
	try {
		if (nListaTipos.includes("%") || bIsText(nListaTipos)) {
			saveError("FILTROMOVCONT_TIPOSOPORTE", nListaTipos, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("CNTMOVUN", "ItdSop", nListaTipos, "");
	} catch (error) {
		saveError("FILTROMOVCONT_TIPOSOPORTE", nListaTipos, error as Error);
		return E1;
	}
}
//#endregion
//#region FILTROMOVINV - MOVIMIENTO DE INVENTARIO
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función con una o más actividades determinadas por el usuario. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaActividades  Filtro que se desea aplicar a la función.
 */
export function filtromovinv_actividad(ListaActividades: string) {
	try {
		//Obtencion del Filtro de manera generica enviando los datos correspondientes
		return generarfiltro("KARMOVUN", "IActividad", ListaActividades, "");
	} catch (error) {
		saveError("FILTROMOVINV_ACTIVIDAD", ListaActividades, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las operaciones realizadas en un año en específico. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaAños  Filtro que se desea aplicar a la función.
 */
export function filtromovinv_anio(ListaAños: string) {
	try {
		//Como este metodo tiene un formato especifico, se valida que compla con dicho formato
		if (ListaAños.includes("%") || bIsText(ListaAños) || ListaAños.includes("-")) {
			saveError("FILTROMOVINV_ANIO", ListaAños, new Error("El parámetro solo puede contener valores númericos positivos, en caso de ser varios valores, se pueden enviar separados por punto y coma ( ; ) o coma ( , )."));
			return "#¡NUM!"
		}
		//Obtencion del filtro de manera generica enviando los valores correspondientes
		return generarfiltro("KARMOVUN", "FAnio", ListaAños, "");
	} catch (error) {
		//Manejo de errores
		saveError("FILTROMOVINV_ANIO", ListaAños, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las operaciones realizadas en una o varias bodegas en específico.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaBodegas  Filtro que se desea aplicar a la función.
 */
export function filtromovinv_bodega(ListaBodegas: string) {
	try {
		//Como este metodo tiene un formato especifico, se valida que compla con dicho formato
		if (ListaBodegas.includes("%") || bIsText(ListaBodegas)) {
			saveError("FILTROMOVINV_BODEGA", ListaBodegas, ErrorNum);
			return "#¡NUM!"
		}
		//Obtencion del filtro de manera generica enviando los valores correspondientes
		return generarfiltro("KARMOVUN", "IInventario", ListaBodegas, "");
	} catch (error) {
		//Manejo de errores
		saveError("FILTROMOVINV_BODEGA", ListaBodegas, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.   Filtra una función según la naturaleza de la operación.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} sListaClases  Filtro que se desea aplicar a la función.
 */
export function filtromovinv_claseOperacion(sListaClases: string) {
	try {
		if (sListaClases.includes("%") || bIsText(sListaClases)) {
			saveError("FILTROMOVINV_CLASEOPERACION", sListaClases, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("KARMOVUN", "IClasifOp", sListaClases, "");
	} catch (error) {
		saveError("FILTROMOVINV_CLASEOPERACION", sListaClases, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las operaciones realizadas en un día en específico.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaDia  Filtro que se desea aplicar a la función.
 */
export function filtromovinv_dia(ListaDia: string) {
	try {
		if (ListaDia.includes("%") || bIsText(ListaDia) || ListaDia.includes("-")) {
			saveError("FILTROMOVINV_DIA", ListaDia, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("KARMOVUN", "FDia", ListaDia, "");
	} catch (error) {
		saveError("FILTROMOVINV_DIA", ListaDia, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.   Filtra una función por las operaciones realizadas con uno o varios elementos en específico. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaElementos  Filtro que se desea aplicar a la función.
 */
export function filtromovinv_elemento(ListaElementos: string) {
	try {
		//Obtencion del Filtro de manera generica enviando los datos correspondientes
		return generarfiltro("KARMOVUN", "IProducto", ListaElementos, "");
	} catch (error) {
		saveError("FILTROMOVINV_ELEMENTO", ListaElementos, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.    Filtra una función por las operaciones realizadas en una empresa en específico. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaEmpresas  Filtro que se desea aplicar a la función.
 */
export function filtromovinv_empresa(ListaEmpresas: string) {
	try {
		if (ListaEmpresas.includes("%") || bIsText(ListaEmpresas)) {
			saveError("FILTROMOVINV_EMPRESA", ListaEmpresas, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("KARMOVUN", "IEmp", ListaEmpresas, "");
	} catch (error) {
		saveError("FILTROMOVINV_EMPRESA", ListaEmpresas, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.   Filtra una función por estado en que se encuentra el centro de costos. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaEstados  Filtro que se desea aplicar a la función.
 */
export function filtromovinv_estado(ListaEstados: string) {
	try {
		if (ListaEstados.includes("%") || bIsText(ListaEstados)) {
			saveError("FILTROMOVINV_ESTADO", ListaEstados, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("KARMOVUN", "IEstado", ListaEstados, "");
	} catch (error) {
		saveError("FILTROMOVINV_ESTADO", ListaEstados, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por la etapa en que se encuentra el centro de costos. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaEtapas  Filtro que se desea aplicar a la función.
 */
export function filtromovinv_etapa(ListaEtapas: string) {
	try {
		if (ListaEtapas.includes("%") || bIsText(ListaEtapas)) {
			saveError("FILTROMOVINV_ETAPA", ListaEtapas, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("KARMOVUN", "IEtapa", ListaEtapas, "");
	} catch (error) {
		saveError("FILTROMOVINV_ETAPA", ListaEtapas, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las operaciones realizadas en un mes en específico.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaMes  Filtro que se desea aplicar a la función.
 */
export function filtromovinv_mes(ListaMes: string) {
	try {
		if (ListaMes.includes("%") || bIsText(ListaMes)) {
			saveError("FILTROMOVINV_MES", ListaMes, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("KARMOVUN", "FMes", ListaMes, "");
	} catch (error) {
		saveError("FILTROMOVINV_MES", ListaMes, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por el número del ciclo productivo en el que se encuentra el centro de costos.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaCiclos  Filtro que se desea aplicar a la función.
 */
export function filtromovinv_numeroCicloDeCostos(ListaCiclos: string) {
	try {
		if (ListaCiclos.includes("%") || bIsText(ListaCiclos)) {
			saveError("FILTROMOVINV_NUMEROCICLODECOSTOS", ListaCiclos, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("KARMOVUN", "QNumCiclocostos", ListaCiclos, "");
	} catch (error) {
		saveError("FILTROMOVINV_NUMEROCICLODECOSTOS", ListaCiclos, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las operaciones en que se determine un porcentaje de descuento. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaPorcentajes  Filtro que se desea aplicar a la función.
 */
export function filtromovinv_porcentajeDescuento(ListaPorcentajes: string) {
	try {
		if (ListaPorcentajes.includes("%") || bIsText(ListaPorcentajes)) {
			saveError("FILTROMOVINV_PORCENTAJEDESCUENTO", ListaPorcentajes, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("KARMOVUN", "PDescuento", ListaPorcentajes, "");
	} catch (error) {
		saveError("FILTROMOVINV_PORCENTAJEDESCUENTO", ListaPorcentajes, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las operaciones en que se determine un porcentaje para el IVA.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaPorcentajes  Filtro que se desea aplicar a la función.
 */
export function filtromovinv_porcentajeiva(ListaPorcentajes: string) {
	try {
		if (ListaPorcentajes.includes("%") || bIsText(ListaPorcentajes)) {
			saveError("FILTROMOVINV_PORCENTAJEIVA", ListaPorcentajes, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("KARMOVUN", "PIVA", ListaPorcentajes, "");
	} catch (error) {
		saveError("FILTROMOVINV_PORCENTAJEIVA", ListaPorcentajes, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por la mesa en la cual se está generando una factura.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaMesas  Filtro que se desea aplicar a la función.
 */
export function filtromovinv_pormesa(ListaMesas: string) {
	try {
		if (ListaMesas.includes("%") || bIsText(ListaMesas)) {
			saveError("FILTROMOVINV_PORMESA", ListaMesas, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("KARMOVUN", "ICuentaPorFacturar", ListaMesas, "");
	} catch (error) {
		saveError("FILTROMOVINV_PORMESA", ListaMesas, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las operaciones realizadas en el número de una semana en específica.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaSemanas  Filtro que se desea aplicar a la función.
 */
export function filtromovinv_semana(ListaSemanas: string) {
	try {
		if (ListaSemanas.includes("%") || bIsText(ListaSemanas)) {
			saveError("FILTROMOVINV_SEMANA", ListaSemanas, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("KARMOVUN", "FSemana", ListaSemanas, "");
	} catch (error) {
		saveError("FILTROMOVINV_SEMANA", ListaSemanas, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Esta función filtra una función por los terceros determindados.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaTerceros  Filtro que se desea aplicar a la función.
 */
export function filtromovinv_tercero(ListaTerceros: string) {
	try {
		return generarfiltro("KARMOVUN", "Init", ListaTerceros, "");
	} catch (error) {
		saveError("FILTROMOVINV_TERCERO", ListaTerceros, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por el tipo de producto. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} sListaTipos  Filtro que se desea aplicar a la función.
 */
export function filtromovinv_tipoProducto(sListaTipos: string) {
	try {
		return generarfiltro("KARMOVUN", "ITipoProducto", sListaTipos, "UPPER");
	} catch (error) {
		saveError("FILTROMOVINV_TIPOPRODUCTO", sListaTipos, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por el documento en que se encuentra soportado. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} nListaTipos  Filtro que se desea aplicar a la función.
 */
export function filtromovinv_tiposoporte(nListaTipos: string) {
	try {
		if (nListaTipos.includes("%") || bIsText(nListaTipos)) {
			saveError("FILTROMOVINV_TIPOSOPORTE", nListaTipos, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("KARMOVUN", "ITDSop", nListaTipos, "");
	} catch (error) {
		saveError("FILTROMOVINV_TIPOSOPORTE", nListaTipos, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por los terceros los cuales realizaron las ventas de algún elemento del inventario. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaVendedores  Filtro que se desea aplicar a la función.
 */
export function filtromovinv_vendedor(ListaVendedores: string) {
	try {
		return generarfiltro("KARMOVUN", "InitVendedor", ListaVendedores, "");
	} catch (error) {
		saveError("FILTROMOVINV_VENDEDOR", ListaVendedores, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por los terceros que colaboraron en las ventas de algún elemento del inventario.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaVendedores  Filtro que se desea aplicar a la función.
 */
export function filtromovinv_vendedor2(ListaVendedores: string) {
	try {
		return generarfiltro("KARMOVUN", "InitVendedor2", ListaVendedores, "");
	} catch (error) {
		saveError("FILTROMOVINV_VENDEDOR", ListaVendedores, error as Error);
		return E1;
	}
}
//#endregion
//#region FILTROCUENTA - PLAN DE CUENTAS
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las cuentas determinadas. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaIdentificadores  Filtro que se desea aplicar a la función.
 */
export function filtrocuenta_identificador(ListaIdentificadores: string) {
	try {
		return generarfiltro("CNTPUC", "ICuenta", ListaIdentificadores, "");
	} catch (error) {
		saveError("FILTROCUENTA_IDENTIFICADOR", ListaIdentificadores, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las cuentas que son ajustadas por inflación.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrocuenta_soloAjustesXInf() {
	try {
		return generarfiltro("CNTPUC", "BAJUSTARXINF", "T", "");
	} catch (error) {
		saveError("FILTROCUENTA_SOLOAJUSTESXINF", "T", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las cuentas que son afectables directamente por el usuario. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrocuenta_soloautoactivables() {
	try {
		return generarfiltro("CNTPUC", "BAUTOACTIVAR", "T", "");
	} catch (error) {
		saveError("FILTROCUENTA_SOLOAUTOACTIVABLES", "T", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las cuentas que exigen activo.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrocuenta_soloexigeactivo() {
	try {
		return generarfiltro("CNTPUC", "BEXIGEACTIVO", "T", "");
	} catch (error) {
		saveError("FILTROCUENTA_SOLOEXIGEACTIVO", "T", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las cuentas que exigen valor base.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrocuenta_soloexigebase() {
	try {
		return generarfiltro("CNTPUC", "IEXIGEBASE", "T", "");
	} catch (error) {
		saveError("FILTROCUENTA_SOLOEXIGEBASE", "T", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las cuentas que exigen la clase 1.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrocuenta_soloexigeclase1() {
	try {
		return generarfiltro("CNTPUC", "BEXIGECLASE1", "T", "");
	} catch (error) {
		saveError("FILTROCUENTA_SOLOEXIGECLASE1", "T", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las cuentas que exigen la clase 2.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrocuenta_soloexigeclase2() {
	try {
		return generarfiltro("CNTPUC", "BEXIGECLASE2", "T", "");
	} catch (error) {
		saveError("FILTROCUENTA_SOLOEXIGECLASE2", "T", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.   Filtra una función por las cuentas que exigen el valor 1.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrocuenta_soloexigevalor1() {
	try {
		return generarfiltro("CNTPUC", "BEXIGEVALOR1", "T", "");
	} catch (error) {
		saveError("FILTROCUENTA_SOLOEXIGEVALOR1", "T", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.   Filtra una función por las cuentas que exigen el valor 2.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrocuenta_soloexigevalor2() {
	try {
		return generarfiltro("CNTPUC", "BEXIGEVALOR2", "T", "");
	} catch (error) {
		saveError("FILTROCUENTA_SOLOEXIGEVALOR2", "T", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las cuentas que manejan cartera. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrocuenta_solomanejacartera() {
	try {
		return generarfiltro("CNTPUC", "BCONTROLCXX", "T", "");
	} catch (error) {
		saveError("FILTROCUENTA_SOLOMANEJACARTERA", "T", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las cuentas que manejan referencias, esto indica si el módulo de cartera deberá manejar el endeudamiento basado en sub-documentos de soporte o no. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrocuenta_solomanejacuotas() {
	try {
		return generarfiltro("CNTPUC", "BMANEJACUOTAS", "T", "");
	} catch (error) {
		saveError("FILTROCUENTA_SOLOMANEJACUOTAS", "T", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por las cuentas que manejan otra moneda. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrocuenta_solomanejaotramoneda() {
	try {
		return generarfiltro("CNTPUC", "BMANEJAOTRAMONEDA", "T", "");
	} catch (error) {
		saveError("FILTROCUENTA_SOLOMANEJAOTRAMONEDA", "T", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.   Filtra una función por las cuentas que maneja terceros. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrocuenta_solomanejatercero() {
	try {
		return generarfiltro("CNTPUC", "BMANEJATERCERO", "T", "");
	} catch (error) {
		saveError("FILTROCUENTA_SOLOMANEJATERCERO", "T", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.   Filtra una función por las cuentas que que manejan temporales 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrocuenta_solotemporales() {
	try {
		return generarfiltro("CNTPUC", "BTEMPORALANIO", "T", "");
	} catch (error) {
		saveError("FILTROCUENTA_SOLOTEMPORALES", "T", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función con el tipo de la cuenta indicada.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaTipos  Filtro que se desea aplicar a la función.
 */
export function filtrocuenta_tipo(ListaTipos: string) {
	try {
		if (ListaTipos.includes("%") || bIsText(ListaTipos)) {
			saveError("FILTROCUENTA_TIPO", ListaTipos, ErrorNum);
			return "#¡NUM!"
		}
		return generarfiltro("CNTPUC", "ItdCuenta", ListaTipos, "");
	} catch (error) {
		saveError("FILTROCUENTA_TIPO", ListaTipos, error as Error);
		return E1;
	}
}
//#endregion
//#region FILTROTERCERO - TERCEROS
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por los terceros con determinada categoría, ésta es asignada a cada tercero desde la configuración del mismo. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaCategorias  Filtro que se desea aplicar a la función.
 */
export function filtrotercero_categoria(ListaCategorias: string) {
	try {
		return generarfiltro("ABANITS", "ItdCategoria", ListaCategorias, "UPPER");
	} catch (error) {
		saveError("FILTROTERCERO_CATEGORIA", ListaCategorias, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.   Filtra una función por los terceros provenientes de una ciudad determinada.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaCiudades  Filtro que se desea aplicar a la función.
 */
export function filtrotercero_ciudad(ListaCiudades: string) {
	try {
		return generarfiltro("ABANITS", "IMun", ListaCiudades, "");
	} catch (error) {
		saveError("FILTROTERCERO_CIUDAD", ListaCiudades, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por los terceros con determinada clase 1
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} sListaClases  Filtro que se desea aplicar a la función.
 */
export function filtrotercero_clase1(sListaClases: string) {
	try {
		return generarfiltro("ABANITS", "Clase1", sListaClases, "UPPER");
	} catch (error) {
		saveError("FILTROTERCERO_CLASE1", sListaClases, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por los terceros con determinada clase 2
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} sListaClases  Filtro que se desea aplicar a la función.
 */
export function filtrotercero_clase2(sListaClases: string) {
	try {
		return generarfiltro("ABANITS", "Clase2", sListaClases, "UPPER");
	} catch (error) {
		saveError("FILTROTERCERO_CLASE2", sListaClases, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por los terceros con determinada clasificación
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaClasificaciones  Filtro que se desea aplicar a la función.
 */
export function filtrotercero_clasificacion(ListaClasificaciones: string) {
	try {
		return generarfiltro("ABANITS", "ITdTercero", ListaClasificaciones, "UPPER");
	} catch (error) {
		saveError("FILTROTERCERO_CLASIFICACION", ListaClasificaciones, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por los terceros con determinada clasificación legal 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaClasificaciones  Filtro que se desea aplicar a la función.
 */
export function filtrotercero_clasificacionlegal(ListaClasificaciones: string) {
	try {
		return generarfiltro("ABANITS", "SClasifLegal", ListaClasificaciones, "UPPER");
	} catch (error) {
		saveError("FILTROTERCERO_CLASIFICACIONLEGAL", ListaClasificaciones, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.   Filtra una función por los terceros con determinado dato 1, éste es asignado a cada tercero desde la configuración del mismo.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaDatos  Filtro que se desea aplicar a la función.
 */
export function filtrotercero_dato1(ListaDatos: string) {
	try {
		return generarfiltro("ABANITS", "Dato1", ListaDatos, "UPPER");
	} catch (error) {
		saveError("FILTROTERCERO_DATO1", ListaDatos, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.   Filtra una función por los terceros con determinado dato 2, éste es asignado a cada tercero desde la configuración del mismo.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaDatos  Filtro que se desea aplicar a la función.
 */
export function filtrotercero_dato2(ListaDatos: string) {
	try {
		return generarfiltro("ABANITS", "Dato2", ListaDatos, "UPPER");
	} catch (error) {
		saveError("FILTROTERCERO_DATO2", ListaDatos, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.   Filtra una función por los terceros provenientes de una ciudad determinada.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaDepartamentos  Filtro que se desea aplicar a la función.
 */
export function filtrotercero_departamento(ListaDepartamentos: string) {
	try {
		return generarfiltro("ABANITS", "IDep", ListaDepartamentos, "");
	} catch (error) {
		saveError("FILTROTERCERO_DEPARTAMENTO", ListaDepartamentos, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por los terceros que se le indiquen. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaIdentificadores  Filtro que se desea aplicar a la función.
 */
export function filtrotercero_identificador(ListaIdentificadores: string) {
	try {
		return generarfiltro("ABANITS", "Init", ListaIdentificadores, "");
	} catch (error) {
		saveError("FILTROTERCERO_IDENTIFICADOR", ListaIdentificadores, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por los terceros provenientes de un país determinado. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaPaises  Filtro que se desea aplicar a la función.
 */
export function filtrotercero_pais(ListaPaises: string) {
	try {
		return generarfiltro("ABANITS", "IPais", ListaPaises, "");
	} catch (error) {
		saveError("FILTROTERCERO_PAIS", ListaPaises, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.   Filtra una función por los terceros con un perfíl determinado.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaPerfiles  Filtro que se desea aplicar a la función.
 */
export function filtrotercero_perfil(ListaPerfiles: string) {
	try {
		return generarfiltro("ABANITS", "IPerfil", ListaPerfiles, "");
	} catch (error) {
		saveError("FILTROTERCERO_PERFIL", ListaPerfiles, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por los terceros que sean de tipo cliente.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} sListaTipos  Filtro que se desea aplicar a la función.
 */
export function filtrotercero_tipocliente(sListaTipos: string) {
	try {
		if (sListaTipos.includes("%") || bIsText(sListaTipos)) {
			saveError("FILTROTERCERO_TIPOCLIENTE", sListaTipos, ErrorNum);
			return "#¡NUM!";
		}
		return generarfiltro("ABANITS", "ItdCliente", sListaTipos, "");
	} catch (error) {
		saveError("FILTROTERCERO_TIPOCLIENTE", sListaTipos, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.   Filtra una función por los terceros con un tipo de documento determinado. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} sListaTipos  Filtro que se desea aplicar a la función.
 */
export function filtrotercero_tipodocumento(sListaTipos: string) {
	try {
		if (sListaTipos.includes("%") || bIsText(sListaTipos)) {
			saveError("FILTROTERCERO_TIPODOCUMENTO", sListaTipos, ErrorNum);
			return "#¡NUM!";
		}
		return generarfiltro("ABANITS", "ItdDocum", sListaTipos, "");
	} catch (error) {
		saveError("FILTROTERCERO_TIPODOCUMENTO", sListaTipos, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por los terceros que sean de tipo empleado. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} sListaTipos  Filtro que se desea aplicar a la función.
 */
export function filtrotercero_tipoempleado(sListaTipos: string) {
	try {
		if (sListaTipos.includes("%") || bIsText(sListaTipos)) {
			saveError("FILTROTERCERO_TIPOEMPLEADO", sListaTipos, ErrorNum);
			return "#¡NUM!";
		}
		return generarfiltro("ABANITS", "ItdEmpleado", sListaTipos, "");
	} catch (error) {
		saveError("FILTROTERCERO_TIPOEMPLEADO", sListaTipos, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.   Filtra una función por los terceros que sean de tipo proveedor.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} sListaTipos  Filtro que se desea aplicar a la función.
 */
export function filtrotercero_tipoproveedor(sListaTipos: string) {
	try {
		if (sListaTipos.includes("%") || bIsText(sListaTipos)) {
			saveError("FILTROTERCERO_TIPOPROVEEDOR", sListaTipos, ErrorNum);
			return "#¡NUM!";
		}
		return generarfiltro("ABANITS", "ItdProveedor", sListaTipos, "");
	} catch (error) {
		saveError("FILTROTERCERO_TIPOPROVEEDOR", sListaTipos, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por los terceros que sean de tipo vendedor.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} sListaTipos  Filtro que se desea aplicar a la función.
 */
export function filtrotercero_tipovendedor(sListaTipos: string) {
	try {
		if (sListaTipos.includes("%") || bIsText(sListaTipos)) {
			saveError("FILTROTERCERO_TIPOVENDEDOR", sListaTipos, ErrorNum);
			return "#¡NUM!";
		}
		return generarfiltro("ABANITS", "ItdVendedor", sListaTipos, "");
	} catch (error) {
		saveError("FILTROTERCERO_TIPOVENDEDOR", sListaTipos, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por los terceros que sean de tipo vendedor.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaVendedores  Filtro que se desea aplicar a la función.
 */
export function filtrotercero_vendedor(ListaVendedores: string) {
	try {
		return generarfiltro("ABANITS", "IVendedor", ListaVendedores, "");
	} catch (error) {
		saveError("FILTROTERCERO_VENDEDOR", ListaVendedores, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por los terceros que sean de tipo vendedor.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 * @param {string} ListaZonas  Filtro que se desea aplicar a la función.
 */
export function filtrotercero_zona(ListaZonas: string) {
	try {
		return generarfiltro("ABANITS", "NZona", ListaZonas, "UPPER");
	} catch (error) {
		saveError("FILTROTERCERO_ZONA", ListaZonas, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por los terceros que sean de tipo vendedor.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrotercero_soloclientes() {
	try {
		return generarfiltro("ABANITS", "BCLIENTE", "T", "");
	} catch (error) {
		saveError("FILTROTERCERO_SOLOCLIENTES", "", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por los tipos de terceros que son empleados.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrotercero_soloempleados() {
	try {
		return generarfiltro("ABANITS", "BEMPLEADO", "T", "");
	} catch (error) {
		saveError("FILTROTERCERO_SOLOEMPLEADOS", "", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por los terceros que tienen clasificación legal PJ, personas jurídicas. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrotercero_solopersonasjuridicas() {
	try {
		return generarfiltro("ABANITS", "BEMPRESA", "T", "");
	} catch (error) {
		saveError("FILTROTERCERO_SOLOPERSONASJURIDICAS", "", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por los terceros que tienen clasificación legal PN, personas naturales. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrotercero_solopersonasnaturales() {
	try {
		return generarfiltro("ABANITS", "BEMPRESA", "F", "");
	} catch (error) {
		saveError("FILTROTERCERO_SOLOPERSONASNATURALES", "", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por los tipos de terceros que son proveedores.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrotercero_soloproveedores() {
	try {
		return generarfiltro("ABANITS", "BPROVEEDOR", "T", "");
	} catch (error) {
		saveError("FILTROTERCERO_SOLOPROVEEDORES", "", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por los tipos de terceros que son vendedores. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrotercero_solovendedores() {
	try {
		return generarfiltro("ABANITS", "BVENDEDOR", "T", "");
	} catch (error) {
		saveError("FILTROTERCERO_SOLOVENDEDORES", "", error as Error);
		return E1;
	}
}
//#endregion
//#region FILTRO SOLO
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por solo las operaciones con el tipo de movimiento de inventario: "Mov. de ingreso de productos por compra". 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrosolocompras() {
	try {
		return generarfiltro("KARMOVUN", "ITdMov", "3006", "");
	} catch (error) {
		saveError("FILTROSOLOCOMPRAS", "", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por solo las operaciones con el tipo de movimiento de inventario Mov. de egresos de elementos para consumo.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrosoloconsumos() {
	try {
		return generarfiltro("KARMOVUN", "ITdMov", "3009", "");
	} catch (error) {
		saveError("FILTROSOLOCOMPRAS", "", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por dos tipos de movimiento de inventario: Mov. de egreso de elementos y Mov. de egresos de elementos para consumo. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrosoloegresos() {
	return ("((KARMOVUN.ITdMov = 3002) OR (KARMOVUN.ITDMov = 3009))");
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por solo las operaciones con el tipo de movimiento de inventario Mov. de ajuste egresos en bodega/inventario. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrosoloegresosporajustes() {
	try {
		return generarfiltro("KARMOVUN", "ITdMov", "3004", "");
	} catch (error) {
		saveError("FILTROSOLOEGRESOSPORAJUSTES", "", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por solo las operaciones con el tipo de movimiento de inventario Mov. de egresos de producto por devoluciones en compras.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrosoloegresospordevolucionesencompras() {
	try {
		return generarfiltro("KARMOVUN", "ITdMov", "3007", "");
	} catch (error) {
		saveError("FILTROSOLOEGRESOSPORDEVOLUCIONESENCOMPRAS", "", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por solo las operaciones con el tipo de movimiento de inventario Mov. de ingreso de producto por embodegamiento. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrosoloembodegamientos() {
	try {
		return generarfiltro("KARMOVUN", "ITdMov", "3001", "");
	} catch (error) {
		saveError("FILTROSOLOEMBODEGAMIENTOS", "", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por solo las operaciones con el tipo de movimiento de inventario Mov. de ajuste ingreso en bodega/inventario. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrosoloingresosporajustes() {
	try {
		return generarfiltro("KARMOVUN", "ITdMov", "3003", "");
	} catch (error) {
		saveError("FILTROSOLOINGRESOSPORAJUSTES", "", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por solo las operaciones con el tipo de movimiento de inventario Mov. de ingresos de producto por devoluciones en venta. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrosoloingresospordevolucionesenventas() {
	try {
		return generarfiltro("KARMOVUN", "ITdMov", "3008", "");
	} catch (error) {
		saveError("FILTROSOLOINGRESOSPORDEVOLUCIONESENVENTAS", "", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.   Filtra una función por solo las operaciones con el tipo de movimiento de inventario Mov. de ingresos de producto por traslado.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrosoloingresosportraslados() {
	try {
		return generarfiltro("KARMOVUN", "ITdMov", "3011", "");
	} catch (error) {
		saveError("FILTROSOLOINGRESOSPORTRASLADOS", "", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por solo las operaciones con el tipo de movimiento de inventario Mov. de inicialización de saldos de bodega/inventario. 
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrosolosaldosiniciales() {
	try {
		return generarfiltro("KARMOVUN", "ITdMov", "3010", "");
	} catch (error) {
		saveError("FILTROSOLOSALDOSINICIALES", "", error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Filtra una función por solo las operaciones con el tipo de movimiento en el inventario Mov. de venta de producto.  
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/todosLosFiltros.html
 */
export function filtrosoloventas() {
	try {
		return generarfiltro("KARMOVUN", "ITdMov", "3005", "");
	} catch (error) {
		saveError("FILTROSOLOVENTAS", "", error as Error);
		return E1;
	}
}
//#endregion
/////////////////////////////////////FIN FILTROS/////////////////////////////////////////
//#region  INVENTARIOS
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Esta función determina la cantidad que se ha consumido un elemento en un periodo de tiempo determinado, tenga en cuenta que esta función se basa en el sistema de inventarios de ContaPyme®.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadConsumos.html
 * @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
 * @param {string} [IElemento] Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
 * @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
 * @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
 * @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
 * @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
 * @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
 */
export async function cantidadConsumos(IBodega: string, IElemento: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string): Promise<string | number> {
	let nMethod = "cantidadConsumos";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		IElemento = _IElemento(IElemento);
		params += "&IElemento=" + IElemento;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina la cantidad de consumos de un elemento con un filtro determinado, tenga en cuenta que esta función se basa en el sistema de inventarios de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadConsumosFiltro.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
*/
export async function cantidadConsumosFiltro(IBodega: string, Filtro: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string): Promise<string | number> {
	let nMethod = "cantidadConsumosFiltro";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina los costos de un elemento del inventario por concepto de consumos en un periodo de tiempo determinado. Tenga en cuenta que la función se basa en el sistema de inventarios de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/costoConsumos.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [IElemento] Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function costoConsumos(IBodega: string, IElemento: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string, BLocal: string): Promise<string | number> {
	let nMethod = "costoConsumos";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		IElemento = _IElemento(IElemento);
		params += "&IElemento=" + IElemento;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina los costos de un elemento del inventario por concepto de consumos en un periodo de tiempo determinado. Tenga en cuenta que la función se basa en el sistema de inventarios de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/costoConsumosFiltro.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function costoConsumosFiltro(IBodega: string, Filtro: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string, BLocal: string): Promise<string | number> {
	let nMethod = "costoConsumosFiltro";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el costo promedio ponderado de un elemento, a una fecha determinada. Tenga en cuenta que esta función se basa en el sistema de inventarios de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/costoPromedioElemento.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [IElemento] Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function costoPromedioElemento(IBodega: string, IElemento: string, FechaFin: string, IEmp: string, ICCProductor: string, BLocal: string): Promise<string | number> {
	let nMethod = "costoPromedioElemento";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		IElemento = _IElemento(IElemento);
		params += "&IElemento=" + IElemento;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el costo promedio ponderado de un elemento, a una fecha determinada. Tenga en cuenta que esta función se basa en el sistema de inventarios de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/costoPromedioElementoFiltro.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función 
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function costoPromedioElementoFiltro(IBodega: string, Filtro: string, FechaFin: string, IEmp: string, ICCProductor: string, BLocal: string): Promise<string | number> {
	let nMethod = "costoPromedioElementoFiltro";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina la cantidad de entradas de un elemento del inventario, registrados en el sistema de inventarios de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadEntradas.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [IElemento] Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
*/
export async function cantidadEntradas(IBodega: string, IElemento: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string): Promise<string | number> {
	let nMethod = "cantidadEntradas";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		IElemento = _IElemento(IElemento);
		params += "&IElemento=" + IElemento;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina la cantidad de entradas de un elemento del inventario con un filtro determinado, registrados en el sistema de inventarios de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadEntradasFiltro.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
*/
export async function cantidadEntradasFiltro(IBodega: string, Filtro: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string): Promise<string | number> {
	let nMethod = "cantidadEntradasFiltro";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el costo de las entradas de un elemento del inventario, a una fecha determinada. Esta función se basa en el sistema de inventarios de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/costoEntradas.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [IElemento] Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function costoEntradas(IBodega: string, IElemento: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string, BLocal: string): Promise<string | number> {
	let nMethod = "costoEntradas";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		IElemento = _IElemento(IElemento);
		params += "&IElemento=" + IElemento;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el costo de las entradas de un elemento del inventario, a una fecha, con un filtro determinado. Tenga en cueta que esta función se basa en el sistema de inventarios de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/costoEntradasFiltro.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function costoEntradasFiltro(IBodega: string, Filtro: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string, BLocal: string): Promise<string | number> {
	let nMethod = "costoEntradasFiltro";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina la cantidad de existencias disponibles de un elemento del inventario a una fecha determinada. Tenga en cuenta que esta función se basa en el sistema de inventarios de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadExistencias.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [IElemento] Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
*/
export async function cantidadExistencias(IBodega: string, IElemento: string, FechaFin: string, IEmp: string, ICCProductor: string): Promise<string | number> {
	let nMethod = "cantidadExistencias";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		IElemento = _IElemento(IElemento);
		params += "&IElemento=" + IElemento;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina la cantidad de existencias disponibles de un elemento del inventario a una fecha y con un filtro determinado. Tenga en cuenta que esta función se basa en el sistema de inventarios de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadExistenciasFiltro.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función 
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
*/
export async function cantidadExistenciasFiltro(IBodega: string, Filtro: string, FechaFin: string, IEmp: string, ICCProductor: string): Promise<string | number> {
	let nMethod = "cantidadExistenciasFiltro";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el costo de las existencias de un elemento de inventario a una fecha determinada. Tenga en cuenta que esta función se basa en el sistema de inventarios de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/costoExistencias.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [IElemento] Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function costoExistencias(IBodega: string, IElemento: string, FechaFin: string, IEmp: string, ICCProductor: string, BLocal: string): Promise<string | number> {
	let nMethod = "costoExistencias";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		IElemento = _IElemento(IElemento);
		params += "&IElemento=" + IElemento;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el costo de las existencias de un elemento de inventario a un fecha y con un filtro determinado. Tenga en cuenta que esta función se basa en el sistema de inventarios de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/costoExistenciasFiltro.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función 
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function costoExistenciasFiltro(IBodega: string, Filtro: string, FechaFin: string, IEmp: string, ICCProductor: string, BLocal: string): Promise<string | number> {
	let nMethod = "costoExistenciasFiltro";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina la cantidad de existencias físicas de un elemento de inventario a una fecha determinada. Tenga en cuenta que esta función se basa en el sistema de inventarios de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadExistenciasFisico.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [IElemento] Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
*/
export async function cantidadExistenciasFisico(IBodega: string, IElemento: string, FechaFin: string, IEmp: string): Promise<string | number> {
	let nMethod = "cantidadExistenciasFisico";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		IElemento = _IElemento(IElemento);
		params += "&IElemento=" + IElemento;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para conocer un atributo de una bodega según el código dado. Tenga en cuenta que los atributos son características dadas a cada bodega y se configuran en cada una de ellas.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoBodega.html
* @param {string} NAtributo Determina el tipo de atributo que desea obtener
* @param {string} IBodega Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
*/
export async function atributoBodega(NAtributo: string, IBodega: string, IEmp: string): Promise<string | number> {
	let nMethod = "AtributoBodega";
	let params = "";
	try {
		IBodega = _IBodega(IBodega);
		params += "IBodega=" + (IBodega);
		NAtributo = ISData.getNAtributoInventarioDefault(NAtributo);
		params += "&natributo=" + (NAtributo);
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		if (IBodega == "" || NAtributo == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para conocer el nombre de una bodega registrada en el catálogo de inventarios de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/nombreBodega.html
* @param {string} IBodega Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
*/
export async function nombreBodega(IBodega: string, IEmp: string): Promise<string | number> {
	let nMethod = "NombreBodega";
	let params = "";
	try {
		IBodega = _IBodega(IBodega);
		params += "IBodega=" + (IBodega);
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		if (IBodega == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para conocer cualquier atributo de un elemento según el código dado. Tenga en cuenta que los atributos son características dadas a cada elemento y se configuran desde el catálogo de elementos de inventario de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoElemento.html
* @param {string} NAtributo Determina el tipo de atributo que desea obtener
* @param {string} [IElemento] Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
*/
export async function atributoElemento(NAtributo: string, IElemento: string): Promise<string | number> {
	let nMethod = "AtributoElemento";
	let params = "";
	try {
		IElemento = _IElemento(IElemento);
		params += "IElemento=" + IElemento;
		NAtributo = ISData.getNAtributoElementosInventarioDefault(NAtributo);
		params += "&natributo=" + (NAtributo);
		if (IElemento == "" || NAtributo == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para contar el número de elementos en el catálogo "Elementos de inventario" con un filtro determinado .
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/contarElementosFiltro.html
* @param {string} [IElemento] Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función 
*/
export async function contarElementosFiltro(IElemento: string, Filtro: string): Promise<string | number> {
	let nMethod = "ContarElementosFiltro";
	let params = "";
	try {
		IElemento = _IElemento(IElemento);
		params += "IElemento=" + IElemento;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el número de movimientos de inventario con un filtro determinado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/contarMovimientosInventarioFiltro.html
* @param {string} [Filtro] Filtro que se desea aplicar a la función 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
*/
export async function contarMovimientosInventarioFiltro(Filtro: string, FechaIni: string, FechaFin: string): Promise<string | number> {
	let nMethod = "ContarMovimientosInventarioFiltro";
	let params = "";
	try {
		FechaIni = _FechaIni(FechaIni);
		params += "fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para mostrar la descripción de un elemento del inventario. Tenga en cuenta que esta descripción es configurable desde el catálogo de inventarios de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/descripcionElemento.html
* @param {string} IElemento Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
*/
export async function descripcionElemento(IElemento: string): Promise<string | number> {
	let nMethod = "DescripcionElemento";
	let params = "";
	try {
		IElemento = _IElemento(IElemento);
		params += "IElemento=" + IElemento;
		if (IElemento == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para mostrar el nombre de un elemento del inventario. Tenga en cuenta que el nombre de un elemento es configurable desde el catálogo de inventarios de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/nombreElemento.html
* @param {string} IElemento Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
*/
export async function nombreElemento(IElemento: string): Promise<string | number> {
	let nMethod = "NombreElemento";
	let params = "";
	try {
		IElemento = _IElemento(IElemento);
		params += "IElemento=" + IElemento;
		if (IElemento == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para mostrar el peso de un elemento del inventario. Tenga en cuenta que el peso de un elemento es configurable desde el catálogo de inventarios de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/pesoElemento.html
* @param {string} IElemento Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
*/
export async function pesoElemento(IElemento: string): Promise<string | number> {
	let nMethod = "PesoElemento";
	let params = "";
	try {
		IElemento = _IElemento(IElemento);
		params += "IElemento=" + IElemento;
		if (IElemento == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para mostrar el stock máximo de un elemento del inventario. Tenga en cuenta que el stock de un elemento es configurable desde el catálogo de elementos de inventario de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/stockMax.html
* @param {string} IElemento Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [IEmp] Código de la empresa por la cual se desea filtrar. 
* @param {string} [ICC] Código del centro de costos por el cual se desea filtrar. 
*/
export async function stockMax(IElemento: string, IEmp: string, ICC: string): Promise<string | number> {
	let nMethod = "StockMax";
	let params = "";
	try {
		IElemento = _IElemento(IElemento);
		params += "IElemento=" + IElemento;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		if (IElemento == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para mostrar el stock mínimo de un elemento del inventario. Tenga en cuenta que el stock de un elemento es configurable desde el catálogo de elementos de inventario de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/stockMin.html
* @param {string} IElemento Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [IEmp] Código de la empresa por la cual se desea filtrar. 
* @param {string} [ICC] Código del centro de costos por el cual se desea filtrar. 
*/
export async function stockMin(IElemento: string, IEmp: string, ICC: string): Promise<string | number> {
	let nMethod = "StockMin";
	let params = "";
	try {
		IElemento = _IElemento(IElemento);
		params += "IElemento=" + IElemento;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		if (IElemento == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para mostrar la unidad de compra de un elemento de inventario. Tenga en cuenta que la unidad de compra de un elemento es configurable desde el catálogo de elementos de inventarios de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/unidadCompraElemento.html
* @param {string} IElemento Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
*/
export async function unidadCompraElemento(IElemento: string): Promise<string | number> {
	let nMethod = "UnidadCompraElemento";
	let params = "";
	try {
		IElemento = _IElemento(IElemento);
		params += "IElemento=" + IElemento;
		if (IElemento == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para mostrar la unidad de venta o consumo de un elemento de inventario. Tenga en cuenta que la unidad de un elemento es configurable desde el catálogo de elementos de inventarios de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/unidadElemento.html
* @param {string} IElemento Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
*/
export async function unidadElemento(IElemento: string): Promise<string | number> {
	let nMethod = "UnidadElemento";
	let params = "";
	try {
		IElemento = _IElemento(IElemento);
		params += "IElemento=" + IElemento;
		if (IElemento == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para mostrar la unidad del peso de un elemento de inventario. Tenga en cuenta que la unidad de peso de un elemento de inventario, es configurable desde el catálogo de elementos de inventarios de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/unidadPesoElemento.html
* @param {string} IElemento Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
*/
export async function unidadPesoElemento(IElemento: string): Promise<string | number> {
	let nMethod = "UnidadPesoElemento";
	let params = "";
	try {
		IElemento = _IElemento(IElemento);
		params += "IElemento=" + IElemento;
		if (IElemento == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para mostrar la unidad del volumen de un elemento del inventario. Tenga en cuenta que la unidad del volumen de un elemento, es configurable desde el catálogo de elementos de inventarios de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/unidadVolumenElemento.html
* @param {string} IElemento Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
*/
export async function unidadVolumenElemento(IElemento: string): Promise<string | number> {
	let nMethod = "UnidadVolumenElemento";
	let params = "";
	try {
		IElemento = _IElemento(IElemento);
		params += "IElemento=" + IElemento;
		if (IElemento == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para mostrar la unidad del volumen de un elemento del inventario. Tenga en cuenta que la unidad del volumen de un elemento, es configurable desde el catálogo de elementos de inventarios de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/volumenElemento.html
* @param {string} IElemento Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
*/
export async function volumenElemento(IElemento: string): Promise<string | number> {
	let nMethod = "VolumenElemento";
	let params = "";
	try {
		IElemento = _IElemento(IElemento);
		params += "IElemento=" + IElemento;
		if (IElemento == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para conocer los atributos del precio de un elemento según el código dado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoPreciosElemento.html
* @param {string} NAtributo Determina el tipo de atributo que desea obtener
* @param {string} IElemento Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ISede] Código de la empresa en la que se calculará el saldo.
*/
export async function atributoPreciosElemento(NAtributo: string, IElemento: string, IBodega: string, IEmp: string, ISede: string): Promise<string | number> {
	let nMethod = "AtributoPreciosElemento";
	let params = "";
	try {
		NAtributo = ISData.getNAtributoPrecioElementoDefault(NAtributo);
		params += "NAtributo=" + (NAtributo);
		IBodega = _IBodega(IBodega);
		params += "&IBodega=" + (IBodega);
		IElemento = _IElemento(IElemento);
		params += "&IElemento=" + IElemento;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ISede = ISData.getISedeDefault(ISede);
		params += "&ISede=" + ISede;
		if (IElemento == "" || NAtributo == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para mostrar el precio de venta de un elemento del inventario según la lista de precios indicada. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/precioElemento.html
* @param {string} IElemento Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [ILista]  Código de la lista de precios de la cual desea trae el precio del elemento del inventario de ContaPyme.  
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ISede] Código de la empresa en la que se calculará el saldo.
*/
export async function precioElemento(IElemento: string, ILista: string, IEmp: string, ISede: string): Promise<string | number> {
	let nMethod = "PrecioElemento";
	let params = "";
	try {
		IElemento = _IElemento(IElemento);
		params += "IElemento=" + IElemento;
		ILista = ISData.getIListaDefault(ILista);
		params += "&ILista=" + ILista;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ISede = ISData.getISedeDefault(ISede);
		params += "&ISede=" + ISede;
		if (IElemento == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina la cantidad de salidas de un elemento del inventario, registrados en el sistema de inventarios de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadSalidas.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [IElemento] Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
*/
export async function cantidadSalidas(IBodega: string, IElemento: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string): Promise<string | number> {
	let nMethod = "cantidadSalidas";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		IElemento = _IElemento(IElemento);
		params += "&IElemento=" + IElemento;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina la cantidad de entradas de un elemento del inventario con un filtro determinado, registrados en el sistema de inventarios de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadSalidasFiltro.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
*/
export async function cantidadSalidasFiltro(IBodega: string, Filtro: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string): Promise<string | number> {
	let nMethod = "cantidadSalidasFiltro";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina la cantidad de salidas de un elemento del inventario, registrados en el sistema de inventarios de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/costoSalidas.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [IElemento] Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function costoSalidas(IBodega: string, IElemento: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string, BLocal: string): Promise<string | number> {
	let nMethod = "costoSalidas";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		IElemento = _IElemento(IElemento);
		params += "&IElemento=" + IElemento;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el costo de las entradas de un elemento del inventario, a una fecha, con un filtro determinado. Tenga en cueta que esta función se basa en el sistema de inventarios de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/costoSalidasFiltro.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function costoSalidasFiltro(IBodega: string, Filtro: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string, BLocal: string): Promise<string | number> {
	let nMethod = "costoSalidasFiltro";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina la cantidad de ventas de un elemento del inventario en un periodo determinado. Se debe tener en cuenta que esta función se basa en la operación de ventas del módulo de inventarios, compras y facturación de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadVentas.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [IElemento] Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
*/
export async function cantidadVentas(IBodega: string, IElemento: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string, ITercero: string): Promise<string | number> {
	let nMethod = "cantidadVentas";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		IElemento = _IElemento(IElemento);
		params += "&IElemento=" + IElemento;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina la cantidad de ventas de un elemento del inventario con un filtro determinado. Tenga en cuenta que esta función se basa en el sistema de inventarios de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadVentasFiltro.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
*/
export async function cantidadVentasFiltro(IBodega: string, Filtro: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string, ITercero: string): Promise<string | number> {
	let nMethod = "cantidadVentasFiltro";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el costo de venta de un elemento del inventario. Se debe tener en cuenta que esta función se basa en la operación de ventas del módulo de inventarios, compras y facturación de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/costoVentas.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [IElemento] Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function costoVentas(IBodega: string, IElemento: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string, ITercero: string, BLocal: string): Promise<string | number> {
	let nMethod = "costoVentas";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		IElemento = _IElemento(IElemento);
		params += "&IElemento=" + IElemento;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el costo de venta de un elemento del inventario usando un filtro determinado. Se debe tener en cuenta que esta función se basa en la operación de ventas del módulo de inventarios, compras y facturación de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/costoVentasFiltro.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function costoVentasFiltro(IBodega: string, Filtro: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string, ITercero: string, BLocal: string): Promise<string | number> {
	let nMethod = "costoVentasFiltro";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el descuento en la venta de un elemento del inventario. Se debe tener en cuenta que esta función se basa en la operación de ventas del módulo de inventarios, compras y facturación de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/descuentoVentas.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [IElemento] Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function descuentoVentas(IBodega: string, IElemento: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string, ITercero: string, BLocal: string): Promise<string | number> {
	let nMethod = "DescuentoVentas";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		IElemento = _IElemento(IElemento);
		params += "&IElemento=" + IElemento;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el descuento en la venta de un elemento del inventario con un filtro determinado. Se debe tener en cuenta que esta función se basa en la operación de ventas del módulo de inventarios, compras y facturación de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/descuentoVentasFiltro.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function descuentoVentasFiltro(IBodega: string, Filtro: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string, ITercero: string, BLocal: string): Promise<string | number> {
	let nMethod = "DescuentoVentasFiltro";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el ingreso generado en la venta de un elemento del inventario. Se debe tener en cuenta que esta función se basa en la operación de ventas del módulo de inventarios, compras y facturación de ContaPyme® y NO tiene en cuenta el valor del IVA para reportarlo como ingreso. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/ingresosVentas.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [IElemento] Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function ingresosVentas(IBodega: string, IElemento: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string, ITercero: string, BLocal: string): Promise<string | number> {
	let nMethod = "IngresosVentas";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		IElemento = _IElemento(IElemento);
		params += "&IElemento=" + IElemento;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina los ingresos por ventas de un elemento del inventario con un filtro determinado. Tenga en cuenta que esta función, solo calcula los valores base, esto quiere decir que NO toma en cuenta el valor del IVA. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/ingresosVentasFiltro.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function ingresosVentasFiltro(IBodega: string, Filtro: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string, ITercero: string, BLocal: string): Promise<string | number> {
	let nMethod = "IngresosVentasFiltro";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el IVA generado por las ventas de un elemento del inventario. Se debe tener en cuenta que esta función se basa en la operación de ventas del módulo de inventarios, compras y facturación de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/IVAVentas.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [IElemento] Código de uno o varios elementos del inventario de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function IVAVentas(IBodega: string, IElemento: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string, ITercero: string, BLocal: string): Promise<string | number> {
	let nMethod = "IVAVentas";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		IElemento = _IElemento(IElemento);
		params += "&IElemento=" + IElemento;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función determina el IVA generado por las ventas de un elemento del inventario. Se debe tener en cuenta que esta función se basa en la operación de ventas del módulo de inventarios, compras y facturación de ContaPyme®.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/IVAVentasFiltro.html
* @param {string} [IBodega] Código de una o más bodegas creadas en ContaPyme® por las cuales se desea filtrar. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICCProductor] Código de uno o varios centros de costos productores creados en ContaPyme®, por los cuales se desea filtrar. 
* @param {string} [ICCDestino] Código de uno o varios centros de costos creados en ContaPyme® a los cuales se les imputan los consumos. 
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [BLocal] Tipo de contabilización a utilizar para el movimiento.
*/
export async function IVAVentasFiltro(IBodega: string, Filtro: string, FechaIni: string, FechaFin: string, IEmp: string, ICCProductor: string, ICCDestino: string, ITercero: string, BLocal: string): Promise<string | number> {
	let nMethod = "IVAVentasFiltro";
	IBodega = _IBodega(IBodega);
	let params = "IBodega=" + (IBodega);
	try {
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICCProductor = _ICC(ICCProductor);
		if (ICCProductor !== "") {
			params += "&ICCProductor=" + ICCProductor;
		}
		ICCDestino = _ICC(ICCDestino);
		if (ICCDestino !== "") {
			params += "&ICCDestino=" + ICCDestino;
		}
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		BLocal = _BLocal(BLocal);
		if (BLocal !== "") params += "&blocal=" + BLocal;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
//#endregion
//#region LABORES
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Esta función es utilizada para conocer cualquier atributo de una actividad según el código dado. Tenga en cuenta que los atributos son características dadas a cada actividad y se configuran en cada una de ellas.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoActividad.html
 * @param {string} NAtributo Determina el tipo de atributo que desea obtener
 * @param {string} IActividad Código de una actividad creada en el módulo de actividad y labores de ContaPyme®. 
 */
export async function atributoActividad(NAtributo: string, IActividad: string): Promise<string | number> {
	let nMethod = "AtributoActividad";
	let params = "";
	try {
		NAtributo = ISData.getNAtributoLaboresDefault(NAtributo);
		params += "natributo=" + (NAtributo);
		IActividad = _IActividad(IActividad);
		params += "&IActividad=" + (IActividad);
		if (NAtributo == "" || IActividad == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para conocer cualquier atributo de una labor según el código dado. Tenga en cuenta que los atributos son características dadas a cada labor y se configuran en cada una de ellas.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoLabor.html
* @param {string} NAtributo Determina el tipo de atributo que desea obtener
* @param {string} ILabor Código de una labor creada en el módulo de actividad y labores de ContaPyme®. 
* @param {string} IActividad Código de una actividad creada en el módulo de actividad y labores de ContaPyme®. 
*/
export async function atributoLabor(NAtributo: string, ILabor: string, IActividad: string): Promise<string | number> {
	let nMethod = "AtributoLabor";
	let params = "";
	try {
		NAtributo = ISData.getNAtributoLaborDefault(NAtributo);
		params += "natributo=" + (NAtributo);
		ILabor = _ILabor(ILabor);
		params += "&ILabor=" + ILabor;
		IActividad = _IActividad(IActividad);
		params += "&IActividad=" + (IActividad);
		if (NAtributo == "" || IActividad == "" || ILabor == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función suma los usos de un activo en las unidades configuradas por el usuario entre un periodo de tiempo. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadLabor.html
* @param {string} ILabor Código de una o varias labores, las cuales son imputadas en el uso del activo. 
* @param {string} IActividad Código de la actividad a la cual pertenece la labor utilizada en la función. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
*/
export async function cantidadLabor(ILabor: string, IActividad: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string): Promise<string | number> {
	let nMethod = "cantidadLabor"
	let params = "";
	try {
		ILabor = _ILabor(ILabor);
		params += "ILabor=" + ILabor;
		IActividad = _IActividad(IActividad);
		params += "&IActividad=" + (IActividad);
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		if (ILabor == "" || IActividad == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula la cantidad de labor ejecutada de una actividad en específico teniendo en cuenta un filtro determinado. Tenga en cuenta que esta función es basada en el sistema de actividades y labores por lo que solo es utilizada en empresas con el módulo de costos de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadLaborFiltro.html
* @param {string} ILabor Código de una o varias labores, las cuales son imputadas en el uso del activo. 
* @param {string} IActividad Código de la actividad a la cual pertenece la labor utilizada en la función. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
*/
export async function cantidadLaborFiltro(ILabor: string, IActividad: string, Filtro: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string): Promise<string | number> {
	let nMethod = "cantidadLaborFiltro"
	let params = "";
	try {
		ILabor = _ILabor(ILabor);
		params += "ILabor=" + ILabor;
		IActividad = _IActividad(IActividad);
		params += "&IActividad=" + (IActividad);
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		if (ILabor == "" || IActividad == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el tiempo que fue usado para realizar las actividades creadas en la empresa. Tenga en cuenta que esta función es basada en el sistema de actividades y labores por lo que solo es utilizada en empresas con el módulo de costos de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadManoDeObraCC.html
* @param {string} NUnidadMdO Unidad de tiempo por la cuál desea filtrar
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
*/
export async function cantidadManoDeObraCC(NUnidadMdO: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string): Promise<string | number> {
	let nMethod = "cantidadManoDeObraCC"
	let params = "";
	try {
		NUnidadMdO = ISData.getNUnidadMdODefault(NUnidadMdO);
		params += "NUnidadMdO=" + NUnidadMdO;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		if (NUnidadMdO == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el tiempo que fue usado para realizar una labor determina. Tenga en cuenta que esta función es basada en el sistema de actividades y labores por lo que solo es utilizada en empresas con el módulo de costos de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadManoDeObraLabor.html
* @param {string} [ILabor] Código de una o varias labores, las cuales son imputadas en el uso del activo. 
* @param {string} [IActividad] Código de la actividad a la cual pertenece la labor utilizada en la función. 
* @param {string} [NUnidad] Unidad de tiempo por la cuál desea filtrar*
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
*/
export async function cantidadManoDeObraLabor(ILabor: string, IActividad: string, NUnidad: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string): Promise<string | number> {
	let nMethod = "cantidadManoDeObraLabor"
	let params = "";
	try {
		ILabor = _ILabor(ILabor);
		params += "ILabor=" + ILabor;
		IActividad = _IActividad(IActividad);
		params += "&IActividad=" + (IActividad);
		NUnidad = ISData.getNUnidadMdODefault(NUnidad);
		params += "&NUnidad=" + NUnidad;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		if (IActividad == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula la cantidad de recurso que fue usado para realizar una labor determina. Tenga en cuenta que esta función es basada en el sistema de actividades y labores por lo que solo es utilizada en empresas con el módulo de costos de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/NoDisponible.html
* @param {string} IElemento  Código de un elemento del catálogo de elementos de inventario de ContaPyme®.  
* @param {string} ILabor Código de una o varias labores, las cuales son imputadas en el uso del activo. 
* @param {string} IActividad Código de la actividad a la cual pertenece la labor utilizada en la función. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
*/
export async function CantidadRecursoLabor(IElemento: string, ILabor: string, IActividad: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string): Promise<string | number> {
	let nMethod = "CantidadRecursoLabor"
	let params = "";
	try {
		IElemento = _IElemento(IElemento);
		params += "IElemento=" + IElemento;
		ILabor = _ILabor(ILabor);
		params += "&ILabor=" + ILabor;
		IActividad = _IActividad(IActividad);
		params += "&IActividad=" + (IActividad);
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		if (IElemento == "" || ILabor == "" || IActividad == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula la cantidad producida 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/NoDisponible.html
* @param {string} [ILabor] Código de una o varias labores, las cuales son imputadas en el uso del activo. 
* @param {string} [IActividad] Código de la actividad a la cual pertenece la labor utilizada en la función. 
* @param {string} [NProducto] Unidad de producto
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
*/
export async function cantidadProducidaCC(ILabor: string, IActividad: string, NProducto: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string): Promise<string | number> {
	let nMethod = "cantidadProducidaCC"
	let params = "";
	try {
		ILabor = _ILabor(ILabor);
		params += "ILabor=" + ILabor;
		IActividad = _IActividad(IActividad);
		params += "&IActividad=" + (IActividad);
		NProducto = ISData.getNProductoDefault(NProducto);
		params += "&NProducto=" + NProducto;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		if (IActividad == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula la cantidad producida 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/NoDisponible.html
* @param {string} [NProducto] Unidad de producto
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
*/
export async function cantidadProducidaLabor(NProducto: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string): Promise<string | number> {
	let nMethod = "cantidadProducidaLabor"
	let params = "";
	try {
		NProducto = ISData.getNProductoDefault(NProducto);
		params += "NProducto=" + NProducto;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		if (NProducto == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula la cantidad producida de un producto, en un periodo de tiempo determinado
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadProducida.html
* @param {string} [NProducto] Unidad de producto
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
*/
export async function cantidadProducida(NProducto: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string): Promise<string | number> {
	let nMethod = "cantidadProducida"
	let params = "";
	try {
		NProducto = ISData.getNProductoDefault(NProducto);
		params += "NProducto=" + NProducto;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		if (NProducto == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el costo de realizar una labor determina. Tenga en cuenta que esta función es basada en ContaPyme® de actividades y labores por lo que solo es utilizada en empresas con el módulo de costos de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/costoLabor.html
* @param {string} [ILabor] Código de una o varias labores, las cuales son imputadas en el uso del activo. 
* @param {string} [IActividad] Código de la actividad a la cual pertenece la labor utilizada en la función. 
* @param {string} [ITipoRecurso] Código del tipo de recurso por el cual se quiere filtrar. 1 - Mano de obra , 2 - Elemento de control, 3 - Uso de maquinaria
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
*/
export async function costoLabor(ILabor: string, IActividad: string, ITipoRecurso: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string): Promise<string | number> {
	let nMethod = "costoLabor"
	let params = "";
	try {
		ILabor = _ILabor(ILabor);
		params += "ILabor=" + ILabor;
		IActividad = _IActividad(IActividad);
		params += "&IActividad=" + (IActividad);
		ITipoRecurso = ISData.getITipoRecursoDefault(ITipoRecurso);
		params += "&ITipoRecurso=" + ITipoRecurso;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		if (IActividad == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función muestra la fecha del día domingo de la semana en que finalizó una labor, según una fecha de referencia.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/fechaFinUltimaVezLabor.html
* @param {string} [ILabor] Código de una o varias labores, las cuales son imputadas en el uso del activo. 
* @param {string} [Fecha] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
*/
export async function fechaFinUltimaVezLabor(ILabor: string, Fecha: string, IEmp: string, ICC: string): Promise<string | number> {
	let nMethod = "FechaFinUltimaVezLabor"
	let params = "";
	try {
		ILabor = _ILabor(ILabor);
		params += "ILabor=" + ILabor;
		Fecha = await _FechaFin(Fecha);
		params += "&Fecha=" + Fecha;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		if (ILabor == "" || IEmp == "" || ICC == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params, "fecha");
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función muestra la fecha del día lunes de la semana en que inició una labor, según una fecha de referencia.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/fechaIniUltimaVezLabor.html
* @param {string} [ILabor] Código de una o varias labores, las cuales son imputadas en el uso del activo. 
* @param {string} [Fecha] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
*/
export async function fechaIniUltimaVezLabor(ILabor: string, Fecha: string, IEmp: string, ICC: string): Promise<string | number> {
	let nMethod = "FechaIniUltimaVezLabor"
	let params = "";
	try {
		ILabor = _ILabor(ILabor);
		params += "ILabor=" + ILabor;
		Fecha = await _FechaFin(Fecha);
		params += "&Fecha=" + Fecha;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		if (ILabor == "" || IEmp == "" || ICC == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params, "fecha");
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función muestra la fecha del día domingo de la semana en que finalizó una labor, según una fecha de referencia.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/fechaFinUltimaVezLaborActividad.html
* @param {string} [ILabor] Código de una o varias labores, las cuales son imputadas en el uso del activo. 
* @param {string} [IActividad] Código de la actividad a la cual pertenece la labor utilizada en la función. 
* @param {string} [Fecha] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
*/
export async function fechaFinUltimaVezLaborActividad(ILabor: string, IActividad: string, Fecha: string, IEmp: string, ICC: string): Promise<string | number> {
	let nMethod = "FechaFinUltimaVezLaborActividad"
	let params = "";
	try {
		ILabor = _ILabor(ILabor);
		params += "ILabor=" + ILabor;
		IActividad = _IActividad(IActividad);
		params += "&IActividad=" + (IActividad);
		Fecha = await _FechaFin(Fecha);
		params += "&Fecha=" + Fecha;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		if (ILabor == "" || IEmp == "" || ICC == "" || IActividad == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params, "fecha");
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función muestra la fecha del día lunes de la semana en que inició una labor, según una fecha de referencia.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/fechaIniUltimaVezLaborActividad.html
* @param {string} [ILabor] Código de una o varias labores, las cuales son imputadas en el uso del activo. 
* @param {string} [IActividad] Código de la actividad a la cual pertenece la labor utilizada en la función. 
* @param {string} [Fecha] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
*/
export async function fechaIniUltimaVezLaborActividad(ILabor: string, IActividad: string, Fecha: string, IEmp: string, ICC: string): Promise<string | number> {
	let nMethod = "FechaIniUltimaVezLaborActividad"
	let params = "";
	try {
		ILabor = _ILabor(ILabor);
		params += "ILabor=" + ILabor;
		IActividad = _IActividad(IActividad);
		params += "&IActividad=" + (IActividad);
		Fecha = await _FechaFin(Fecha);
		params += "&Fecha=" + Fecha;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		if (ILabor == "" || IEmp == "" || ICC == "" || IActividad == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params, "fecha");
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el tiempo que fue usado para realizar una labor determina. Tenga en cuenta que esta función es basada en el sistema de actividades y labores por lo que solo es utilizada en empresas con el módulo de costos de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/numeroVecesLabor.html
* @param {string} [ILabor] Código de una o varias labores, las cuales son imputadas en el uso del activo. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
*/
export async function numeroVecesLabor(ILabor: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string): Promise<string | number> {
	let nMethod = "NumeroVecesLabor"
	let params = "";
	try {
		ILabor = _ILabor(ILabor);
		params += "ILabor=" + ILabor;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		if (ILabor == "" || IEmp == "" || ICC == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el tiempo que fue usado para realizar una labor determina. Tenga en cuenta que esta función es basada en el sistema de actividades y labores por lo que solo es utilizada en empresas con el módulo de costos de ContaPyme®. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/numeroVecesLaborActividad.html
* @param {string} [ILabor] Código de una o varias labores, las cuales son imputadas en el uso del activo. 
* @param {string} [IActividad] Código de la actividad a la cual pertenece la labor utilizada en la función. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
* @param {string} [ICC] Código de una sede o centro de costos en el que se calculará el saldo
*/
export async function numeroVecesLaborActividad(ILabor: string, IActividad: string, FechaIni: string, FechaFin: string, IEmp: string, ICC: string): Promise<string | number> {
	let nMethod = "NumeroVecesLaborActividad"
	let params = "";
	try {
		ILabor = _ILabor(ILabor);
		params += "ILabor=" + ILabor;
		IActividad = _IActividad(IActividad);
		params += "&IActividad=" + (IActividad);
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		ICC = _ICC(ICC);
		if (ICC !== "") params += "&icc=" + ICC;
		if (ILabor == "" || IEmp == "" || ICC == "" || IActividad == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
//#endregion
//#region TERCEROS
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Esta función es utilizada para conocer cualquier atributo de un tercero según el código dado. Tenga en cuenta que los atributos son características dadas a cada tercero y se configuran desde el catálogo de terceros de ContaPyme®.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoTercero.html
 * @param {string} NAtributo Determina el tipo de atributo que desea obtener
 * @param {string} ITercero Código de un tercero creado en el catálogo de Terceros en ContaPyme®. 
 */
export async function atributoTercero(NAtributo: string, ITercero: string): Promise<string | number> {
	let nMethod: string = "AtributoTercero";
	let params: string = "";
	try {
		ITercero = _ITercero(ITercero);
		params += "ITercero=" + ITercero;
		NAtributo = ISData.getNAtributoTerceroDefault(NAtributo);
		params += "&NAtributo=" + (NAtributo);
		if (!isBool(ITercero) || !isBool(NAtributo)) throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para mostrar el número de celular de un tercero, según el código dado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/celularTercero.html
* @param {string} ITercero Código de un tercero creado en el catálogo de Terceros en ContaPyme®. 
*/
export async function celularTercero(ITercero: string): Promise<string | number> {
	let nMethod = "CelularTercero";
	let params = "";
	try {
		ITercero = _IElemento(ITercero);
		params += "ITercero=" + ITercero;
		if (ITercero == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para mostrar la ciudad principal de un tercero, según el código dado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/ciudadTercero.html
* @param {string} ITercero Código de un tercero creado en el catálogo de Terceros en ContaPyme®. 
*/
export async function ciudadTercero(ITercero: string): Promise<string | number> {
	let nMethod = "CiudadTercero";
	let params = "";
	try {
		ITercero = _IElemento(ITercero);
		params += "ITercero=" + ITercero;
		if (ITercero == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para contar los terceros según un filtro determinado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/contarTercerosFiltro.html
* @param {string} [ITercero] Código de un tercero creado en el catálogo de Terceros en ContaPyme®. 
* @param {string} [Filtro] Filtro que se desea aplicar a la función 
*/
export async function contarTercerosFiltro(ITercero: string, Filtro: string): Promise<string | number> {
	let nMethod = "ContarTercerosFiltro";
	let params = "";
	try {
		ITercero = _IElemento(ITercero);
		params += "ITercero=" + ITercero;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para mostrar la direccion de un tercero, según el código dado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/direccionTercero.html
* @param {string} ITercero Código de un tercero creado en el catálogo de Terceros en ContaPyme®. 
*/
export async function direccionTercero(ITercero: string): Promise<string | number> {
	let nMethod = "DireccionTercero";
	let params = "";
	try {
		ITercero = _IElemento(ITercero);
		params += "ITercero=" + ITercero;
		if (ITercero == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para mostrar el email de un tercero, según el código dado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/emailTercero.html
* @param {string} ITercero Código de un tercero creado en el catálogo de Terceros en ContaPyme®. 
*/
export async function emailTercero(ITercero: string): Promise<string | number> {
	let nMethod = "EmailTercero";
	let params = "";
	try {
		ITercero = _IElemento(ITercero);
		params += "ITercero=" + ITercero;
		if (ITercero == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para determinar la fecha de nacimiento de un tercero, según el código dado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/nacimientoTercero.html
* @param {string} ITercero Código de un tercero creado en el catálogo de Terceros en ContaPyme®. 
*/
export async function nacimientoTercero(ITercero: string): Promise<string | number> {
	let nMethod = "NacimientoTercero";
	let params = "";
	try {
		ITercero = _IElemento(ITercero);
		params += "ITercero=" + ITercero;
		if (ITercero == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params, "fecha");
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para determinar el nombre comercial de un tercero, según el código dado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/nombreComercialTercero.html
* @param {string} ITercero Código de un tercero creado en el catálogo de Terceros en ContaPyme®. 
*/
export async function nombreComercialTercero(ITercero: string): Promise<string | number> {
	let nMethod = "NombreComercialTercero";
	let params = "";
	try {
		ITercero = _IElemento(ITercero);
		params += "ITercero=" + ITercero;
		if (ITercero == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para mostrar el nombre de un tercero, según el código dado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/nombreTercero.html
* @param {string} ITercero Código de un tercero creado en el catálogo de Terceros en ContaPyme®. 
*/
export async function nombreTercero(ITercero: string): Promise<string | number> {
	let nMethod = "NombreTercero";
	let params = "";
	try {
		ITercero = _IElemento(ITercero);
		params += "ITercero=" + ITercero;
		if (ITercero == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para determinar el país de un tercero, según el código dado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/paisTercero.html
* @param {string} ITercero Código de un tercero creado en el catálogo de Terceros en ContaPyme®. 
*/
export async function paisTercero(ITercero: string): Promise<string | number> {
	let nMethod = "PaisTercero";
	let params = "";
	try {
		ITercero = _IElemento(ITercero);
		params += "ITercero=" + ITercero;
		if (ITercero == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para determinar el sexo de un tercero, según el código dado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/sexoTercero.html
* @param {string} ITercero Código de un tercero creado en el catálogo de Terceros en ContaPyme®. 
*/
export async function sexoTercero(ITercero: string): Promise<string | number> {
	let nMethod = "SexoTercero";
	let params = "";
	try {
		ITercero = _IElemento(ITercero);
		params += "ITercero=" + ITercero;
		if (ITercero == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para determinar el teléfono principal de un tercero. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/telefonoTercero.html
* @param {string} ITercero Código de un tercero creado en el catálogo de Terceros en ContaPyme®. 
*/
export async function telefonoTercero(ITercero: string): Promise<string | number> {
	let nMethod = "TelefonoTercero";
	let params = "";
	try {
		ITercero = _IElemento(ITercero);
		params += "ITercero=" + ITercero;
		if (ITercero == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para mostrar el id del tipo de documento de un tercero. Tenga en cuenta que el usuario puede modificar el id del tipo de documento desde la tabla de títulos "tipos de documentos". 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/tipoDocumentoTercero.html
* @param {string} ITercero Código de un tercero creado en el catálogo de Terceros en ContaPyme®. 
*/
export async function tipoDocumentoTercero(ITercero: string): Promise<string | number> {
	let nMethod = "TipoDocumentoTercero";
	let params = "";
	try {
		ITercero = _IElemento(ITercero);
		params += "ITercero=" + ITercero;
		if (ITercero == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para mostrar cuál es el debido tratamiento para un tercero, según el código dado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/tratamientoTercero.html
* @param {string} ITercero Código de un tercero creado en el catálogo de Terceros en ContaPyme®. 
*/
export async function tratamientoTercero(ITercero: string): Promise<string | number> {
	let nMethod = "TratamientoTercero";
	let params = "";
	try {
		ITercero = _IElemento(ITercero);
		params += "ITercero=" + ITercero;
		if (ITercero == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
//#endregion
//#region CONTRATOS Y VARIOS
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Esta función es utilizada para contar las operaciones que se han creado en ContaPyme según los filtros dados en sus parámetros.
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/ContarOprPorTipoFiltro.html
 * @param {string} ITipoOperacion Código que identifica el tipo de operación a la que pertenece una operación. 
 * @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
 * @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
 * @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
 * @param {string} [Filtro] Filtro que se desea aplicar a la función
 */
export async function ContarOprPorTipoFiltro(ITipoOperacion: string, FechaIni: string, FechaFin: string, IEmp: string, Filtro: string): Promise<string | number> {
	let nMethod = "ContarOprPorTipoFiltro"
	let params = "";
	try {
		ITipoOperacion = _ILabor(ITipoOperacion);
		params += "ITipoOperacion=" + ITipoOperacion;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		if (ITipoOperacion == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Esta función es utilizada para contar las operaciones que se han creado en ContaPyme según los filtros dados en sus parámetros. Permite principalmente filtrar las operaciones por el tipo documento soporte con el cual fueron creadas
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/ContarOprPorDocFiltro.html
 * @param {string} ITipoDocumento Código que identifica el tipo de operación a la que pertenece una operación. 
 * @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
 * @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
 * @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
 * @param {string} [Filtro] Filtro que se desea aplicar a la función
 */
export async function ContarOprPorDocFiltro(ITipoDocumento: string, FechaIni: string, FechaFin: string, IEmp: string, Filtro: string): Promise<string | number> {
	let nMethod = "ContarOprPorDocFiltro";
	let params = "";
	try {
		ITipoDocumento = _ILabor(ITipoDocumento);
		params += "ITipoDocumento=" + ITipoDocumento;
		Filtro = _Filtro(Filtro);
		if (Filtro !== "") params += "&filtro=" + Filtro;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		if (ITipoDocumento == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función es utilizada para mostrar el texto de un número escrito. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/montoEscrito.html
* @param {string} Valor Código de una o varias labores, las cuales son imputadas en el uso del activo. 
* @param {string} [NMoneda] Código de la actividad a la cual pertenece la labor utilizada en la función. 
* @param {string} [NCentavos] Determina la fecha donde realiza el cálculo.
*/
export async function montoEscrito(Valor: string, NMoneda: string, NCentavos: string): Promise<string | number> {
	let nMethod = "MontoEscrito"
	let params = "";
	try {
		Valor = ISData.getIValorMontoEscritoDefault(Valor);
		params += "Valor=" + Valor;
		NMoneda = ISData.getNMonedaDefault(NMoneda);
		params += "&NMoneda=" + (NMoneda);
		NCentavos = ISData.getNCentavosDefault(NCentavos);
		params += "&NCentavos=" + NCentavos;
		if (Valor == "") throw ErrorParam
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula la cantidad de mano de obra pagada en un periodo de tiempo determinado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadManoDeObraPagadaContratos.html
* @param {string} [ITDContrato] Código del tipo de contrato por el cual se desea filtrar
* @param {string} [IContrato]   Código o número del contrato por el cual se quiere filtrar.  
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
*/
export async function cantidadManoDeObraPagadaContratos(ITDContrato: string, IContrato: string, ITercero: string, FechaIni: string, FechaFin: string, IEmp: string): Promise<string | number> {
	let nMethod = "cantidadManoDeObraPagadaContratos";
	let params = "";
	try {			// Manejo de los datos ingresados
		ITDContrato = _ITDContrato(ITDContrato);
		params = "ITDContrato=" + (ITDContrato);
		IContrato = _IContrato(IContrato);
		params += "&IContrato=" + IContrato;
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el costo de mano de obra pagada en un periodo de tiempo determinado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/costoManoDeObraPagadaContratos.html
* @param {string} [ITDContrato] Código del tipo de contrato por el cual se desea filtrar
* @param {string} [IContrato]   Código o número del contrato por el cual se quiere filtrar.  
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
*/
export async function costoManoDeObraPagadaContratos(ITDContrato: string, IContrato: string, ITercero: string, FechaIni: string, FechaFin: string, IEmp: string): Promise<string | number> {
	let nMethod = "costoManoDeObraPagadaContratos";
	let params = "";
	try {			// Manejo de los datos ingresados
		ITDContrato = _ITDContrato(ITDContrato);
		params = "ITDContrato=" + (ITDContrato);
		IContrato = _IContrato(IContrato);
		params += "&IContrato=" + IContrato;
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula la cantidad de obra o labor pagada en un periodo de tiempo determinado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/cantidadDeObraPagadaContratos.html
* @param {string} [ITDContrato] Código del tipo de contrato por el cual se desea filtrar
* @param {string} [IContrato]   Código o número del contrato por el cual se quiere filtrar.  
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
*/
export async function cantidadDeObraPagadaContratos(ITDContrato: string, IContrato: string, ITercero: string, FechaIni: string, FechaFin: string, IEmp: string): Promise<string | number> {
	let nMethod = "cantidadDeObraPagadaContratos";
	let params = "";
	try {			// Manejo de los datos ingresados
		ITDContrato = _ITDContrato(ITDContrato);
		params = "ITDContrato=" + (ITDContrato);
		IContrato = _IContrato(IContrato);
		params += "&IContrato=" + IContrato;
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el costo por la cantidad de obra pagada en un periodo de tiempo determinado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/costocantidadDeObraPagadaContratos.html
* @param {string} [ITDContrato] Código del tipo de contrato por el cual se desea filtrar
* @param {string} [IContrato]   Código o número del contrato por el cual se quiere filtrar.  
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
*/
export async function costocantidadDeObraPagadaContratos(ITDContrato: string, IContrato: string, ITercero: string, FechaIni: string, FechaFin: string, IEmp: string): Promise<string | number> {
	let nMethod = "costocantidadDeObraPagadaContratos";
	let params = "";
	try {			// Manejo de los datos ingresados
		ITDContrato = _ITDContrato(ITDContrato);
		params = "ITDContrato=" + (ITDContrato);
		IContrato = _IContrato(IContrato);
		params += "&IContrato=" + IContrato;
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el costo pagado por los contratos realizados en un periodo de tiempo determinado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/costoTotalPagadoContratos.html
* @param {string} [ITDContrato] Código del tipo de contrato por el cual se desea filtrar
* @param {string} [IContrato]   Código o número del contrato por el cual se quiere filtrar.  
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
*/
export async function costoTotalPagadoContratos(ITDContrato: string, IContrato: string, ITercero: string, FechaIni: string, FechaFin: string, IEmp: string): Promise<string | number> {
	let nMethod = "costoTotalPagadoContratos";
	let params = "";
	try {			// Manejo de los datos ingresados
		ITDContrato = _ITDContrato(ITDContrato);
		params = "ITDContrato=" + (ITDContrato);
		IContrato = _IContrato(IContrato);
		params += "&IContrato=" + IContrato;
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el total de los descuentos pagados por salud de los contratos realizados en un periodo de tiempo determinado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/descuento1Contratos.html
* @param {string} [ITDContrato] Código del tipo de contrato por el cual se desea filtrar
* @param {string} [IContrato]   Código o número del contrato por el cual se quiere filtrar.  
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
*/
export async function descuento1Contratos(ITDContrato: string, IContrato: string, ITercero: string, FechaIni: string, FechaFin: string, IEmp: string): Promise<string | number> {
	let nMethod = "Descuento1Contratos";
	let params = "";
	try {			// Manejo de los datos ingresados
		ITDContrato = _ITDContrato(ITDContrato);
		params = "ITDContrato=" + (ITDContrato);
		IContrato = _IContrato(IContrato);
		params += "&IContrato=" + IContrato;
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el total de los descuentos pagados por pensión de los contratos realizados en un periodo de tiempo determinado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/descuento2Contratos.html
* @param {string} [ITDContrato] Código del tipo de contrato por el cual se desea filtrar
* @param {string} [IContrato]   Código o número del contrato por el cual se quiere filtrar.  
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
*/
export async function descuento2Contratos(ITDContrato: string, IContrato: string, ITercero: string, FechaIni: string, FechaFin: string, IEmp: string): Promise<string | number> {
	let nMethod = "Descuento2Contratos";
	let params = "";
	try {			// Manejo de los datos ingresados
		ITDContrato = _ITDContrato(ITDContrato);
		params = "ITDContrato=" + (ITDContrato);
		IContrato = _IContrato(IContrato);
		params += "&IContrato=" + IContrato;
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el total de los descuentos pagados por ReteFuente de los contratos realizados en un periodo de tiempo determinado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/descuento3Contratos.html
* @param {string} [ITDContrato] Código del tipo de contrato por el cual se desea filtrar
* @param {string} [IContrato]   Código o número del contrato por el cual se quiere filtrar.  
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
*/
export async function descuento3Contratos(ITDContrato: string, IContrato: string, ITercero: string, FechaIni: string, FechaFin: string, IEmp: string): Promise<string | number> {
	let nMethod = "Descuento3Contratos";
	let params = "";
	try {			// Manejo de los datos ingresados
		ITDContrato = _ITDContrato(ITDContrato);
		params = "ITDContrato=" + (ITDContrato);
		IContrato = _IContrato(IContrato);
		params += "&IContrato=" + IContrato;
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el total pagado por otros descuentos de los contratos realizados en un periodo de tiempo determinado. 
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/descuento4Contratos.html
* @param {string} [ITDContrato] Código del tipo de contrato por el cual se desea filtrar
* @param {string} [IContrato]   Código o número del contrato por el cual se quiere filtrar.  
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
*/
export async function descuento4Contratos(ITDContrato: string, IContrato: string, ITercero: string, FechaIni: string, FechaFin: string, IEmp: string): Promise<string | number> {
	let nMethod = "Descuento4Contratos";
	let params = "";
	try {			// Manejo de los datos ingresados
		ITDContrato = _ITDContrato(ITDContrato);
		params = "ITDContrato=" + (ITDContrato);
		IContrato = _IContrato(IContrato);
		params += "&IContrato=" + IContrato;
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo. Esta función calcula el total a pagar por los contratos realizados en un periodo de tiempo determinado.
* @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/totalAPagarContratos.html
* @param {string} [ITDContrato] Código del tipo de contrato por el cual se desea filtrar
* @param {string} [IContrato]   Código o número del contrato por el cual se quiere filtrar.  
* @param {string} [ITercero] Código de uno o varios terceros del catálogo de terceros de ContaPyme® por los cuales se desea filtrar. 
* @param {string} [FechaIni] Determina la fecha donde realiza el cálculo.
* @param {string} [FechaFin] Determina la fecha donde realiza el cálculo.
* @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
*/
export async function totalAPagarContratos(ITDContrato: string, IContrato: string, ITercero: string, FechaIni: string, FechaFin: string, IEmp: string): Promise<string | number> {
	let nMethod = "TotalAPagarContratos";
	let params = "";
	try {			// Manejo de los datos ingresados
		ITDContrato = _ITDContrato(ITDContrato);
		params = "ITDContrato=" + (ITDContrato);
		IContrato = _IContrato(IContrato);
		params += "&IContrato=" + IContrato;
		ITercero = _ITercero(ITercero);
		params += "&itercero=" + ITercero;
		FechaIni = _FechaIni(FechaIni);
		params += "&fechaini=" + FechaIni;
		FechaFin = await _FechaFin(FechaFin);
		params += "&fechafin=" + FechaFin;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
//#endregion
//#region METODOS MULTIPLE RESPUESTA
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Con esta formular podra consultar el extracto de movimientos de cuentas
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/NoDisponible.html
 * @param {string} [FechaIni] Fecha Inicial del movimiento por la cual se desea filtrar
 * @param {string} [FechaFin] Fecha Final del movimiento por la cual se desea filtrar
 * @param {string} [CamposAgrupadores] Campos agrupadores por los cuales se desea filtrar
 * @param {string} [CamposDeAgregado] Campos de agregado por los cuales se desea filtrar
 * @param {string} [DatosFiltro] Valores de filtro por los cuales se desea filtrar
 * @param {string} [CamposOrden] Campos de orden por los cuales se desea agrupar la respuesta
 * @returns {string[][]} Extracto movimiento de cuentas
 */
export async function ExtractoMovCuentas(FechaIni: string, FechaFin: string, CamposAgrupadores: string, CamposDeAgregado: string, DatosFiltro: string, CamposOrden: string) {
	FechaFin = await _FechaFin(FechaFin);
	return new Promise((resolve) => {
		try {
			let nombreTabla: keyof typeof ISCamposExtracto = "CNTMOVUN";
			FechaIni = _FechaIni(FechaIni);
			let valores = ISCamposExtracto[nombreTabla];
			let arregloCamposAgrupadores = valores.agrupadores;
			let arregloCamposDeAgregado = valores.agregados;
			if (CamposAgrupadores !== null && CamposAgrupadores !== "") {
				if (CamposAgrupadores == "*") 
					CamposAgrupadores = arregloCamposAgrupadores.join(",");
				 else {
					CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadores);
				}
				//CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadores);
				CamposDeAgregado = validarAgregado(CamposDeAgregado, arregloCamposDeAgregado);
				CamposOrden = validarDatosValoresInformes(CamposOrden, arregloCamposAgrupadores);
				if (DatosFiltro == null)
					DatosFiltro = "";
			} else {
				return resolve([[E1]]);
			}
			return resolve(generarSQL(FechaIni, FechaFin, CamposAgrupadores, CamposDeAgregado, DatosFiltro, CamposOrden, nombreTabla, "web"));
		} catch (error) {
			saveError("ExtractoMovCuentas", "*", error as Error);
			return resolve([[E1]]);
		}
	})
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Con esta formular podra consultar el extracto de movimientos de inventarios
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/NoDisponible.html
 * @param {string} [FechaIni] Fecha Inicial del movimiento por la cual se desea filtrar
 * @param {string} [FechaFin] Fecha Final del movimiento por la cual se desea filtrar
 * @param {string} [CamposAgrupadores] Campos agrupadores por los cuales se desea filtrar
 * @param {string} [CamposDeAgregado] Campos de agregado por los cuales se desea filtrar
 * @param {string} [DatosFiltro] Valores de filtro por los cuales se desea filtrar
 * @param {string} [CamposOrden] Campos de orden por los cuales se desea agrupar la respuesta
 * @returns {string[][]} Extracto movimiento de inventarios
 */
export async function ExtractoMovInventarios(FechaIni: string, FechaFin: string, CamposAgrupadores: string, CamposDeAgregado: string, DatosFiltro: string, CamposOrden: string) {
	try {
		let nombreTabla: keyof typeof ISCamposExtracto = "KARMOVUN";
		FechaIni = _FechaIni(FechaIni);
		FechaFin = await _FechaFin(FechaFin);
		let valores: TJSONObject = ISCamposExtracto[nombreTabla];
		let arregloCamposAgrupadores: any = valores.agrupadores;
		let arregloCamposDeAgregado: any = valores.agregados;
		if (CamposAgrupadores !== null && CamposAgrupadores !== "") {
			if (CamposAgrupadores == "*") {
				CamposAgrupadores = arregloCamposAgrupadores.join(",")
			} else {
				CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadores);
			}
			CamposDeAgregado = validarAgregado(CamposDeAgregado, arregloCamposDeAgregado);
			CamposOrden = validarDatosValoresInformes(CamposOrden, arregloCamposAgrupadores);
			if (DatosFiltro == null)
				DatosFiltro = "";
		} else {
			return [[E1]];
		}
		return generarSQL(FechaIni, FechaFin, CamposAgrupadores, CamposDeAgregado, DatosFiltro, CamposOrden, nombreTabla, "web");
	} catch (error) {
		saveError("ExtractoMovInventarios", "*", error as Error);
		return [[E1]]
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Con esta formular podra consultar el extracto de movimientos de terceros
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/NoDisponible.html
 * @param {string} [CamposAgrupadores] Campos agrupadores por los cuales se desea filtrar
 * @param {string} [Tipo] Tipo de tercero por el cual se desea filtrar
 * @param {string} [CamposDeAgregado] Campos de agregado por los cuales se desea filtrar
 * @param {string} [DatosFiltro] Valores de filtro por los cuales se desea filtrar
 * @param {string} [CamposOrden] Campos de orden por los cuales se desea agrupar la respuesta
 * @returns {string[][]} Extracto movimiento de terceros
 */
export function ExtractoTerceros(CamposAgrupadores: string, Tipo: string, CamposDeAgregado: string, DatosFiltro: string, CamposOrden: string) {
	try {
		let nombreTabla: keyof typeof ISCamposExtracto = "ABANITS";
		let valores = ISCamposExtracto[nombreTabla];
		let arregloCamposAgrupadores = valores.agrupadores;
		let arregloCamposDeAgregado = valores.agregados;
		if (CamposAgrupadores !== null && CamposAgrupadores !== "") {
			if (CamposAgrupadores == "*") {
				CamposAgrupadores = arregloCamposAgrupadores.join(",")
			} else {
				CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadores);
			}
			if (!isBool(DatosFiltro))
				DatosFiltro = "";
			if (isBool(Tipo)) {
				let ArrayTipos = ["CLIENTE", "PROVEEDOR", "VENDEDOR", "EMPLEADO"];
				let tempTipos = "";
				for (let i = 0; i < ArrayTipos.length; i++) {
					if (Tipo.toUpperCase().includes(ArrayTipos[i])) {
						if (tempTipos.length < 2)
							tempTipos += "B" + ArrayTipos[i] + "='T'";
						else
							tempTipos += " AND B" + ArrayTipos[i] + "='T'";
					}
				}
				if (DatosFiltro.length > 1)
					DatosFiltro += " AND ";
				DatosFiltro += tempTipos;
			}
			CamposDeAgregado = validarAgregado(CamposDeAgregado, arregloCamposDeAgregado);
			CamposOrden = validarDatosValoresInformes(CamposOrden, arregloCamposAgrupadores);
			if (DatosFiltro == null)
				DatosFiltro = "";
		} else {
			return [[E1]];
		}
		return generarSQL("", "", CamposAgrupadores, CamposDeAgregado, DatosFiltro, CamposOrden, nombreTabla, "web");
	} catch (error) {
		saveError("ExtractoMovTerceros", "*", error as Error);
		return [[E1]]
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Con esta formular podra consultar el extracto de cuentas
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/NoDisponible.html
 * @param {string} [CamposAgrupadores] Campos agrupadores por los cuales se desea filtrar
 * @param {string} [ICuenta] Cuenta o cuentas del plan de cuentas por la cual se desea filtrar
 * @param {string} [INivel] Nivel por el cual se desea filtrar
 * @param {string} [ITDCuenta] Tipo de cuenta por la cual se desea filtrar
 * @param {string} [BLocal] Tipo de contabilización a utilizar para el filtro (F o T)
 * @param {string} [IClase] Clase por la cual se desea filtrar
 * @param {string} [CamposDeAgregado] Campos de agregado por los cuales se desea filtrar
 * @param {string} [DatosFiltro] Valores de filtro por los cuales se desea filtrar
 * @param {string} [CamposOrden] Campos de orden por los cuales se desea agrupar la respuesta
 * @returns {string[][]} Extracto movimiento de cuentas
 */
export function ExtractoCuentas(CamposAgrupadores: string, ICuenta: string, INivel: string, ITDCuenta: string, BLocal: string, IClase: string, CamposDeAgregado: string, DatosFiltro: string, CamposOrden: string) {
	try {
		let nombreTabla: keyof typeof ISCamposExtracto = "CNTPUC";
		let valores = ISCamposExtracto[nombreTabla];
		let arregloCamposAgrupadores = valores.agrupadores;
		let arregloCamposDeAgregado = valores.agregados;
		if (!isBool(DatosFiltro))
			DatosFiltro = "";
		if (isBool(ICuenta)) {
			if (DatosFiltro.length > 1)
				DatosFiltro += " AND ";
			if (ICuenta.includes("*")) {
				ICuenta = ICuenta.replace("*", "%");
				DatosFiltro += "ICuenta LIKE '" + ICuenta + "' OR NCuenta LIKE '" + ICuenta + "'";
			} else {
				DatosFiltro += "ICuenta='" + ICuenta + "' OR NCuenta='" + ICuenta + "'";
			}
		}
		if (isBool(INivel) && isNumeric(INivel)) {
			if (DatosFiltro.length > 1)
				DatosFiltro += " AND ";
			DatosFiltro += "inivel='" + INivel + "'";
		}
		if (isBool(ITDCuenta) && isNumeric(ITDCuenta)) {
			if (DatosFiltro.length > 1)
				DatosFiltro += " AND ";
			DatosFiltro += "itdcuenta='" + ITDCuenta + "'";
		}
		if (isBool(BLocal)) {
			let datosTemp = "";
			if (DatosFiltro.length > 1)
				datosTemp = " AND ";
			if (BLocal.toUpperCase().trim() == "F") {
				DatosFiltro += datosTemp + "bniif='T' AND blocal='F'";
			} else if (BLocal.toUpperCase().trim() == "T") {
				DatosFiltro += datosTemp + "bniif='T' AND blocal='T'";
			}
		}
		if (isBool(IClase) && isNumeric(IClase)) {
			if (DatosFiltro.length > 1)
				DatosFiltro += " AND ";
			DatosFiltro += "iclase='" + IClase + "'";
		}
		//CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadoresTexto, arregloCamposAgrupadores);
		if (CamposAgrupadores !== null && CamposAgrupadores !== "") {
			if (CamposAgrupadores == "*") {
				CamposAgrupadores = arregloCamposAgrupadores.join(",")
			} else {
				CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadores);
			}
			//CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadores);
			CamposDeAgregado = validarAgregado(CamposDeAgregado, arregloCamposDeAgregado);
			CamposOrden = validarDatosValoresInformes(CamposOrden, arregloCamposAgrupadores);
			if (DatosFiltro == null)
				DatosFiltro = "";
		} else {
			return [[E1]];
		}
		return generarSQL("", "", CamposAgrupadores, CamposDeAgregado, DatosFiltro, CamposOrden, nombreTabla, "web");
	} catch (error) {
		saveError("ExtractoCuentas", "*", error as Error);
		return [[E1]]
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Con esta formular podra consultar el extracto de elementos de inventario
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/NoDisponible.html
 * @param {string} [CamposAgrupadores] Campos agrupadores por los cuales se desea filtrar
 * @param {string} [IRecurso] Recurso o recursos de elementos de inventario por el cual se desea filtrar
 * @param {string} [IGrupoInv] Grupo de inventario por el cual se desea filtrar
 * @param {string} [IDepInv] Departamento o línea por el cual se desea filtrar
 * @param {string} [CamposDeAgregado] Campos de agregado por los cuales se desea filtrar
 * @param {string} [DatosFiltro] Valores de filtro por los cuales se desea filtrar
 * @param {string} [CamposOrden] Campos de orden por los cuales se desea agrupar la respuesta
 * @returns {string[][]} Extracto de elementos de inventario
 */
export function ExtractoElementosInventario(CamposAgrupadores: string, IRecurso: string, IGrupoInv: string, IDepInv: string, CamposDeAgregado: string, DatosFiltro: string, CamposOrden: string) {
	try {
		let nombreTabla: keyof typeof ISCamposExtracto = "INVMREC";
		let valores = ISCamposExtracto[nombreTabla];
		let arregloCamposAgrupadores = valores.agrupadores;
		let arregloCamposDeAgregado = valores.agregados;
		if (DatosFiltro == null)
			DatosFiltro = "";
		if (isBool(IRecurso)) {
			if (DatosFiltro.length > 1)
				DatosFiltro += " AND ";
			if (IRecurso.includes("*")) {
				IRecurso = IRecurso.replace("*", "%");
				DatosFiltro += "IRecurso LIKE '" + IRecurso + "' OR NRecurso LIKE '" + IRecurso + "'";
			} else {
				DatosFiltro += "IRecurso='" + IRecurso + "' OR NRecurso='" + IRecurso + "'";
			}
		}
		if (isBool(IGrupoInv)) {
			if (DatosFiltro.length > 1)
				DatosFiltro += " AND ";
			DatosFiltro += "IGrupoInv='" + IGrupoInv + "'";
		}
		if (isBool(IDepInv)) {
			if (DatosFiltro.length > 1)
				DatosFiltro += " AND ";
			DatosFiltro += "IDepInv='" + IDepInv + "'";
		}
		//CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadoresTexto, arregloCamposAgrupadores);
		if (CamposAgrupadores !== null && CamposAgrupadores !== "") {
			if (CamposAgrupadores == "*") {
				CamposAgrupadores = arregloCamposAgrupadores.join(",")
			} else {
				CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadores);
			}
			//CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadores);
			CamposDeAgregado = validarAgregado(CamposDeAgregado, arregloCamposDeAgregado);
			CamposOrden = validarDatosValoresInformes(CamposOrden, arregloCamposAgrupadores);
			if (DatosFiltro == null)
				DatosFiltro = "";
		} else {
			return [[E1]];
		}
		return generarSQL("", "", CamposAgrupadores, CamposDeAgregado, DatosFiltro, CamposOrden, nombreTabla, "web");
	} catch (error) {
		saveError("ExtractoElementosInventario", "*", error as Error);
		return [[E1]]
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Con esta formular podra consultar el extracto de Bodegas
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/NoDisponible.html
 * @param {string} [CamposAgrupadores] Campos agrupadores por los cuales se desea filtrar
 * @param {string} [NInventario] Lista de bodegas por el cual se desea filtrar
 * @param {string} [CamposDeAgregado] Campos de agregado por los cuales se desea filtrar
 * @param {string} [DatosFiltro] Valores de filtro por los cuales se desea filtrar
 * @param {string} [CamposOrden] Campos de orden por los cuales se desea agrupar la respuesta
 * @returns {string[][]} Extracto de Bodegas
 */
export function ExtractoBodegas(CamposAgrupadores: string, NInventario: string, CamposDeAgregado: string, DatosFiltro: string, CamposOrden: string) {
	try {
		let nombreTabla: keyof typeof ISCamposExtracto = "INVMAEST";
		let valores = ISCamposExtracto[nombreTabla];
		let arregloCamposAgrupadores = valores.agrupadores;
		let arregloCamposDeAgregado = valores.agregados;
		if (DatosFiltro == null)
			DatosFiltro = "";
		if (isBool(NInventario)) {
			if (DatosFiltro.length > 1)
				DatosFiltro += " AND ";
			if (NInventario.includes("*")) {
				NInventario = NInventario.replace("*", "%");
				DatosFiltro += "NInventario LIKE '" + NInventario + "' OR IInventario LIKE '" + NInventario + "'";
			} else {
				DatosFiltro += "NInventario='" + NInventario + "' OR IInventario='" + NInventario + "'";
			}
		}
		//CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadoresTexto, arregloCamposAgrupadores);
		if (CamposAgrupadores !== null && CamposAgrupadores !== "") {
			if (CamposAgrupadores == "*") {
				CamposAgrupadores = arregloCamposAgrupadores.join(",")
			} else {
				CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadores);
			}
			//CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadores);
			CamposDeAgregado = validarAgregado(CamposDeAgregado, arregloCamposDeAgregado);
			CamposOrden = validarDatosValoresInformes(CamposOrden, arregloCamposAgrupadores);
			if (DatosFiltro == null)
				DatosFiltro = "";
		} else {
			return [[E1]];
		}
		return generarSQL("", "", CamposAgrupadores, CamposDeAgregado, DatosFiltro, CamposOrden, nombreTabla, "web");
	} catch (error) {
		saveError("ExtractoBodegas", "*", error as Error);
		return [[E1]]
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Con esta formular podra consultar el extracto de Departamentos/Lineas
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/NoDisponible.html
 * @param {string} [CamposAgrupadores] Campos agrupadores por los cuales se desea filtrar
 * @param {string} [NDepInv] Lista de Departamentos/Lineas por el cual se desea filtrar
 * @param {string} [CamposDeAgregado] Campos de agregado por los cuales se desea filtrar
 * @param {string} [DatosFiltro] Valores de filtro por los cuales se desea filtrar
 * @param {string} [CamposOrden] Campos de orden por los cuales se desea agrupar la respuesta
 * @returns {string[][]} Extracto de Departamentos/Lineas
 */
export function ExtractoDeptosLineas(CamposAgrupadores: string, NDepInv: string, CamposDeAgregado: string, DatosFiltro: string, CamposOrden: string) {
	try {
		let nombreTabla: keyof typeof ISCamposExtracto = "ABAMDEPINV";
		let valores = ISCamposExtracto[nombreTabla];
		let arregloCamposAgrupadores = valores.agrupadores;
		let arregloCamposDeAgregado = valores.agregados;
		if (DatosFiltro == null)
			DatosFiltro = "";
		if (isBool(NDepInv)) {
			if (DatosFiltro.length > 1)
				DatosFiltro += " AND ";
			if (NDepInv.includes("*")) {
				NDepInv = NDepInv.replace("*", "%");
				DatosFiltro += "NDepInv LIKE '" + NDepInv + "' OR IDepInv LIKE '" + NDepInv + "'";
			} else {
				DatosFiltro += "NDepInv='" + NDepInv + "' OR IDepInv='" + NDepInv + "'";
			}
		}
		//CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadoresTexto, arregloCamposAgrupadores);
		if (CamposAgrupadores !== null && CamposAgrupadores !== "") {
			if (CamposAgrupadores == "*") {
				CamposAgrupadores = arregloCamposAgrupadores.join(",")
			} else {
				CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadores);
			}
			//CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadores);
			CamposDeAgregado = validarAgregado(CamposDeAgregado, arregloCamposDeAgregado);
			CamposOrden = validarDatosValoresInformes(CamposOrden, arregloCamposAgrupadores);
			if (DatosFiltro == null)
				DatosFiltro = "";
		} else {
			return [[E1]];
		}
		return generarSQL("", "", CamposAgrupadores, CamposDeAgregado, DatosFiltro, CamposOrden, nombreTabla, "web");
	} catch (error) {
		saveError("ExtractoDeptosLineas", "*", error as Error);
		return [[E1]]
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Con esta formular podra consultar el extracto de Grupos de inventario
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/NoDisponible.html
 * @param {string} [CamposAgrupadores] Campos agrupadores por los cuales se desea filtrar
 * @param {string} [NGrupo] Lista de Grupos de inventario por el cual se desea filtrar
 * @param {string} [CamposDeAgregado] Campos de agregado por los cuales se desea filtrar
 * @param {string} [DatosFiltro] Valores de filtro por los cuales se desea filtrar
 * @param {string} [CamposOrden] Campos de orden por los cuales se desea agrupar la respuesta
 * @returns {string[][]} Extracto de Grupos de inventario
 */
export function ExtractoGruposInventario(CamposAgrupadores: string, NGrupo: string, CamposDeAgregado: string, DatosFiltro: string, CamposOrden: string) {
	try {
		let nombreTabla: keyof typeof ISCamposExtracto = "INVMGRUPO";
		let valores = ISCamposExtracto[nombreTabla];
		let arregloCamposAgrupadores = valores.agrupadores;
		let arregloCamposDeAgregado = valores.agregados;
		if (DatosFiltro == null)
			DatosFiltro = "";
		if (isBool(NGrupo)) {
			if (DatosFiltro.length > 1)
				DatosFiltro += " AND ";
			if (NGrupo.includes("*")) {
				NGrupo = NGrupo.replace("*", "%");
				DatosFiltro += "IGRUPOINV LIKE '" + NGrupo + "' OR NGrupo LIKE '" + NGrupo + "'";
			} else {
				DatosFiltro += "IGRUPOINV='" + NGrupo + "' OR NGrupo='" + NGrupo + "'";
			}
		}
		//CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadoresTexto, arregloCamposAgrupadores);
		if (CamposAgrupadores !== null && CamposAgrupadores !== "") {
			if (CamposAgrupadores == "*") {
				CamposAgrupadores = arregloCamposAgrupadores.join(",")
			} else {
				CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadores);
			}
			//CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadores);
			CamposDeAgregado = validarAgregado(CamposDeAgregado, arregloCamposDeAgregado);
			CamposOrden = validarDatosValoresInformes(CamposOrden, arregloCamposAgrupadores);
			if (DatosFiltro == null)
				DatosFiltro = "";
		} else {
			return [[E1]];
		}
		return generarSQL("", "", CamposAgrupadores, CamposDeAgregado, DatosFiltro, CamposOrden, nombreTabla, "web");
	} catch (error) {
		saveError("ExtractoGruposInventario", "*", error as Error);
		return [[E1]]
	}
}
/** 
* Nota: Debe agregarse "CP." a todas las formulas como prefijo.  Con esta formular podra consultar el extracto de Centros de costos
 * @customfunction
* @helpurl https://contaexcel.blob.core.windows.net/ayudas/NoDisponible.html
 * @param {string} [CamposAgrupadores] Campos agrupadores por los cuales se desea filtrar
 * @param {string} [NCC] Lista de centros de costos por el cual se desea filtrar
 * @param {string} [ITDCC] Clases de centros de costos por el cual se desea filtrar
 * @param {string} [CamposDeAgregado] Campos de agregado por los cuales se desea filtrar
 * @param {string} [DatosFiltro] Valores de filtro por los cuales se desea filtrar
 * @param {string} [CamposOrden] Campos de orden por los cuales se desea agrupar la respuesta
 * @returns {string[][]} Extracto de Centros de costos
 */
export function ExtractoCentrosdeCostos(CamposAgrupadores: string, NCC: string, ITDCC: string, CamposDeAgregado: string, DatosFiltro: string, CamposOrden: string) {
	try {
		let nombreTabla: keyof typeof ISCamposExtracto = "EMPMAESTCC";
		let valores = ISCamposExtracto[nombreTabla];
		let arregloCamposAgrupadores = valores.agrupadores;
		let arregloCamposDeAgregado = valores.agregados;
		if (DatosFiltro == null)
			DatosFiltro = "";
		if (isBool(NCC)) {
			if (DatosFiltro.length > 1)
				DatosFiltro += " AND ";
			if (NCC.includes("*")) {
				NCC = NCC.replace("*", "%");
				DatosFiltro += "NCC LIKE '" + NCC + "' OR ICC LIKE '" + NCC + "'";
			} else {
				DatosFiltro += "NCC='" + NCC + "' OR ICC='" + NCC + "'";
			}
		}
		if (isBool(ITDCC) && isNumeric(ITDCC)) {
			if (DatosFiltro.length > 1)
				DatosFiltro += " AND ";
			DatosFiltro += "ITDCC='" + ITDCC + "'";
		}
		//CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadoresTexto, arregloCamposAgrupadores);
		if (CamposAgrupadores !== null && CamposAgrupadores !== "") {
			if (CamposAgrupadores == "*") CamposAgrupadores = arregloCamposAgrupadores.join(",")
			else CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadores);
			//CamposAgrupadores = validarDatosValoresInformes(CamposAgrupadores, arregloCamposAgrupadores);
			CamposDeAgregado = validarAgregado(CamposDeAgregado, arregloCamposDeAgregado);
			CamposOrden = validarDatosValoresInformes(CamposOrden, arregloCamposAgrupadores);
			if (DatosFiltro == null)
				DatosFiltro = "";
		} else {
			return [[E1]];
		}
		return generarSQL("", "", CamposAgrupadores, CamposDeAgregado, DatosFiltro, CamposOrden, nombreTabla, "web");
	} catch (error) {
		saveError("ExtractoCentrosdeCostos", "*", error as Error);
		return [[E1]]
	}
}
//#endregion
//#region FUNCIONES DE NÓMINA

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {string} date1 fecha inicial
 * @param {string} date2 fecha final
 */
export async function MesesEntre(date1: string, date2: string): Promise<string | number> {
	let nMethod = "MesesEntre";
	let params = "";
	try {
		date1 = _FechaIni(date1);
		params += "date1=" + date1;
		date2 = await _FechaFin(date2);
		params += "&date2=" + date2;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {string} date1 fecha inicial
 * @param {string} date2 fecha final
 */
export async function DiasEntre(date1: string, date2: string): Promise<string | number> {
	let nMethod = "DiasEntre";
	let params = "";
	try {
		date1 = _FechaIni(date1);
		params += "date1=" + date1;
		date2 = await _FechaFin(date2);
		params += "&date2=" + date2;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {string} date1 fecha inicial
 * @param {string} date2 fecha final
 */
export async function Dias360(date1: string, date2: string): Promise<string | number> {
	let nMethod = "Dias360";
	let params = "";
	try {
		date1 = _FechaIni(date1);
		params += "date1=" + date1;
		date2 = await _FechaFin(date2);
		params += "&date2=" + date2;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {string} date1 fecha inicial
 * @param {string} date2 fecha final
 */
export async function MaxDate(date1: string, date2: string): Promise<string | number> {
	let nMethod = "MaxDate";
	let params = "";
	try {
		date1 = _FechaIni(date1);
		params += "date1=" + date1;
		date2 = await _FechaFin(date2);
		params += "&date2=" + date2;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {string} date fecha 
 * @param {number} qInc fecha final
 */
export async function IncDias360(date: string, qInc: number): Promise<string | number> {
	let nMethod = "IncDias360";
	let params = "";
	try {
		date = await _FechaFin(date);
		params += "date=" + date;
		params += "&qInc=" + qInc;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {string} date fecha 
 * @param {number} qInc fecha final
 */
export async function IncMeses(date: string, qInc: number): Promise<string | number> {
	let nMethod = "IncMeses";
	let params = "";
	try {
		date = await _FechaFin(date);
		params += "date=" + date;
		params += "&qInc=" + qInc;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {string} date fecha 
 * @param {number} qInc fecha final
 */
export async function IncAnios(date: string, qInc: number): Promise<string | number> {
	let nMethod = "IncAnios";
	let params = "";
	try {
		date = await _FechaFin(date);
		params += "date=" + date;
		params += "&qInc=" + qInc;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {number} val fecha final
 */
export async function RedondeoArriba(val: number): Promise<string | number> {
	let nMethod = "RedondeoArriba";
	let params = "";
	try {
		params += "val=" + val;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {number} val fecha final
 */
export async function RedondeoAbajo(val: number): Promise<string | number> {
	let nMethod = "RedondeoAbajo";
	let params = "";
	try {
		params += "val=" + val;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {number} val fecha final
 */
export async function Redondeo(val: number): Promise<string | number> {
	let nMethod = "Redondeo";
	let params = "";
	try {
		params += "val=" + val;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {string} [date] fecha 
 */
export async function FIniAnio(date: string): Promise<string | number> {
	let nMethod = "FIniAnio";
	let params = "";
	try {
		date = await _FechaFin(date);
		params += "date=" + date;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {string} [date] fecha 
 */
export async function FFinAnio(date: string): Promise<string | number> {
	let nMethod = "FFinAnio";
	let params = "";
	try {
		date = await _FechaFin(date);
		params += "date=" + date;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {string} [date] fecha 
 */
export async function FIniSemestre(date: string): Promise<string | number> {
	let nMethod = "FIniSemestre";
	let params = "";
	try {
		date = await _FechaFin(date);
		params += "date=" + date;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {string} [date] fecha 
 */
export async function FFinSemestre(date: string): Promise<string | number> {
	let nMethod = "FFinSemestre";
	let params = "";
	try {
		date = await _FechaFin(date);
		params += "date=" + date;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {string} [date] fecha 
 */
export async function FIniMesAnterior(date: string): Promise<string | number> {
	let nMethod = "FIniMesAnterior";
	let params = "";
	try {
		date = await _FechaFin(date);
		params += "date=" + date;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {string} [date] fecha 
 */
export async function FFinMesAnterior(date: string): Promise<string | number> {
	let nMethod = "FFinMesAnterior";
	let params = "";
	try {
		date = await _FechaFin(date);
		params += "date=" + date;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {string} [date] fecha 
 */
export async function FIniMes(date: string): Promise<string | number> {
	let nMethod = "FIniMes";
	let params = "";
	try {
		date = await _FechaFin(date);
		params += "date=" + date;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {string} [date] fecha 
 */
export async function FFinMes(date: string): Promise<string | number> {
	let nMethod = "FFinMes";
	let params = "";
	try {
		date = await _FechaFin(date);
		params += "date=" + date;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {string} [date] fecha 
 */
export async function DiasDe(date: string): Promise<string | number> {
	let nMethod = "DiasDe";
	let params = "";
	try {
		date = await _FechaFin(date);
		params += "date=" + date;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {string} ITipoDocumento ITipoDocumento
 * @param {string} NAtributo NAtributo 
 */
export async function AtributoTipoDocumento(ITipoDocumento: string, NAtributo: string): Promise<string | number> {
	let nMethod = "AtributoTipoDocumento";
	let params = "";
	try {
		params += "ITipoDocumento=" + ITipoDocumento;
		params += "&NAtributo=" + NAtributo;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {string} [date] fecha 
 */
export async function SMMLV(date: string): Promise<string | number> {
	let nMethod = "SMMLV";
	let params = "";
	try {
		date = await _FechaFin(date);
		params += "date=" + date;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {string} [date] fecha 
 */
export async function SMDLV(date: string): Promise<string | number> {
	let nMethod = "SMDLV";
	let params = "";
	try {
		date = await _FechaFin(date);
		params += "date=" + date;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return await fetchCE(nMethod, params)
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoActivo.html
 * @param {string} IEmpleado Determina el tipo de atributo que desea obtener
 * @param {string} FReferencia Código de un activo creado en el catálogo de activos de ContaPyme®. 
 * @param {string} IContrato Determina la fecha donde realiza el cálculo.
 * @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
 */
export async function FIniContrato(IEmpleado: string, FReferencia: string, IContrato: string, IEmp: string): Promise<string | number> {
	let nMethod = "FIniContrato";
	let params = "";
	try {
		IEmpleado = _ITercero(IEmpleado);
		params += "IEmpleado=" + IEmpleado;
		FReferencia = await _FechaFin(FReferencia);
		params += "&FReferencia=" + FReferencia;
		params += "&IContrato=" + IContrato;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoActivo.html
 * @param {string} IEmpleado Determina el tipo de atributo que desea obtener
 * @param {string} FReferencia Código de un activo creado en el catálogo de activos de ContaPyme®. 
 * @param {string} IContrato Determina la fecha donde realiza el cálculo.
 * @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
 */
export async function FFinContrato(IEmpleado: string, FReferencia: string, IContrato: string, IEmp: string): Promise<string | number> {
	let nMethod = "FFinContrato";
	let params = "";
	try {
		IEmpleado = _ITercero(IEmpleado);
		params += "IEmpleado=" + IEmpleado;
		FReferencia = await _FechaFin(FReferencia);
		params += "&FReferencia=" + FReferencia;
		params += "&IContrato=" + IContrato;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoActivo.html
 * @param {string} IEmpleado Determina el tipo de atributo que desea obtener
 * @param {string} FReferencia Código de un activo creado en el catálogo de activos de ContaPyme®. 
 * @param {string} IContrato Determina la fecha donde realiza el cálculo.
 * @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
 */
export async function DiasPeriodoPago(IEmpleado: string, FReferencia: string, IContrato: string, IEmp: string): Promise<string | number> {
	let nMethod = "DiasPeriodoPago";
	let params = "";
	try {
		IEmpleado = _ITercero(IEmpleado);
		params += "IEmpleado=" + IEmpleado;
		FReferencia = await _FechaFin(FReferencia);
		params += "&FReferencia=" + FReferencia;
		params += "&IContrato=" + IContrato;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoActivo.html
 * @param {string} IEmpleado Determina el tipo de atributo que desea obtener
 * @param {string} FReferencia Código de un activo creado en el catálogo de activos de ContaPyme®. 
 * @param {string} IContrato Determina la fecha donde realiza el cálculo.
 * @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
 */
export async function IntensidadHorariaDiariaContrato(IEmpleado: string, FReferencia: string, IContrato: string, IEmp: string): Promise<string | number> {
	let nMethod = "IntensidadHorariaDiariaContrato";
	let params = "";
	try {
		IEmpleado = _ITercero(IEmpleado);
		params += "IEmpleado=" + IEmpleado;
		FReferencia = await _FechaFin(FReferencia);
		params += "&FReferencia=" + FReferencia;
		params += "&IContrato=" + IContrato;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoActivo.html
 * @param {string} IEmpleado Determina el tipo de atributo que desea obtener
 * @param {string} FReferencia Código de un activo creado en el catálogo de activos de ContaPyme®. 
 * @param {string} IContrato Determina la fecha donde realiza el cálculo.
 * @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
 */
export async function SalarioBasicoDia(IEmpleado: string, FReferencia: string, IContrato: string, IEmp: string): Promise<string | number> {
	let nMethod = "SalarioBasicoDia";
	let params = "";
	try {
		IEmpleado = _ITercero(IEmpleado);
		params += "IEmpleado=" + IEmpleado;
		FReferencia = await _FechaFin(FReferencia);
		params += "&FReferencia=" + FReferencia;
		params += "&IContrato=" + IContrato;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoActivo.html
 * @param {string} IEmpleado Determina el tipo de atributo que desea obtener
 * @param {string} FReferencia Código de un activo creado en el catálogo de activos de ContaPyme®. 
 * @param {string} IContrato Determina la fecha donde realiza el cálculo.
 * @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
 */
export async function SalarioBasicoHora(IEmpleado: string, FReferencia: string, IContrato: string, IEmp: string): Promise<string | number> {
	let nMethod = "SalarioBasicoHora";
	let params = "";
	try {
		IEmpleado = _ITercero(IEmpleado);
		params += "IEmpleado=" + IEmpleado;
		FReferencia = await _FechaFin(FReferencia);
		params += "&FReferencia=" + FReferencia;
		params += "&IContrato=" + IContrato;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoActivo.html
 * @param {string} IEmpleado Determina el tipo de atributo que desea obtener
 * @param {string} FReferencia Código de un activo creado en el catálogo de activos de ContaPyme®. 
 * @param {string} IContrato Determina la fecha donde realiza el cálculo.
 * @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
 */
export async function ApoyoSostenimientoDia(IEmpleado: string, FReferencia: string, IContrato: string, IEmp: string): Promise<string | number> {
	let nMethod = "ApoyoSostenimientoDia";
	let params = "";
	try {
		IEmpleado = _ITercero(IEmpleado);
		params += "IEmpleado=" + IEmpleado;
		FReferencia = await _FechaFin(FReferencia);
		params += "&FReferencia=" + FReferencia;
		params += "&IContrato=" + IContrato;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoActivo.html
 * @param {string} IEmpleado Determina el tipo de atributo que desea obtener
 * @param {string} FReferencia Código de un activo creado en el catálogo de activos de ContaPyme®. 
 * @param {string} IContrato Determina la fecha donde realiza el cálculo.
 * @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
 */
export async function ApoyoSostenimientoHora(IEmpleado: string, FReferencia: string, IContrato: string, IEmp: string): Promise<string | number> {
	let nMethod = "ApoyoSostenimientoHora";
	let params = "";
	try {
		IEmpleado = _ITercero(IEmpleado);
		params += "IEmpleado=" + IEmpleado;
		FReferencia = await _FechaFin(FReferencia);
		params += "&FReferencia=" + FReferencia;
		params += "&IContrato=" + IContrato;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoActivo.html
 * @param {string} IEmpleado Determina el tipo de atributo que desea obtener
 * @param {string} FReferencia Código de un activo creado en el catálogo de activos de ContaPyme®. 
 * @param {string} IContrato Determina la fecha donde realiza el cálculo.
 * @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
 */
export async function DiasPeriodoLiquidar(IEmpleado: string, FReferencia: string, IContrato: string, IEmp: string): Promise<string | number> {
	let nMethod = "DiasPeriodoLiquidar";
	let params = "";
	try {
		IEmpleado = _ITercero(IEmpleado);
		params += "IEmpleado=" + IEmpleado;
		FReferencia = await _FechaFin(FReferencia);
		params += "&FReferencia=" + FReferencia;
		params += "&IContrato=" + IContrato;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoActivo.html
 * @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
 */
export async function FechaInicioNomina(IEmp: string): Promise<string | number> {
	let nMethod = "FechaInicioNomina";
	let params = "";
	try {
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoActivo.html
 * @param {string} NAtributo Determina el tipo de atributo que desea obtener
 * @param {string} IConcepto Determina el tipo de atributo que desea obtener
 * @param {string} [FReferencia] Código de un activo creado en el catálogo de activos de ContaPyme®. 
 */
export async function AtributoConceptoNomina(NAtributo: string, IConcepto: string, FReferencia: string): Promise<string | number> {
	let nMethod = "AtributoConceptoNomina";
	let params = "";
	try {
		params += "NAtributo=" + NAtributo;
		params += "&IConcepto=" + IConcepto;
		FReferencia = await _FechaFin(FReferencia);
		params += "&FReferencia=" + FReferencia;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoActivo.html
 * @param {string} NVariable Determina el tipo de atributo que desea obtener
 * @param {string} [FReferencia] Código de un activo creado en el catálogo de activos de ContaPyme®. 
 */
export async function VariableNomina(NVariable: string, FReferencia: string): Promise<string | number> {
	let nMethod = "VariableNomina";
	let params = "";
	try {
		params += "NVariable=" + NVariable;
		FReferencia = await _FechaFin(FReferencia);
		params += "&FReferencia=" + FReferencia;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoActivo.html
 * @param {string} NVarDetalle Determina el tipo de atributo que desea obtener
 * @param {string} FIni Código de un activo creado en el catálogo de activos de ContaPyme®. 
 * @param {string} FFin Código de un activo creado en el catálogo de activos de ContaPyme®. 
 * @param {string} BIncluyePeriodoActual Determina el tipo de atributo que desea obtener
 * @param {string} IEmpleado Determina el tipo de atributo que desea obtener
 * @param {string} IContrato Determina la fecha donde realiza el cálculo.
 * @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
 */
export async function MovNominaValor(NVarDetalle: string, FIni: string, FFin: string, BIncluyePeriodoActual: string, IEmpleado: string, IContrato: string, IEmp: string): Promise<string | number> {
	let nMethod = "MovNominaValor";
	let params = "";
	try {
		params += "NVarDetalle=" + NVarDetalle;
		FIni = _FechaIni(FIni)
		params += "&FIni=" + FIni;
		FFin = await _FechaFin(FFin);
		params += "&FFin=" + FFin;
		params += "&BIncluyePeriodoActual=" + BIncluyePeriodoActual;
		IEmpleado = _ITercero(IEmpleado);
		params += "&IEmpleado=" + IEmpleado;
		params += "&IContrato=" + IContrato;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/pendinete.html
 * @param {string} NVarDetalle 
 * @param {string} bAfectaDias 
 * @param {string} FIni 
 * @param {string} FFin 
 * @param {string} BIncluyePeriodoActual 
 * @param {string} IEmpleado 
 * @param {string} IContrato 
 * @param {string} [IEmp] 
 */
export async function MovNominaCantidad(NVarDetalle: string, bAfectaDias: string, FIni: string, FFin: string, BIncluyePeriodoActual: string, IEmpleado: string, IContrato: string, IEmp: string): Promise<string | number> {
	let nMethod = "MovNominaCantidad";
	let params = "";
	try {
		params += "NVarDetalle=" + NVarDetalle;
		params += "&bAfectaDias=" + bAfectaDias;
		FIni = _FechaIni(FIni)
		params += "&FIni=" + FIni;
		FFin = await _FechaFin(FFin);
		params += "&FFin=" + FFin;
		params += "&BIncluyePeriodoActual=" + BIncluyePeriodoActual;
		IEmpleado = _ITercero(IEmpleado);
		params += "&IEmpleado=" + IEmpleado;
		params += "&IContrato=" + IContrato;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}

/** 
 * @customfunction
 * @helpurl https://contaexcel.blob.core.windows.net/ayudas/atributoActivo.html
 * @param {string} FIni Código de un activo creado en el catálogo de activos de ContaPyme®. 
 * @param {string} FFin Código de un activo creado en el catálogo de activos de ContaPyme®. 
 * @param {string} bDias360 Determina el tipo de atributo que desea obtener
 * @param {string} IEmpleado Determina el tipo de atributo que desea obtener
 * @param {string} IContrato Determina la fecha donde realiza el cálculo.
 * @param {string} [IEmp] Código de la empresa en la que se calculará el saldo.
 */
export async function DiasContrato(FIni: string, FFin: string, bDias360: string, IEmpleado: string, IContrato: string, IEmp: string): Promise<string | number> {
	let nMethod = "DiasContrato";
	let params = "";
	try {

		FIni = _FechaIni(FIni)
		params += "FIni=" + FIni;
		FFin = await _FechaFin(FFin);
		params += "&FFin=" + FFin;
		params += "&BIncluyePeriodoActual=" + bDias360;
		IEmpleado = _ITercero(IEmpleado);
		params += "&IEmpleado=" + IEmpleado;
		params += "&IContrato=" + IContrato;
		IEmp = await _IEmp(IEmp);
		params += "&iemp=" + IEmp;
		//Se realiza la peticion al ExecuteFunctionCE con los datos necesarios
		return fetchCE(nMethod, params);
	} catch (error) {
		saveError(nMethod, params, error as Error);
		return E1;
	}
}
//#endregion
CustomFunctions.associate("GETSTORAGE", getstorage);
CustomFunctions.associate("SETSTORAGE", setstorage);
CustomFunctions.associate("GETKEYSSTORAGE", getkeysstorage);
CustomFunctions.associate("DELETEKEYSSTORAGE", deletekeysstorage);
CustomFunctions.associate("ATRIBUTOACTIVO", atributoActivo);
CustomFunctions.associate("CANTIDADUSOACTIVO", cantidadUsoActivo);
CustomFunctions.associate("CANTIDADUSOACTIVOFILTRO", cantidadUsoActivoFiltro);
CustomFunctions.associate("COSTOHISTORICOACTIVO", costoHistoricoActivo);
CustomFunctions.associate("DEPRECIACIONACTIVO", depreciacionActivo);
CustomFunctions.associate("FALTAPORDEPRECIARACTIVO", faltaPorDepreciarActivo);
CustomFunctions.associate("VALORHOYACTIVO", valorHoyActivo);
CustomFunctions.associate("CARTERAPOREDAD", carteraPorEdad);
CustomFunctions.associate("CARTERAPOREDADFECHA", carteraPorEdadFecha);
CustomFunctions.associate("MOVCARTERATERCERO", movCarteraTercero);
CustomFunctions.associate("MOVCARTERATERCEROFILTRO", movCarteraTerceroFiltro);
CustomFunctions.associate("SALDOCARTERATERCERO", saldoCarteraTercero);
CustomFunctions.associate("SALDOCARTERATERCEROFILTRO", saldoCarteraTerceroFiltro);
CustomFunctions.associate("ATRIBUTOSEDE", atributoSede);
CustomFunctions.associate("ATRIBUTOEMPRESA", atributoEmpresa);
CustomFunctions.associate("ATRIBUTOCC", atributoCC);
CustomFunctions.associate("DATOANALISISSUELOS", DatoAnalisisSuelos);
CustomFunctions.associate("CANTIDADAREAM2CC", cantidadAreaM2CC);
CustomFunctions.associate("CANTIDADAREAUNIDADCC", cantidadAreaUnidadCC);
CustomFunctions.associate("NOMBRECC", nombreCC);
CustomFunctions.associate("POBLACIONCC", poblacionCC);
CustomFunctions.associate("UNIDADAREACC", unidadAreaCC);
CustomFunctions.associate("UNIDADPOBLACIONCC", unidadPoblacionCC);
CustomFunctions.associate("CONTARASIENTOSEJECPPTALFILTRO", contarAsientosEjecPptalFiltro);
CustomFunctions.associate("MOVEJECPPTAL", movEjecPptal);
CustomFunctions.associate("MOVEJECPPTALFILTRO", movEjecPptalFiltro);
CustomFunctions.associate("SALDOEJECPPTAL", saldoEjecPptal);
CustomFunctions.associate("SALDOEJECPPTALFILTRO", saldoEjecPptalFiltro);
CustomFunctions.associate("ATRIBUTOCUENTA", atributoCuenta);
CustomFunctions.associate("CONTARASIENTOSCONTABLESFILTRO", contarAsientosContablesFiltro);
CustomFunctions.associate("CONTARCUENTASFILTRO", contarCuentasFiltro);
CustomFunctions.associate("IDCUENTA", idCuenta);
CustomFunctions.associate("IDCUENTAALTERNA", idCuentaAlterna);
CustomFunctions.associate("IDTIPOCUENTA", idTipoCuenta);
CustomFunctions.associate("MANEJAVALORBASE", manejaValorBase);
CustomFunctions.associate("NATURALEZACUENTA", naturalezaCuenta);
CustomFunctions.associate("NOMBRECUENTA", nombreCuenta);
CustomFunctions.associate("NOMBRECUENTAALTERNA", nombreCuentaAlterna);
CustomFunctions.associate("NOMBRETIPOCUENTA", nombreTipoCuenta);
CustomFunctions.associate("MOVCONTABILIDADDCFILTRO", movContabilidadDCFiltro);
CustomFunctions.associate("MOVCONTABILIDADFILTRO", movContabilidadFiltro);
CustomFunctions.associate("MOVCUENTA", movCuenta);
CustomFunctions.associate("MOVCUENTADC", movCuentaDC);
CustomFunctions.associate("MOVCUENTADCFILTRO", movCuentaDCFiltro);
CustomFunctions.associate("MOVCUENTAFILTRO", movCuentaFiltro);
CustomFunctions.associate("MOVCUENTAMONEDAEXTRANJERA", movCuentaMonedaExtranjera);
CustomFunctions.associate("MOVCUENTAMONEDAEXTRANJERAFILTRO", movCuentaMonedaExtranjeraFiltro);
CustomFunctions.associate("MOVCUENTATERCERO", movCuentaTercero);
CustomFunctions.associate("MOVCUENTAVALOR1", movCuentaValor1);
CustomFunctions.associate("MOVCUENTAVALOR2", movCuentaValor2);
CustomFunctions.associate("MOVCUENTAVALOR1FILTRO", movCuentaValor1Filtro);
CustomFunctions.associate("MOVCUENTAVALOR2FILTRO", movCuentaValor2Filtro);
CustomFunctions.associate("MOVCUENTAVALORBASE", movCuentaValorBase);
CustomFunctions.associate("MOVCUENTAVALORBASEFILTRO", movCuentaValorBaseFiltro);
CustomFunctions.associate("PRESUPUESTO", presupuesto);
CustomFunctions.associate("PRESUPUESTOFILTRO", presupuestoFiltro);
CustomFunctions.associate("PRESUPUESTOPERIODO", presupuestoPeriodo);
CustomFunctions.associate("SALDOCONTABILIDADFILTRO", saldoContabilidadFiltro);
CustomFunctions.associate("SALDOCUENTA", saldoCuenta);
CustomFunctions.associate("SALDOCUENTAFILTRO", saldoCuentaFiltro);
CustomFunctions.associate("SALDOCUENTAMONEDAEXTRANJERA", saldoCuentaMonedaExtranjera);
CustomFunctions.associate("SALDOCUENTAMONEDAEXTRANJERAFILTRO", saldoCuentaMonedaExtranjeraFiltro);
CustomFunctions.associate("SALDOCUENTAVALOR1", saldoCuentaValor1);
CustomFunctions.associate("SALDOCUENTAVALOR2", saldoCuentaValor2);
CustomFunctions.associate("SALDOCUENTAVALOR1FILTRO", saldoCuentaValor1Filtro);
CustomFunctions.associate("SALDOCUENTAVALOR2FILTRO", saldoCuentaValor2Filtro);
CustomFunctions.associate("TASACAMBIO", tasaCambio);
CustomFunctions.associate("EMPRESATRABAJOACTIVA", empresaTrabajoActiva);
CustomFunctions.associate("EMPRESATRABAJOACTIVAMULTI", empresaTrabajoActivaMulti);
CustomFunctions.associate("FECHATRABAJO", fechaTrabajo);
CustomFunctions.associate("NOMBREAREATRABAJOACTIVA", nombreAreaTrabajoActiva);
CustomFunctions.associate("NOMBREEMPRESA", nombreEmpresa);
CustomFunctions.associate("NOMBREEMPRESATRABAJOACTIVA", nombreEmpresaTrabajoActiva);
CustomFunctions.associate("NOMBRETIPODOCUMENTO", nombreTipoDocumento);
CustomFunctions.associate("EDAD", edad);
CustomFunctions.associate("FECHAINICIOSEMANA", fechaInicioSemana);
CustomFunctions.associate("FECHALUNES", fechaLunes);
CustomFunctions.associate("NOMBREMES", nombreMes);
CustomFunctions.associate("NUMEROSEMANA", numeroSemana);
CustomFunctions.associate("PRIMERDIAMES", primerDiaMes);
CustomFunctions.associate("ULTIMODIAMES", ultimoDiaMes);
CustomFunctions.associate("FECHAPARAFILTROS", fechaParaFiltros);
CustomFunctions.associate("FILTROS_AND", filtros_and);
CustomFunctions.associate("FILTROS_OR", filtros_or);
CustomFunctions.associate("FILTROMOVCONT_ACTIVIDAD", filtromovcont_actividad);
CustomFunctions.associate("FILTROMOVCONT_ACTIVO", filtromovcont_activo);
CustomFunctions.associate("FILTROMOVCONT_ANIO", filtromovcont_anio);
CustomFunctions.associate("FILTROMOVCONT_BANCO", filtromovcont_banco);
CustomFunctions.associate("FILTROMOVCONT_CENTRODECOSTOS", filtromovcont_centrodecostos);
CustomFunctions.associate("FILTROMOVCONT_CENTRODECOSTOSORIGEN", filtromovcont_centrodecostosorigen);
CustomFunctions.associate("FILTROMOVCONT_CLASE1", filtromovcont_clase1);
CustomFunctions.associate("FILTROMOVCONT_CLASE2", filtromovcont_clase2);
CustomFunctions.associate("FILTROMOVCONT_CLASEOPERACION", filtromovcont_claseoperacion);
CustomFunctions.associate("FILTROMOVCONT_CUENTA", filtromovcont_cuenta);
CustomFunctions.associate("FILTROMOVCONT_CUENTAFUENTE", filtromovcont_cuentafuente);
CustomFunctions.associate("FILTROMOVCONT_CUENTAPRESUPUESTO", filtromovcont_cuentapresupuesto);
CustomFunctions.associate("FILTROMOVCONT_DIA", filtromovcont_dia);
CustomFunctions.associate("FILTROMOVCONT_DOCUMENTOREFERENCIA", filtromovcont_documentoreferencia);
CustomFunctions.associate("FILTROMOVCONT_EMPRESA", filtromovcont_empresa);
CustomFunctions.associate("FILTROMOVCONT_ESTADO", filtromovcont_estado);
CustomFunctions.associate("FILTROMOVCONT_ETAPA", filtromovcont_etapa);
CustomFunctions.associate("FILTROMOVCONT_FLUJO", filtromovcont_flujo);
CustomFunctions.associate("FILTROMOVCONT_MES", filtromovcont_mes);
CustomFunctions.associate("FILTROMOVCONT_NUMEROCICLODECOSTOS", filtromovcont_numeroCicloDeCostos);
CustomFunctions.associate("FILTROMOVCONT_PERIODO", filtromovcont_periodo);
CustomFunctions.associate("FILTROMOVCONT_PERIODOFECHAPAGO", filtromovcont_periodoFechaPago);
CustomFunctions.associate("FILTROMOVCONT_SEDE", filtromovcont_sede);
CustomFunctions.associate("FILTROMOVCONT_SEMANA", filtromovcont_semana);
CustomFunctions.associate("FILTROMOVCONT_TERCERO", filtromovcont_tercero);
CustomFunctions.associate("FILTROMOVCONT_TERCEROCARTERA", filtromovcont_terceroCartera);
CustomFunctions.associate("FILTROMOVCONT_TIPOSOPORTE", filtromovcont_tipoSoporte);
CustomFunctions.associate("FILTROMOVINV_ACTIVIDAD", filtromovinv_actividad);
CustomFunctions.associate("FILTROMOVINV_ANIO", filtromovinv_anio);
CustomFunctions.associate("FILTROMOVINV_BODEGA", filtromovinv_bodega);
CustomFunctions.associate("FILTROMOVINV_CLASEOPERACION", filtromovinv_claseOperacion);
CustomFunctions.associate("FILTROMOVINV_DIA", filtromovinv_dia);
CustomFunctions.associate("FILTROMOVINV_ELEMENTO", filtromovinv_elemento);
CustomFunctions.associate("FILTROMOVINV_EMPRESA", filtromovinv_empresa);
CustomFunctions.associate("FILTROMOVINV_ESTADO", filtromovinv_estado);
CustomFunctions.associate("FILTROMOVINV_ETAPA", filtromovinv_etapa);
CustomFunctions.associate("FILTROMOVINV_MES", filtromovinv_mes);
CustomFunctions.associate("FILTROMOVINV_NUMEROCICLODECOSTOS", filtromovinv_numeroCicloDeCostos);
CustomFunctions.associate("FILTROMOVINV_PORCENTAJEDESCUENTO", filtromovinv_porcentajeDescuento);
CustomFunctions.associate("FILTROMOVINV_PORCENTAJEIVA", filtromovinv_porcentajeiva);
CustomFunctions.associate("FILTROMOVINV_PORMESA", filtromovinv_pormesa);
CustomFunctions.associate("FILTROMOVINV_SEMANA", filtromovinv_semana);
CustomFunctions.associate("FILTROMOVINV_TERCERO", filtromovinv_tercero);
CustomFunctions.associate("FILTROMOVINV_TIPOPRODUCTO", filtromovinv_tipoProducto);
CustomFunctions.associate("FILTROMOVINV_TIPOSOPORTE", filtromovinv_tiposoporte);
CustomFunctions.associate("FILTROMOVINV_VENDEDOR", filtromovinv_vendedor);
CustomFunctions.associate("FILTROMOVINV_VENDEDOR2", filtromovinv_vendedor2);
CustomFunctions.associate("FILTROCUENTA_IDENTIFICADOR", filtrocuenta_identificador);
CustomFunctions.associate("FILTROCUENTA_SOLOAJUSTESXINF", filtrocuenta_soloAjustesXInf);
CustomFunctions.associate("FILTROCUENTA_SOLOAUTOACTIVABLES", filtrocuenta_soloautoactivables);
CustomFunctions.associate("FILTROCUENTA_SOLOEXIGEACTIVO", filtrocuenta_soloexigeactivo);
CustomFunctions.associate("FILTROCUENTA_SOLOEXIGEBASE", filtrocuenta_soloexigebase);
CustomFunctions.associate("FILTROCUENTA_SOLOEXIGECLASE1", filtrocuenta_soloexigeclase1);
CustomFunctions.associate("FILTROCUENTA_SOLOEXIGECLASE2", filtrocuenta_soloexigeclase2);
CustomFunctions.associate("FILTROCUENTA_SOLOEXIGEVALOR1", filtrocuenta_soloexigevalor1);
CustomFunctions.associate("FILTROCUENTA_SOLOEXIGEVALOR2", filtrocuenta_soloexigevalor2);
CustomFunctions.associate("FILTROCUENTA_SOLOMANEJACARTERA", filtrocuenta_solomanejacartera);
CustomFunctions.associate("FILTROCUENTA_SOLOMANEJACUOTAS", filtrocuenta_solomanejacuotas);
CustomFunctions.associate("FILTROCUENTA_SOLOMANEJAOTRAMONEDA", filtrocuenta_solomanejaotramoneda);
CustomFunctions.associate("FILTROCUENTA_SOLOMANEJATERCERO", filtrocuenta_solomanejatercero);
CustomFunctions.associate("FILTROCUENTA_SOLOTEMPORALES", filtrocuenta_solotemporales);
CustomFunctions.associate("FILTROCUENTA_TIPO", filtrocuenta_tipo);
CustomFunctions.associate("FILTROTERCERO_CATEGORIA", filtrotercero_categoria);
CustomFunctions.associate("FILTROTERCERO_CIUDAD", filtrotercero_ciudad);
CustomFunctions.associate("FILTROTERCERO_CLASE1", filtrotercero_clase1);
CustomFunctions.associate("FILTROTERCERO_CLASE2", filtrotercero_clase2);
CustomFunctions.associate("FILTROTERCERO_CLASIFICACION", filtrotercero_clasificacion);
CustomFunctions.associate("FILTROTERCERO_CLASIFICACIONLEGAL", filtrotercero_clasificacionlegal);
CustomFunctions.associate("FILTROTERCERO_DATO1", filtrotercero_dato1);
CustomFunctions.associate("FILTROTERCERO_DATO2", filtrotercero_dato2);
CustomFunctions.associate("FILTROTERCERO_DEPARTAMENTO", filtrotercero_departamento);
CustomFunctions.associate("FILTROTERCERO_IDENTIFICADOR", filtrotercero_identificador);
CustomFunctions.associate("FILTROTERCERO_PAIS", filtrotercero_pais);
CustomFunctions.associate("FILTROTERCERO_PERFIL", filtrotercero_perfil);
CustomFunctions.associate("FILTROTERCERO_TIPOCLIENTE", filtrotercero_tipocliente);
CustomFunctions.associate("FILTROTERCERO_TIPODOCUMENTO", filtrotercero_tipodocumento);
CustomFunctions.associate("FILTROTERCERO_TIPOEMPLEADO", filtrotercero_tipoempleado);
CustomFunctions.associate("FILTROTERCERO_TIPOPROVEEDOR", filtrotercero_tipoproveedor);
CustomFunctions.associate("FILTROTERCERO_TIPOVENDEDOR", filtrotercero_tipovendedor);
CustomFunctions.associate("FILTROTERCERO_VENDEDOR", filtrotercero_vendedor);
CustomFunctions.associate("FILTROTERCERO_ZONA", filtrotercero_zona);
CustomFunctions.associate("FILTROTERCERO_SOLOCLIENTES", filtrotercero_soloclientes);
CustomFunctions.associate("FILTROTERCERO_SOLOEMPLEADOS", filtrotercero_soloempleados);
CustomFunctions.associate("FILTROTERCERO_SOLOPERSONASJURIDICAS", filtrotercero_solopersonasjuridicas);
CustomFunctions.associate("FILTROTERCERO_SOLOPERSONASNATURALES", filtrotercero_solopersonasnaturales);
CustomFunctions.associate("FILTROTERCERO_SOLOPROVEEDORES", filtrotercero_soloproveedores);
CustomFunctions.associate("FILTROTERCERO_SOLOVENDEDORES", filtrotercero_solovendedores);
CustomFunctions.associate("FILTROSOLOCOMPRAS", filtrosolocompras);
CustomFunctions.associate("FILTROSOLOCONSUMOS", filtrosoloconsumos);
CustomFunctions.associate("FILTROSOLOEGRESOS", filtrosoloegresos);
CustomFunctions.associate("FILTROSOLOEGRESOSPORAJUSTES", filtrosoloegresosporajustes);
CustomFunctions.associate("FILTROSOLOEGRESOSPORDEVOLUCIONESENCOMPRAS", filtrosoloegresospordevolucionesencompras);
CustomFunctions.associate("FILTROSOLOEMBODEGAMIENTOS", filtrosoloembodegamientos);
CustomFunctions.associate("FILTROSOLOINGRESOSPORAJUSTES", filtrosoloingresosporajustes);
CustomFunctions.associate("FILTROSOLOINGRESOSPORDEVOLUCIONESENVENTAS", filtrosoloingresospordevolucionesenventas);
CustomFunctions.associate("FILTROSOLOINGRESOSPORTRASLADOS", filtrosoloingresosportraslados);
CustomFunctions.associate("FILTROSOLOSALDOSINICIALES", filtrosolosaldosiniciales);
CustomFunctions.associate("FILTROSOLOVENTAS", filtrosoloventas);
CustomFunctions.associate("CANTIDADCONSUMOS", cantidadConsumos);
CustomFunctions.associate("CANTIDADCONSUMOSFILTRO", cantidadConsumosFiltro);
CustomFunctions.associate("COSTOCONSUMOS", costoConsumos);
CustomFunctions.associate("COSTOCONSUMOSFILTRO", costoConsumosFiltro);
CustomFunctions.associate("COSTOPROMEDIOELEMENTO", costoPromedioElemento);
CustomFunctions.associate("COSTOPROMEDIOELEMENTOFILTRO", costoPromedioElementoFiltro);
CustomFunctions.associate("CANTIDADENTRADAS", cantidadEntradas);
CustomFunctions.associate("CANTIDADENTRADASFILTRO", cantidadEntradasFiltro);
CustomFunctions.associate("COSTOENTRADAS", costoEntradas);
CustomFunctions.associate("COSTOENTRADASFILTRO", costoEntradasFiltro);
CustomFunctions.associate("CANTIDADEXISTENCIAS", cantidadExistencias);
CustomFunctions.associate("CANTIDADEXISTENCIASFILTRO", cantidadExistenciasFiltro);
CustomFunctions.associate("COSTOEXISTENCIAS", costoExistencias);
CustomFunctions.associate("COSTOEXISTENCIASFILTRO", costoExistenciasFiltro);
CustomFunctions.associate("CANTIDADEXISTENCIASFISICO", cantidadExistenciasFisico);
CustomFunctions.associate("ATRIBUTOBODEGA", atributoBodega);
CustomFunctions.associate("NOMBREBODEGA", nombreBodega);
CustomFunctions.associate("ATRIBUTOELEMENTO", atributoElemento);
CustomFunctions.associate("CONTARELEMENTOSFILTRO", contarElementosFiltro);
CustomFunctions.associate("CONTARMOVIMIENTOSINVENTARIOFILTRO", contarMovimientosInventarioFiltro);
CustomFunctions.associate("DESCRIPCIONELEMENTO", descripcionElemento);
CustomFunctions.associate("NOMBREELEMENTO", nombreElemento);
CustomFunctions.associate("PESOELEMENTO", pesoElemento);
CustomFunctions.associate("STOCKMAX", stockMax);
CustomFunctions.associate("STOCKMIN", stockMin);
CustomFunctions.associate("UNIDADCOMPRAELEMENTO", unidadCompraElemento);
CustomFunctions.associate("UNIDADELEMENTO", unidadElemento);
CustomFunctions.associate("UNIDADPESOELEMENTO", unidadPesoElemento);
CustomFunctions.associate("UNIDADVOLUMENELEMENTO", unidadVolumenElemento);
CustomFunctions.associate("VOLUMENELEMENTO", volumenElemento);
CustomFunctions.associate("ATRIBUTOPRECIOSELEMENTO", atributoPreciosElemento);
CustomFunctions.associate("PRECIOELEMENTO", precioElemento);
CustomFunctions.associate("CANTIDADSALIDAS", cantidadSalidas);
CustomFunctions.associate("CANTIDADSALIDASFILTRO", cantidadSalidasFiltro);
CustomFunctions.associate("COSTOSALIDAS", costoSalidas);
CustomFunctions.associate("COSTOSALIDASFILTRO", costoSalidasFiltro);
CustomFunctions.associate("CANTIDADVENTAS", cantidadVentas);
CustomFunctions.associate("CANTIDADVENTASFILTRO", cantidadVentasFiltro);
CustomFunctions.associate("COSTOVENTAS", costoVentas);
CustomFunctions.associate("COSTOVENTASFILTRO", costoVentasFiltro);
CustomFunctions.associate("DESCUENTOVENTAS", descuentoVentas);
CustomFunctions.associate("DESCUENTOVENTASFILTRO", descuentoVentasFiltro);
CustomFunctions.associate("INGRESOSVENTAS", ingresosVentas);
CustomFunctions.associate("INGRESOSVENTASFILTRO", ingresosVentasFiltro);
CustomFunctions.associate("IVAVENTAS", IVAVentas);
CustomFunctions.associate("IVAVENTASFILTRO", IVAVentasFiltro);
CustomFunctions.associate("ATRIBUTOACTIVIDAD", atributoActividad);
CustomFunctions.associate("ATRIBUTOLABOR", atributoLabor);
CustomFunctions.associate("CANTIDADLABOR", cantidadLabor);
CustomFunctions.associate("CANTIDADLABORFILTRO", cantidadLaborFiltro);
CustomFunctions.associate("CANTIDADMANODEOBRACC", cantidadManoDeObraCC);
CustomFunctions.associate("CANTIDADMANODEOBRALABOR", cantidadManoDeObraLabor);
CustomFunctions.associate("CANTIDADRECURSOLABOR", CantidadRecursoLabor);
CustomFunctions.associate("CANTIDADPRODUCIDACC", cantidadProducidaCC);
CustomFunctions.associate("CANTIDADPRODUCIDALABOR", cantidadProducidaLabor);
CustomFunctions.associate("CANTIDADPRODUCIDA", cantidadProducida);
CustomFunctions.associate("COSTOLABOR", costoLabor);
CustomFunctions.associate("FECHAFINULTIMAVEZLABOR", fechaFinUltimaVezLabor);
CustomFunctions.associate("FECHAINIULTIMAVEZLABOR", fechaIniUltimaVezLabor);
CustomFunctions.associate("FECHAFINULTIMAVEZLABORACTIVIDAD", fechaFinUltimaVezLaborActividad);
CustomFunctions.associate("FECHAINIULTIMAVEZLABORACTIVIDAD", fechaIniUltimaVezLaborActividad);
CustomFunctions.associate("NUMEROVECESLABOR", numeroVecesLabor);
CustomFunctions.associate("NUMEROVECESLABORACTIVIDAD", numeroVecesLaborActividad);
CustomFunctions.associate("ATRIBUTOTERCERO", atributoTercero);
CustomFunctions.associate("CELULARTERCERO", celularTercero);
CustomFunctions.associate("CIUDADTERCERO", ciudadTercero);
CustomFunctions.associate("CONTARTERCEROSFILTRO", contarTercerosFiltro);
CustomFunctions.associate("DIRECCIONTERCERO", direccionTercero);
CustomFunctions.associate("EMAILTERCERO", emailTercero);
CustomFunctions.associate("NACIMIENTOTERCERO", nacimientoTercero);
CustomFunctions.associate("NOMBRECOMERCIALTERCERO", nombreComercialTercero);
CustomFunctions.associate("NOMBRETERCERO", nombreTercero);
CustomFunctions.associate("PAISTERCERO", paisTercero);
CustomFunctions.associate("SEXOTERCERO", sexoTercero);
CustomFunctions.associate("TELEFONOTERCERO", telefonoTercero);
CustomFunctions.associate("TIPODOCUMENTOTERCERO", tipoDocumentoTercero);
CustomFunctions.associate("TRATAMIENTOTERCERO", tratamientoTercero);
CustomFunctions.associate("CONTAROPRPORTIPOFILTRO", ContarOprPorTipoFiltro);
CustomFunctions.associate("CONTAROPRPORDOCFILTRO", ContarOprPorDocFiltro);
CustomFunctions.associate("MONTOESCRITO", montoEscrito);
CustomFunctions.associate("CANTIDADMANODEOBRAPAGADACONTRATOS", cantidadManoDeObraPagadaContratos);
CustomFunctions.associate("COSTOMANODEOBRAPAGADACONTRATOS", costoManoDeObraPagadaContratos);
CustomFunctions.associate("CANTIDADDEOBRAPAGADACONTRATOS", cantidadDeObraPagadaContratos);
CustomFunctions.associate("COSTOCANTIDADDEOBRAPAGADACONTRATOS", costocantidadDeObraPagadaContratos);
CustomFunctions.associate("COSTOTOTALPAGADOCONTRATOS", costoTotalPagadoContratos);
CustomFunctions.associate("DESCUENTO1CONTRATOS", descuento1Contratos);
CustomFunctions.associate("DESCUENTO2CONTRATOS", descuento2Contratos);
CustomFunctions.associate("DESCUENTO3CONTRATOS", descuento3Contratos);
CustomFunctions.associate("DESCUENTO4CONTRATOS", descuento4Contratos);
CustomFunctions.associate("TOTALAPAGARCONTRATOS", totalAPagarContratos);
CustomFunctions.associate("EXTRACTOMOVCUENTAS", ExtractoMovCuentas);
CustomFunctions.associate("EXTRACTOMOVINVENTARIOS", ExtractoMovInventarios);
CustomFunctions.associate("EXTRACTOTERCEROS", ExtractoTerceros);
CustomFunctions.associate("EXTRACTOCUENTAS", ExtractoCuentas);
CustomFunctions.associate("EXTRACTOELEMENTOSINVENTARIO", ExtractoElementosInventario);
CustomFunctions.associate("EXTRACTOBODEGAS", ExtractoBodegas);
CustomFunctions.associate("EXTRACTODEPTOSLINEAS", ExtractoDeptosLineas);
CustomFunctions.associate("EXTRACTOGRUPOSINVENTARIO", ExtractoGruposInventario);
CustomFunctions.associate("EXTRACTOCENTROSDECOSTOS", ExtractoCentrosdeCostos);
CustomFunctions.associate("MESESENTRE", MesesEntre);
CustomFunctions.associate("DIASENTRE", DiasEntre);
CustomFunctions.associate("DIAS360", Dias360);
CustomFunctions.associate("MAXDATE", MaxDate);
CustomFunctions.associate("INCDIAS360", IncDias360);
CustomFunctions.associate("INCMESES", IncMeses);
CustomFunctions.associate("INCANIOS", IncAnios);
CustomFunctions.associate("REDONDEOARRIBA", RedondeoArriba);
CustomFunctions.associate("REDONDEOABAJO", RedondeoAbajo);
CustomFunctions.associate("REDONDEO", Redondeo);
CustomFunctions.associate("FINIANIO", FIniAnio);
CustomFunctions.associate("FFINANIO", FFinAnio);
CustomFunctions.associate("FINISEMESTRE", FIniSemestre);
CustomFunctions.associate("FFINSEMESTRE", FFinSemestre);
CustomFunctions.associate("FINIMESANTERIOR", FIniMesAnterior);
CustomFunctions.associate("FFINMESANTERIOR", FFinMesAnterior);
CustomFunctions.associate("FINIMES", FIniMes);
CustomFunctions.associate("FFINMES", FFinMes);
CustomFunctions.associate("DIASDE", DiasDe);
CustomFunctions.associate("ATRIBUTOTIPODOCUMENTO", AtributoTipoDocumento);
CustomFunctions.associate("SMMLV", SMMLV);
CustomFunctions.associate("SMDLV", SMDLV);
CustomFunctions.associate("FINICONTRATO", FIniContrato);
CustomFunctions.associate("FFINCONTRATO", FFinContrato);
CustomFunctions.associate("DIASPERIODOPAGO", DiasPeriodoPago);
CustomFunctions.associate("INTENSIDADHORARIADIARIACONTRATO", IntensidadHorariaDiariaContrato);
CustomFunctions.associate("SALARIOBASICODIA", SalarioBasicoDia);
CustomFunctions.associate("SALARIOBASICOHORA", SalarioBasicoHora);
CustomFunctions.associate("APOYOSOSTENIMIENTODIA", ApoyoSostenimientoDia);
CustomFunctions.associate("APOYOSOSTENIMIENTOHORA", ApoyoSostenimientoHora);
CustomFunctions.associate("DIASPERIODOLIQUIDAR", DiasPeriodoLiquidar);
CustomFunctions.associate("FECHAINICIONOMINA", FechaInicioNomina);
CustomFunctions.associate("ATRIBUTOCONCEPTONOMINA", AtributoConceptoNomina);
CustomFunctions.associate("VARIABLENOMINA", VariableNomina);
CustomFunctions.associate("MOVNOMINAVALOR", MovNominaValor);
CustomFunctions.associate("MOVNOMINACANTIDAD", MovNominaCantidad);
CustomFunctions.associate("DIASCONTRATO", DiasContrato);