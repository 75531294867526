import { TJSONArray, TJSONObject, TReqResponse, isBool, val2Int, val2Str } from "@ingenieria_insoft/ispgen";
import { InitialiceApp } from "./UlConst";
import { TASWRequest } from "./UlRequest";

const ndatabase = "contapymeexcel"
const ntblError = "ERRORES"
const ntblPeticiones = "LOGPETICIONES"
const ntblDatos = "DATOS"

function opendIndexedDB(onsuccess: (db: IDBDatabase) => any, onerror: (reason?: any) => void): IDBOpenDBRequest {
	try {
		const request: IDBOpenDBRequest = indexedDB.open(ndatabase);
		request.onupgradeneeded = function () {
			try {

				// The database did not previously exist, so create object stores and indexes.
				const db: IDBDatabase = request.result;
				let storeErrores: IDBObjectStore = db.createObjectStore(ntblError, { keyPath: "id", autoIncrement: true });
				// storeErrores.createIndex("by_metodo", "metodo");
				// storeErrores.createIndex("by_parametros", "parametros");
				// storeErrores.createIndex("by_nombre", "nombre");
				// storeErrores.createIndex("by_mensaje", "mensaje");
				// storeErrores.createIndex("by_fherror", "fherror");

				let storePeticiones: IDBObjectStore = db.createObjectStore(ntblPeticiones, { keyPath: "id", autoIncrement: true });
				// storePeticiones.createIndex("by_metodo", "metodo");
				// storePeticiones.createIndex("by_fhentrada", "fhentrada");
				// storePeticiones.createIndex("by_qtiempo", "qtiempo");
				// storePeticiones.createIndex("by_url", "url");
				// storePeticiones.createIndex("by_params", "params");
				// storePeticiones.createIndex("by_response", "response");
				// storePeticiones.createIndex("by_statuscode", "statuscode");
				// storePeticiones.createIndex("by_fhcre", "fhcre");


				let storeDatos: IDBObjectStore = db.createObjectStore(ntblDatos, { keyPath: "key" });
				storeDatos.createIndex("by_key", "key", { unique: true });
				//storeDatos.createIndex("by_value", "value");
				//storeDatos.createIndex("by_fhcre", "fhcre");
			} catch (error) {
				onerror(error)
			}
		};
		request.onsuccess = () => { onsuccess(request.result) }
		request.onerror = function () { onerror(new Error('Error al abrir la base de datos')); };
		return request;
	} catch (error) {
		onerror(error);
		return null as unknown as IDBOpenDBRequest;
	}
}

function putIndexedDB(ntbl: string, registro: TJSONObject): Promise<boolean> {
	return new Promise<boolean>((resolve, reject) => {
		try {
			opendIndexedDB(function (db: IDBDatabase) {
				try {
					const transaction: IDBTransaction = db.transaction([ntbl], 'readwrite');
					const objectStore: IDBObjectStore = transaction.objectStore(ntbl);
					const req: IDBRequest<IDBValidKey> = objectStore.put(registro);
					transaction.oncomplete = () => { db.close(); resolve(true) };
				} catch (error) {
					reject(error)
				}
			}, (error) => { reject(error) });
		} catch (error) { reject(error) }
	})
}

function deleteIndexedDB(ntbl: string, indexname?: string): Promise<boolean> {
	return new Promise<boolean>((resolve, reject) => {
		opendIndexedDB(function (db: IDBDatabase) {
			try {
				const transaction: IDBTransaction = db.transaction([ntbl], 'readwrite');
				const objectStore: IDBObjectStore = transaction.objectStore(ntbl);
				let req: IDBRequest<undefined> = undefined as unknown as IDBRequest<undefined>;
				if (indexname && isBool(indexname)) req = objectStore.delete(indexname);
				else req = objectStore.clear();
				req.onsuccess = () => { db.close(); resolve(true) };
				req.onerror = () => { db.close(); resolve(false) };
			} catch (error) {
				reject(error)
			}
		}, (error) => { reject(error) });
	})
}

function getIndexedDB(ntbl: string): Promise<TJSONArray> {
	return new Promise<TJSONArray>((resolve) => {
		opendIndexedDB(function (db: IDBDatabase) {
			try {
				const transaction: IDBTransaction = db.transaction([ntbl], 'readonly');
				const objectStore: IDBObjectStore = transaction.objectStore(ntbl);
				const store: IDBRequest<any[]> = objectStore.getAll();
				store.onsuccess = () => { db.close(); resolve(store.result) };
				store.onerror = () => { db.close(); resolve([]) };
			} catch (error) {
				resolve([])
			}
		}, () => { resolve([]) });
	})
}

export function DeleteDB() {
	return Promise.all([
		deleteIndexedDB(ntblError),
		deleteIndexedDB(ntblPeticiones),
		//deleteIndexedDB(ntblDatos)
	])
}

export async function registrarRequest(request: TASWRequest, response: TReqResponse) {
	try {
		putIndexedDB(ntblPeticiones, {
			method: request.method, finicial: request.finicial, ffinal: request.ffinal, qtime: request.qtime, url: request.url,
			params: request.params, responseText: response.responseText, statusCode: response.statusCode, fhcre: request.fhcre
		})
	} catch (_) { }
}

export async function saveError(metodo: string, parametros: string, error: Error) {
	try {
		console.error(metodo, parametros, error);
		putIndexedDB(ntblError, { metodo, parametros, nombre: error?.name, mensaje: error?.message, stack: error?.stack, fhcre: new Date() })
	} catch (_) { }
}

export async function obtenerErrores() {
	return getIndexedDB(ntblError)
}

export async function obtenerLogPeticiones(): Promise<TJSONArray> {
	return getIndexedDB(ntblPeticiones)
}

export async function datosLogIn(bShowLogin: boolean = true): Promise<{ keyagente: number, url: string, email: string }> {
	await InitialiceApp();
	let { url, keyagente, email } = await getItems(["url", "keyagente", "email"]);
	if (bShowLogin && (!isBool(url) || !isBool(keyagente) || val2Int(keyagente, 0) == 0))
		try { await Office.addin?.showAsTaskpane() } catch (_) { }
	return { email: val2Str(email), url: val2Str(url), keyagente: val2Int(keyagente) }
}

export async function restaurarLogin() {
	try {
		await removeItems(["keyagente"]);
		Office.onReady(async () => {
			await Excel.run(async (context) => {
				await Office.addin?.showAsTaskpane()
				window.location.reload();
				await context.sync();
			})
		});
	} catch (_) { }
}

export async function setItem(key: string, value: string): Promise<void> {
	try {
		//await opendIndexedDB();
		putIndexedDB(ntblDatos, { key, value, fhcre: new Date() })
	} catch (error) {
		console.error(error)
	}
}

export function setItems(keyValues: { [key: string]: string }): Promise<any> {
	let ArrPromises: Array<Promise<void>> = []
	for (let key in keyValues) ArrPromises.push(setItem(key, val2Str(keyValues[key])))
	return Promise.all(ArrPromises)
}

export async function getKeys(): Promise<Array<string>> {
	let result: Array<string> = []
	try {
		let datos: any = await getIndexedDB(ntblDatos)
		for (let dato of datos) result.push(dato.key)
	} catch (_) { }
	return result;
}

export async function removeItems(keys: Array<string>): Promise<void> {
	for (let key of keys) await deleteIndexedDB(ntblDatos, key);
}

export async function getItem(key: string): Promise<string | null> {
	let result: string | null = null
	try {
		let datos: any = await getIndexedDB(ntblDatos)
		for (let dato of datos) {
			if (key == dato.key) {
				result = dato.value;
				break;
			}
		}
	} catch (_) { }
	return result;
};

export async function getItems(keys: Array<string>): Promise<{ [key: string]: string | null }> {
	let result: { [key: string]: string | null } = {};
	try {
		let datos: any = await getIndexedDB(ntblDatos)
		for (let dato of datos)
			if (keys.includes(dato.key)) result[val2Str(dato.key)] = dato.value
	} catch (_) { }
	return result;
}
