
import { ISRequest, TJSONObject, TReqResponse, isBool, isNumeric, val2JSON, val2Str, val2Date, val2Int } from "@ingenieria_insoft/ispgen";
import { GetObjectCache, setObjectCache, verificarBorrarCache } from "./UlCache";
import { config, ISErrores, SServerProxy, TISdefCampos } from "./UlConst";
import { getControlKey } from "./UlRequest";
import { datosLogIn, saveError } from "./UlStore";

function validarArregloAgrupadoresOrden(arrayAgrupadores: string, arrayOrden: string) {
	let arregloOrden = arrayOrden.split(",");
	for (let i = 0; i < arregloOrden.length; i++)
		if (!arrayAgrupadores.includes(arregloOrden[i]))
			arrayAgrupadores += "," + arregloOrden[i];
	return arrayAgrupadores;
}

function construirSentenciaSelect(arrayAgrupadores: string, arrayAgregados: string) {
	let textoAgregados = "";
	if (arrayAgregados.trim().length > 0)
		textoAgregados = "," + arrayAgregados;
	return "SELECT " + arrayAgrupadores + textoAgregados;
}

function construirSentenciaWhere(fechainicial: string, fechafinal: string, SQLFiltro: string) {
	SQLFiltro = SQLFiltro.trim();
	if (fechainicial !== "" && fechafinal !== "") {
		if (SQLFiltro !== "")
			return " WHERE FSOPORT BETWEEN '" + fechainicial + "' AND '" + fechafinal + "' AND " + SQLFiltro;
		else
			return " WHERE FSOPORT BETWEEN '" + fechainicial + "' AND '" + fechafinal + "'" + SQLFiltro;
	} else {
		if (SQLFiltro !== "") return " WHERE " + SQLFiltro;
		else return "";
	}
}

function construirSentenciaGroupBy(arrayAgrupadores: string) {
	return " GROUP BY " + arrayAgrupadores;
}

function construirSentenciaOrderBy(arrayOrden: string) {
	let res = "";
	if (arrayOrden.trim().length > 0)
		res = " ORDER BY " + arrayOrden;
	return res;
}

function calcularRangoTabla(range: string, columnasAdicionales: number): string {
	let textoColumna: string = "";
	let numeroColumna: string = "";
	let rangoTemporal: Array<string> = range.split("!");
	rangoTemporal = rangoTemporal[1].split(":")
	for (let i = 0; i < rangoTemporal[0].length; i++) {
		if (!isNumeric(rangoTemporal[0][i])) {
			textoColumna += rangoTemporal[0][i];
		} else {
			numeroColumna += rangoTemporal[0][i];
		}
	}
	//nextString(textoColumna,columnasAdicionales-1);
	let res = textoColumna + numeroColumna + ":" + nextString(textoColumna, columnasAdicionales - 1) + numeroColumna;
	return res;
}

function nextString(str: string, incremento: number): string {
	if (incremento > 0) {
		incremento = incremento - 1;
		if (!str) return nextString('A', incremento--); // return 'A' if str is empty or null 
		let tail: string = '';
		let i: number = str.length - 1;
		let char = str[i]; // find the index of the first character from the right that is not a 'Z' 
		while (char === 'Z' && i > 0) {
			i--;
			char = str[i];
			tail = 'A' + tail; // tail contains a string of 'A' 
		}
		if (char === 'Z') return nextString('AA' + tail, incremento--) // increment the character that was not a 'Z' 
		return nextString(str.slice(0, i) + String.fromCharCode(char.charCodeAt(0) + 1) + tail, incremento--);
	} else {
		return str;
	}
}

function splitMulti(str: string, tokens: Array<string>) {
	let tempChar = tokens[0]; // We can use the first token as a temporary join character
	for (let i = 1; i < tokens.length; i++)
		str = str.split(tokens[i]).join(tempChar);
	return str.split(tempChar);
}

function generarVectorSplit(str: any) {
	let temp = [];
	for (let index = 0; index < str.length; index++) {
		str[index] = str[index].replace(/[ ]+/g, '');
		if (str[index] !== "") temp.push(str[index]);
	}
	return temp;
}

async function realizarConsultaSQL(camposHead: Array<string>, datoSQL: string, keyCache: string, contexto: string) {
	try {
		await verificarBorrarCache(); //Se verifica si se debe borrar el cache para la ejecucion
		let { url, keyagente } = await datosLogIn();

		let objCache: any = GetObjectCache(url, "extractos", keyCache);

		if (isBool(objCache)) {
			return construirVectorRespuesta(camposHead, objCache.response, contexto);
		} else {
			let JSend: TJSONObject = { sql: datoSQL };
			const controlKey = getControlKey(JSend, keyagente);
			let params: string = encodeURI(val2Str(JSend)) + "/" + controlKey + "/" + config.iapp + "/" + config.idmaquina;
			url = SServerProxy(url + '/datasnap/rest/TBasicoGeneral/"GetSql"/' + params);
			let ReqResponse: TReqResponse = await ISRequest.Get(url, {}, 30000);

			let JResonse: any = val2JSON(ReqResponse.responseText, {});

			if (JResonse.result?.at(0)?.encabezado?.resultado == "true") {
				setObjectCache(url, "extractos", keyCache, JResonse.result[0].encabezado.resultado);
				let datosBody = construirVectorRespuesta(camposHead, JResonse.result[0].respuesta.datos, contexto);
				return datosBody;
			}

			throw new Error("No se ha podido realizar la petición " + ReqResponse.responseText);
		}
	} catch (error) {
		saveError("ConsultaSQL", datoSQL, error as Error);
		return [["#¡VALOR!"]]
	}
}

function construirVectorRespuesta(camposHead: Array<string>, datosRespuesta: Array<TJSONObject>, contexto: string) {
	try {
		//Se va a pedir al servidor

		let res = [];
		if (contexto !== "panel")
			res.push(camposHead);
		for (let i = 1; i < datosRespuesta.length + 1; i++) {
			let arregloTemp = [];
			for (let j in datosRespuesta[i - 1]) {
				if (TISdefCampos[j.toUpperCase() as keyof typeof TISdefCampos] !== undefined && TISdefCampos[j.toUpperCase() as keyof typeof TISdefCampos] !== null) {
					if (TISdefCampos[j.toUpperCase() as keyof typeof TISdefCampos].datatype.type == "numeric") {
						if (isNumeric(datosRespuesta[i - 1][j]))
							arregloTemp.push(parseFloat(datosRespuesta[i - 1][j] as string))
						else
							arregloTemp.push((datosRespuesta[i - 1][j]))
					} else if (TISdefCampos[j.toUpperCase() as keyof typeof TISdefCampos].datatype.type == "date") {
						arregloTemp.push(new Date(datosRespuesta[i - 1][j] as string).toISOString().slice(0, 10));
					} else {
						arregloTemp.push(datosRespuesta[i - 1][j])
					}
				} else if ((j.toUpperCase()).includes("SUM") || (j.toUpperCase()).includes("MIN") || (j.toUpperCase()).includes("MAX") || (j.toUpperCase()).includes("AVG")) {
					arregloTemp.push(parseFloat(datosRespuesta[i - 1][j] as string))
				}
				//arregloTemp.push(datosRespuesta[i - 1][j])
			}
			res.push(arregloTemp);
		}
		return res;
	} catch (error) {
		saveError("construirVectorRespuesta", "*", error as Error);
	}
}

function generarfiltroLike(clase: string, identificador: string, params: string, condition: string) {
	// Miramos si contiene una condicion de llamado (Actualmente es UPPER) y se construye la respuesta en caso si o no
	if (condition !== "")
		return (condition + "(" + clase + "." + identificador + ")" + " LIKE '" + params + "'");
	else
		return (clase + "." + identificador + " LIKE '" + params + "'");

}

function generarfiltroEqual(clase: string, identificador: string, params: string, condition: string) {
	// Miramos si contiene una condicion de llamado (Actualmente es UPPER) y se construye la respuesta en caso si o no
	if (condition !== "")
		return (condition + "(" + clase + "." + identificador + ")" + " = '" + params + "'");
	else
		return (clase + "." + identificador + " = '" + params + "'");
}

function generarfiltroIn(clase: string, identificador: string, params: string[], condition: string) {
	//Obtenemos cada uno de los valores enviados por el parametro, y lo separamos por una coma
	let result = "";
	for (let index = 0; index < params.length; index++)
		if (index == 0) result += "'" + params[index] + "'";
		else result += "," + "'" + params[index] + "'";

	// Miramos si contiene una condicion de llamado (Actualmente es UPPER) y se construye la respuesta en caso si o no
	if (condition !== "") return (condition + "(" + clase + "." + identificador + ")" + " IN (" + result + ")");
	else return (clase + "." + identificador + " IN (" + result + ")");

}

export function getFechaString(serial: string): string {
	let utc_days: number = Math.floor(val2Int(serial) - 25568);
	let utc_value: number = utc_days * 86400;
	let date_info: Date = new Date(utc_value * 1000);
	let fechares: Date = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate());
	fechares.setUTCHours(12, 0, 0, 0);
	return fechares.toISOString().slice(0, 10); //No tenia el .slice(0, 10)
}

export async function generarSQL(fechainicial: string, fechafinal: string, arrayAgrupadores: string, arrayAgregados: string, datosFiltro: string, arrayOrden: string, nombreTabla: string, contexto: string) {
	let SQLFiltro = "";
	try {
		if (arrayAgrupadores.trim().length > 0) {
			arrayAgrupadores = validarArregloAgrupadoresOrden(arrayAgrupadores, arrayOrden);
			let sentenciaSQL = construirSentenciaSelect(arrayAgrupadores, arrayAgregados) + " FROM " + nombreTabla +
				construirSentenciaWhere(fechainicial, fechafinal, datosFiltro) +
				//construirSentenciaGroupBy(arrayAgrupadores, arrayOrden) +
				construirSentenciaOrderBy(arrayOrden);
			let keyCache = fechainicial + ":" + fechafinal + ":" + arrayAgrupadores + ":" + arrayAgregados + ":" + arrayOrden + ":" + SQLFiltro;
			return await realizarConsultaSQL(generarCamposHead(arrayAgrupadores, arrayAgregados, arrayOrden), sentenciaSQL, keyCache, contexto);
		}
		throw new Error("Debe seleccionar datos para consultar");
	} catch (error) {
		saveError("generarSQLGenerico", fechainicial + ":" + fechafinal + ":" + arrayAgrupadores + ":" + arrayAgregados + ":" + arrayOrden + ":" + SQLFiltro, error as Error);
		return [["#¡VALOR!"]]
	}
}

export function generarCamposHead(datosConsultar: string, datosAdicionales: string, datosOrden: string): Array<string> {
	let res = [];
	let tempLimpieza = [];
	let temporalDatosConsultar = validarArregloAgrupadoresOrden(datosConsultar, datosOrden)

	// "," + datosOrden.value +
	tempLimpieza = (temporalDatosConsultar + "," + datosAdicionales).split(",");
	//document.getElementById("textoBtnVentana").innerText = datosConsultar.value + "," + datosOrden.value + "," + datosAdicionales.value;
	for (let i = 0; i < tempLimpieza.length; i++)
		if (tempLimpieza[i].trim().length > 0)
			res.push(tempLimpieza[i].trim());

	return res.filter((value, index, self) => { return self.indexOf(value) === index; });
}

export async function ConstruirTablaRespuesta(camposHead: Array<string>, datosRespuesta: any) {
	try {
		await Excel.run(async (context) => {

			try {

				let range = context.workbook.getSelectedRange();
				range.load("addressLocal");

				await context.sync();

				let nuevoRango = calcularRangoTabla(range.addressLocal, camposHead.length);

				let currentWorksheet = context.workbook.worksheets.getActiveWorksheet();
				let indicadoresTable = currentWorksheet.tables.add(nuevoRango, true /*hasHeaders*/);
				indicadoresTable.name = "Indicadores" + Math.floor(Math.random() * 1000);
				indicadoresTable.getHeaderRowRange().values = [camposHead];
				indicadoresTable.rows.add(undefined /*add at the end*/, datosRespuesta);

				indicadoresTable.getRange().format.autofitColumns();
				indicadoresTable.getRange().format.autofitRows();

			} catch (error) {
				saveError("ConstruirTablaRespuesta01", "*", error as Error);
			}
		})


	} catch (error) {
		saveError("ConstruirTablaRespuesta", "*", error as Error);
	}
}

export function generarfiltro(clase: string, identificador: string, params: string, condition: string) {
	try {
		//Se verifica si los parametros que se han enviado al filtro son o no son vacios, si es vacio mostramos mensaje de error
		if (params == "") {
			throw new Error("El parámetro ingresado es incorrecto, la función requiere un parámetro que se ha brindado como un valor vacío o incorrecto.");
		} else {
			//Se verifica que no contenga algun mensaje de error de un posible parametro enviado, para ello se obtene el vector de mensajes de error y se verifica que no contenga ninguno de ellos
			for (let index in ISErrores)
				if (params.includes(ISErrores[index])) {
					//Si contiene algun error, se retorna el valor del error que ha sido invocado en la cascada de ejecucion
					return ISErrores[index];
				}

			params = params.replace(/["]+/g, '');

			// Se verifica que el parametro no sea un valor vacio, si es asi se devuelve un mensaje de error
			if (params == "") {
				saveError("GENERADOR_FILTROS", params, new Error("El parámetro ingresado es incorrecto, la función requiere un parámetro y se ha brindado un valor vacío."));
				return "#¡VALOR!";
			}
			// Se realiza un split para obtener todos los valores incluidos en los parametros, para ello se obtiene la condicion del split del vector
			let values = generarVectorSplit(splitMulti(params, [",", ";"]));
			// Comenzamos a construir el resultado en base a las peticiones
			let result: string = "(";
			let valuesIn: string[] = [];
			for (let index = 0; index < values.length; index++) {
				// Si contiene el valor de %, por lo tanto es un filtro Like
				if (values[index].includes("%")) {
					if (values.length == 1 || (index == values.length - 1 && valuesIn.length == 0)) {
						result += generarfiltroLike(clase, identificador, values[index], condition);
					} else {
						result += generarfiltroLike(clase, identificador, values[index], condition) + " OR ";
					}
				} else {
					// Si no, agregamos cada uno de los valores del parametro para ser verificados posteriormmente
					if (values[index] !== null && values[index] !== "")
						valuesIn.push(values[index]);
				}
			}

			// Se verifica cuantos parametros se tienen sin la condicion Like, si es > 1, se genera el filtro con el agrupador In
			if (valuesIn.length > 1) {
				result += generarfiltroIn(clase, identificador, valuesIn, condition);
			} else {
				// Se verifica cuantos parametros se tienen sin la condicion Like, si es = 1, se genera el filtro con la condicion igual
				if (valuesIn.length == 1) {
					result += generarfiltroEqual(clase, identificador, valuesIn[0], condition);
				}
			}
			result += ")";
			return result;

		}
	} catch (error) {
		saveError("generarfiltroGenerico", "", error as Error);
	}
}

export function bIsText(value: string): boolean {
	return (/[a-zA-Z]/.test(val2Str(value)))
}

export async function setValorCelda(texto: any) {
	try {
		await Excel.run(async context => {
			try {
				const range = context.workbook.getSelectedRange();
				range.load("address");
				await context.sync();
				range.values = texto;
			} catch (error) {
				console.error(error);
			}
		});
	} catch (error) {
		console.error(error);
	}
}

export function fnname(): string {
	let result = "anonymus";
	try {
		throw new Error();
	} catch (e) {
		if (e instanceof Error) {
			// matches this function, the caller and the parent
			const allMatches = e.stack?.match(/(\w+)@|at (\w+) \(/g);
			// match parent function name
			if (allMatches) {
				const parentMatches = allMatches[1].match(/(\w+)@|at (\w+) \(/);
				// return only name
				if (parentMatches && parentMatches instanceof Array)
					result = parentMatches[1] || parentMatches[2];
			}
		}
	}
	return result;
}